import Vue from 'vue';

import config from '../config';
import { resizeImage } from '../utils/imageUtility';

export default {
  /* ADA: símmenntunviðbót */
  /**
   * Ath hvort notandi sé skráður mms eða símmenntun (eða bæði)
   */
  checkBookUserType() {
    return Vue.http
      .get(config.CHECK_BOOK_USER_TYPE)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að fletta upp kennitölu nemanda þegar verið er að virkja ferilbók
   */
  searchStudent(ssn) {
    return Vue.http
      .get(config.SEARCH_STUDENT_URL, {
        params: {
          ssn,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að fletta upp nemandanúmeri nemanda í þeim skóla sem er valinn og aðstandendum í Innu
   */
  searchStudentInformation(ssn, schoolSsn) {
    return Vue.http
      .get(config.SEARCH_STUDENT_INFORMATION_URL, {
        params: {
          ssn,
          schoolSsn,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  signetContractGuardianContact(data) {
    return Vue.http
      .post(config.SIGNET_CONTRACT_GUARDIAN_CONTACT, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að fletta upp kennitölu meistara þegar verið er að virkja ferilbók
   */
  searchSupervisor(ssn) {
    return Vue.http
      .get(config.SEARCH_SUPERVISOR_URL, {
        params: {
          ssn,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að fletta upp kennitölu umsýsluaðila þegar verið er að virkja ferilbók
   */
  searchInstructor(ssn) {
    return Vue.http
      .get(config.SEARCH_INSTRUCTOR_URL, {
        params: {
          ssn,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að athuga hvort allir undirritendur séu skráðir með netföng og símanúmer
   */
  signetSignersValid(ssn) {
    return Vue.http
      .get(config.VALIDATE_CONTACTS_URL, {
        params: {
          ssn,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að finna undirritunaraðila ferilbókar
   */
  contractContactsList(id) {
    return Vue.http
      .get(config.CONTRACT_CONTACTS_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að uppfæra netfang undirritunar aðila
   * Þessi þjónusta virkar ekki eins og er fyrst Signet sér um að senda tölvupósta fyrir samninga
   */
  contractContactUpdateEmail(id, data) {
    return Vue.http
      .put(config.CONTRACT_CONTACTS_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að finna skóla fyrir samninga
   */
  institutionsList() {
    return Vue.http
      .get(config.CONTRACT_INSTITUTIONS_LIST_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að finna vinnustaði fyrir samninga
   */
  workplacesList(bookUuid) {
    return Vue.http
      .get(config.CONTRACT_WORKPLACES_LIST_URL.replace('{id}', encodeURIComponent(bookUuid)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að vista upplýsingar um samning fyrir signet
   */
  signetContractDraft(data) {
    return Vue.http
      .post(config.SIGNET_CONTRACT_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að eyða signet samningi
   */
  signetContractDelete(id) {
    return Vue.http
      .delete(config.SIGNET_CONTRACT_DELETE_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að afvirkja signet samningi
   */
  signetContractDeactivate(id, data) {
    return Vue.http
      .put(config.SIGNET_CONTRACT_UPDATE_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja samninga skráða á ferilbók
   */
  signetContracts(id) {
    return Vue.http
      .get(config.SIGNET_CONTRACT_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => {
        // eslint-disable-next-line no-param-reassign
        response.data.results?.forEach((e) => {
          e.relative_contact = e.relative_contact ? JSON.parse(e.relative_contact) : e.relative_contact;
        });
        return response;
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að fletta upp kennitölu vinnustaðar þegar verið er að virkja ferilbók
   */
  searchWorkplace(profession) {
    return Vue.http
      .get(config.SEARCH_WORKPLACE_URL, {
        params: {
          profession,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /*
   * Vefþjónusta til að sækja lista af ferilbókum
   */
  careerbookList(
    page = null,
    limit = null,
    searchName = null,
    searchProfession = null,
    searchStatus = null,
    studentRecordFrom = null,
    studentRecordTo = null,
    supervisorRecordFrom = null,
    supervisorRecordTo = null,
    percentageSupervisor = null,
    percentageFrom = null,
    percentageTo = null,
  ) {
    const allPercentage = percentageFrom === 0 && percentageTo === 100;
    return Vue.http
      .get(config.BOOK_LIST_URL, {
        params: {
          page,
          limit,
          search_name: searchName !== '' ? searchName : null,
          search_profession: searchProfession || null,
          search_status: searchStatus !== null ? searchStatus : null,
          student_record_from: studentRecordFrom || null,
          student_record_to: studentRecordTo || null,
          supervisor_record_from: supervisorRecordFrom || null,
          supervisor_record_to: supervisorRecordTo || null,
          percentage_supervisor: percentageSupervisor ? 1 : 0,
          percentage_from: allPercentage ? null : percentageFrom,
          percentage_to: allPercentage ? null : percentageTo,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /*
   * Vefþjónusta til að sækja list af ferilbókum umsækjanda
   */
  careerbookApplicationList(page = null, limit = null, searchName = null) {
    return Vue.http
      .get(config.BOOK_APPLICATION_LIST_URL, {
        params: {
          page,
          limit,
          search_name: searchName !== '' ? searchName : null,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /*
   * Vefþjónusta til að sækja staka ferilbók
   */
  careerbookDetail(id, meStudent = null, meSupervisor = null, meInstructor = null, isApplicationBook = null) {
    return Vue.http
      .get(config.BOOK_DETAIL_URL.replace('{id}', encodeURIComponent(id)), {
        params: {
          me_student: meStudent,
          me_supervisor: meSupervisor,
          me_instructor: meInstructor,
          is_application_book: isApplicationBook,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  careerbookHistory(id) {
    return Vue.http
      .get(config.BOOK_HISTORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  historyToExcel(book) {
    Vue.http({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `${config.HISTORY_TO_EXCEL}?id=${book}`,
    }).then((success) => {
      const a = document.createElement('a');
      const disposition = success.headers['content-disposition'];
      let filename = null;

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
      }
      const file = new Blob([success.data], { type: success.headers['content-type'] });
      // if (window.navigator.msSaveOrOpenBlob) {
      //   // Fyrir IE
      //   window.navigator.msSaveOrOpenBlob(file);
      // } else {
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
      // }
    });
  },

  bookToExcel(book) {
    Vue.http({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `${config.BOOK_TO_EXCEL}?id=${book}`,
    }).then((success) => {
      const a = document.createElement('a');
      const disposition = success.headers['content-disposition'];
      let filename = null;

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
      }
      const file = new Blob([success.data], { type: success.headers['content-type'] });
      // if (window.navigator.msSaveOrOpenBlob) {
      //   // Fyrir IE
      //   window.navigator.msSaveOrOpenBlob(file);
      // } else {
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
      // }
    });
  },
  /*
   * Vefþjónusta til að staðfesta samning?
   */
  confirmAgreement(id) {
    return Vue.http
      .post(config.BOOK_CONFIRM_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /*
   * Vefþjónusta til að stofna ferilbók
   */
  careerbookCreate(data) {
    return Vue.http
      .post(config.BOOK_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /*
   * Vefþjónusta til að breyta ferilbók
   */
  careerbookUpdate(id, data) {
    return Vue.http
      .put(config.BOOK_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /*
   * Vefþjónusta til að eyða ferilbók
   */
  careerbookDelete(id) {
    return Vue.http
      .delete(config.BOOK_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  careerbookCopy(data) {
    return Vue.http
      .post(config.BOOK_COPY_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til að fá upplýsingar um skráningu verkflokka og verkþátta
   */
  careerbookCategoryFactorCompletedCount(id) {
    return Vue.http
      .get(config.BOOK_CATEGORY_FACTOR_COMPLETED_COUNT.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir verkflokka ferilbókar
   */
  careerbookCategoryList(id) {
    return Vue.http
      .get(config.BOOK_CATEGORY_LIST_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  // careerbookCategoryFactorComplete(data) {
  //   return Vue.http
  //     .put(config.BOOK_CATEGORY_FACTOR_COMPLETED, data)
  //     .then((response) => Promise.resolve(response))
  //     .catch((error) => Promise.reject(error));
  // },

  careerbookCategoryCompleted(id, data) {
    return Vue.http
      .put(config.BOOK_CATEGORY_FACTOR_COMPLETED.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  getCareerbookCategoryCompleted(id) {
    return Vue.http
      .get(config.BOOK_CATEGORY_FACTOR_COMPLETED.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  // getCareerbookCategoryFactorCompletedCount(id) {
  //   return Vue.http
  //     .get(config.BOOK_CATEGORY_FACTOR_COMPLETED_COUNT.replace('{id}', encodeURIComponent(id)))
  //     .then((response) => Promise.resolve(response))
  //     .catch((error) => Promise.reject(error));
  // },
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir verkþætti verkflokks
   */
  careerbookFactorList(id, category) {
    return Vue.http
      .get(config.BOOK_FACTOR_LIST_URL.replace('{id}', encodeURIComponent(id)).replace('{category}', encodeURIComponent(category)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta sem uppfærir vinnustað á ferilbók
   */
  careerbookWorkplaceUpdate(id, data) {
    return Vue.http
      .put(config.BOOK_BOOK_WORKPLACE_PUT_URL.replace('{workplace}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta sem sækir lista af vinnustöðum á ferilbók
   */
  bookWorkplaceList(id) {
    return Vue.http
      .get(config.BOOK_BOOK_WORKPLACE_LIST_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta sem uppfærir tilsjónaraðila ferilbókar
   */
  careerbookSupervisorUpdate(id, data) {
    return Vue.http
      .put(config.BOOK_BOOK_SUPERVISOR_PUT_URL.replace('{supervisor}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta sem sækir lista af tilsjónaraðilum ferilbókar
   */
  bookSupervisorList(id) {
    return Vue.http
      .get(config.BOOK_BOOK_SUPERVISOR_LIST_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta sem býr til tilkynningar vegna mynda, skilaboða osfrv.
   */
  careerbookCategoryNotification(data) {
    return Vue.http
      .post(config.BOOK_CATEGORY_NOTIFICATIONS_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta sem uppfærir tilkynningar á flokki vegna mynda, skilaboða osfrv.
   */
  careerbookCategoryNotificationUpdate(data) {
    return Vue.http
      .put(config.BOOK_CATEGORY_NOTIFICATIONS_PUT_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta sem uppfærir tilkynningar á þætti vegna mynda, skilaboða osfrv.
   */
  careerbookFactorNotificationUpdate(data) {
    return Vue.http
      .put(config.BOOK_FACTOR_NOTIFICATIONS_PUT_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta sem sækir fjöldatölur fyrir verkhluta fyrir myndir, skilaboð ofl
   */
  careerbookCategoryCount(id, category) {
    return Vue.http
      .get(config.BOOK_CATEGORY_COUNT_URL.replace('{id}', encodeURIComponent(id)).replace('{category}', encodeURIComponent(category)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta sem sækir fjöldatölur fyrir verkþátt fyrir myndir, skilaboð ofl
   */
  careerbookFactorCount(id, bookFactor) {
    return Vue.http
      .get(config.BOOK_FACTOR_COUNT_URL.replace('{id}', encodeURIComponent(id)).replace('{bookFactor}', encodeURIComponent(bookFactor)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja réttindi að ferilbók
   */
  careerbookProbe(id, isApplicationCareerbook = false) {
    return Vue.http
      .get(config.BOOK_PROBE_URL.replace('{id}', encodeURIComponent(id)), {
        params: {
          isApplicationCareerbook: isApplicationCareerbook === true ? 1 : 0,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að downloda skjali
   */
  factorFileDownload(id, bookId, filename) {
    Vue.http({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `${config.BOOK_FACTOR_FILE_DOWNLOAD_URL}?key=${id}&book=${bookId}`,
    }).then((success) => {
      const a = document.createElement('a');
      const file = new Blob([success.data], { type: success.headers['content-type'] });
      if (window.navigator.msSaveOrOpenBlob) {
        // Fyrir IE
        window.navigator.msSaveOrOpenBlob(file, filename);
      } else {
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    });
  },
  /**
   * Vefþjónusta til þess að eyða skjali
   */
  factorFileDelete(id, category, factor, file) {
    return Vue.http
      .delete(
        config.BOOK_FACTOR_FILE_DETAIL_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor))
          .replace('{file}', encodeURIComponent(file)),
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að downloda mynd
   */
  factorImageDownload(id, bookId, filename, type) {
    Vue.http({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `${config.BOOK_FACTOR_IMAGE_DOWNLOAD_URL}?key=${id}&type=${type}&book=${bookId}`,
    }).then((success) => {
      const a = document.createElement('a');
      const file = new Blob([success.data], { type: success.headers['content-type'] });
      if (window.navigator.msSaveOrOpenBlob) {
        // Fyrir IE
        window.navigator.msSaveOrOpenBlob(file, filename);
      } else {
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    });
  },

  /**
   * Vefþjónusta til þess að eyða mynd
   */
  factorImageDelete(id, category, factor, image) {
    return Vue.http
      .delete(
        config.BOOK_FACTOR_IMAGE_DETAIL_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor))
          .replace('{image}', encodeURIComponent(image)),
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja öll skjöl fyrir þátt
   */
  factorFileList(id, category, factor) {
    return Vue.http
      .get(
        config.BOOK_FACTOR_FILE_LIST_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor)),
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja allar myndir fyrir bók
   */
  imageList(id, category, factor) {
    return Vue.http
      .get(
        config.BOOK_IMAGE_LIST.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor)),
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja eina mynd og upplýsingar um hana
   */
  factorImage(id, category, factor, image) {
    return Vue.http
      .get(
        config.BOOK_FACTOR_IMAGE_DETAIL_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor))
          .replace('{image}', encodeURIComponent(image)),
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vistar thumbnail við stærri mynd.
   * @param {*} id uuid bókar
   * @param {*} category uuid flokks
   * @param {*} factor uuid verkþáttar
   * @param {number} imageId raðnúmer myndar
   * @param {File} image Mynd sem á að búa til thumbnail af.
   */
  async setFactorImageThumbnail(id, category, factor, imageId, image) {
    const thumbnail = await resizeImage(image, 450, 450);
    return Vue.http
      .post(
        config.BOOK_FACTOR_IMAGE_THUMBNAIL_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor))
          .replace('{image}', encodeURIComponent(imageId)),
        thumbnail.match(/,(.*)$/)[1],
        {
          headers: {
            'Content-Type': 'text/plain',
          },
        },
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja eina mynd og upplýsingar um hana
   */
  factorImageInfo(mid) {
    return Vue.http
      .get(config.BOOK_FACTOR_IMAGE_INFO_URL.replace('{mid}', encodeURIComponent(mid)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja allar myndir fyrir þátt
   */
  factorImageList(id, category, factor) {
    return Vue.http
      .get(
        config.BOOK_FACTOR_IMAGE_LIST_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor)),
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja allar umræður fyrir þátt
   */
  factorMessageList(id, category, factor) {
    return Vue.http
      .get(
        config.BOOK_FACTOR_MESSAGE_LIST_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor)),
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja allar umræður fyrir þátt
   */
  factorMessageCreate(id, category, factor, data) {
    return Vue.http
      .post(
        config.BOOK_FACTOR_MESSAGE_POST_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor)),
        data,
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja allar umræður fyrir þátt
   */
  factorMessageDelete(id, category, factor, message) {
    return Vue.http
      .delete(
        config.BOOK_FACTOR_MESSAGE_DETAIL_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor))
          .replace('{message}', encodeURIComponent(message)),
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá tíma
   */
  factorTimelogCreate(id, category, factor, data) {
    return Vue.http
      .post(
        config.BOOK_FACTOR_TIMELOG_POST_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor)),
        data,
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja allar tímaskráningar
   */
  factorTimelogList(id, category, factor) {
    return Vue.http
      .get(
        config.BOOK_FACTOR_TIMELOG_LIST_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor)),
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja allar umræður fyrir þátt
   */
  factorTimelogDelete(id, category, factor, timelog) {
    return Vue.http
      .delete(
        config.BOOK_FACTOR_TIMELOG_DETAIL_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor))
          .replace('{timelog}', encodeURIComponent(timelog)),
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að breyta upplýsingum á þætti
   */
  factorPatch(id, category, factor, data) {
    return Vue.http
      .put(
        config.BOOK_FACTOR_DETAIL_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor)),
        data,
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá tíma
   */
  factorEvaluationCreate(id, category, factor, data) {
    return Vue.http
      .post(
        config.BOOK_FACTOR_EVALUATION_POST_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor)),
        data,
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja allar tímaskráningar
   */
  factorEvaluationList(id, category, factor) {
    return Vue.http
      .get(
        config.BOOK_FACTOR_EVALUATION_LIST_URL.replace('{id}', encodeURIComponent(id))
          .replace('{category}', encodeURIComponent(category))
          .replace('{factor}', encodeURIComponent(factor)),
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja allar tímaskráningar
   */
  myBooks(page = null, limit = null, meStudent = null, meSupervisor = null, meInstructor = null) {
    return Vue.http
      .get(config.BOOK_LIST_URL, {
        params: {
          page,
          limit,
          me_student: meStudent,
          me_supervisor: meSupervisor,
          me_instructor: meInstructor,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Uppfærir starfsgrein bókar í nýjustu útgáfu starfsgreinar.
   * Búin er tíl ný bók (afrit) með uppfærðu starfsgreininni.
   * @param {string} id Uuid bókar sem á að uppfæra.
   * @returns Uuid nýrrar ferilbókar.
   */
  upgrade(id) {
    return Vue.http
      .post(config.BOOK_BOOK_UPGRADE.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};
