/* eslint-disable */
// const { API_URL } = process.env;
const API_URL = '/fbk/'; // '/api/';

const LOGIN_TOKEN = 'id_token';
const LOGIN_TOKEN_EXPIRE = 'id_token_exp';
const PING_URL = `${API_URL}system-services/ping`;
const ME_URL = `${API_URL}system-services/me`;
const CHECK_LOGIN_URL = `${API_URL}auth/check-login`;
const CHECK_LOGIN_SSO_URL = `${API_URL}auth/check-login-sso`;
const USER_ACCESS_URL = `${API_URL}auth/access`;
const CHANGE_SYSTEM = `${API_URL}auth/system`;

const USER_CHECK_URL = `${API_URL}auth/usercheck/{ssn}`;
const USER_LIST_URL = `${API_URL}auth/users`;
const USER_BOOK_LIST_URL = `${API_URL}auth/book_users`;
const USER_BOOK_INSTRUCTOR_LIST_URL = `${API_URL}auth/book_instructor_users`;
const USER_BOOK_SUPERVISOR_LIST_URL = `${API_URL}auth/book_supervisor_users`;
const USER_DETAIL_URL = `${API_URL}auth/users/{id}`;

const SHOW_SYSTEM_STATUS_LOGIN = `${API_URL}core/systemstatuslogin`;
const SHOW_SYSTEM_STATUS_LOGGED_IN = `${API_URL}core/systemstatusloggedin`;

const PROFESSION_CATEGORY_LIST_URL = `${API_URL}core/professioncategory`;
const PROFESSION_CATEGORY_DETAIL_URL = `${API_URL}core/professioncategory/{id}`;

const PROFESSION_LIST_URL = `${API_URL}core/profession`;
const PROFESSION_DETAIL_URL = `${API_URL}core/profession/{id}`;

const PROFESSION_VERSION_LIST_URL = `${API_URL}core/profession/{id}/version`;
const PROFESSION_VERSION_DETAIL_URL = `${API_URL}core/profession/{id}/version/{vid}`;

const PROFESSION_DESCRIPTION_URL = `${API_URL}core/profession/{id}/description`;

const CONTACT_LIST_URL = `${API_URL}core/contact`;
const CONTACT_DETAILL_URL = `${API_URL}core/contact/{id}`;
const INSTITUTION_CONTACT_LIST_URL = `${API_URL}core/institution/{id}/contact`;
const WORKPLACE_CONTACT_LIST_URL = `${API_URL}core/workplace/{id}/contact`;

const CONTRACT_LIST_URL = `${API_URL}core/contract`;
const CONTRACT_DETAIL_URL = `${API_URL}core/contract/{id}`;
const SIGNET_CONTRACT_LIST_URL = `${API_URL}core/signetcontract`;

const CONTRACT_CONTACTS_URL = `${API_URL}book/book/{id}/contractcontacts`;
const CONTRACT_INSTITUTIONS_LIST_URL = `${API_URL}book/book/institutions`;
const CONTRACT_WORKPLACES_LIST_URL = `${API_URL}book/book/{id}/workplaces`;
const SIGNET_CONTRACT_URL = `${API_URL}book/book/signetcontract`;
const SIGNET_CONTRACT_DETAIL_URL = `${API_URL}book/book/{id}/contract`;
const SIGNET_CONTRACT_DELETE_URL = `${API_URL}book/book/signetcontract/{id}`;
const SIGNET_CONTRACT_UPDATE_URL = `${API_URL}book/book/signetcontract/{id}`;
const VALIDATE_CONTACTS_URL = `${API_URL}book/book/{id}/validatecontractcontacts`;

const INSTITUTION_LIST_URL = `${API_URL}core/institution`;
const INSTITUTION_DETAIL_URL = `${API_URL}core/institution/{id}`;

const FACTOR_CATEGORY_LIST_URL = `${API_URL}core/factorcategory`;
const FACTOR_CATEGORY_DETAIL_URL = `${API_URL}core/factorcategory/{id}`;

const CAREERBOOK_VERSION_POST_URL = `${API_URL}core/careerbookversionexcel`;
const FACTOR_CATEGORY_POST_URL = `${API_URL}core/factorcategoryexcel`;
const FACTOR_POST_URL = `${API_URL}core/factorexcel`;
const VERSION_GET_URL = `${API_URL}core/version`;
const FACTOR_GET_URL = `${API_URL}core/factorexcel`;

const FACTOR_SUBCATEGORY_LIST_URL = `${API_URL}core/factorsubcategory`;
const FACTOR_SUBCATEGORY_DETAIL_URL = `${API_URL}core/factorsubcategory/{id}`;

const FACTOR_SUBCATEGORY_SORT_LIST_URL = `${API_URL}core/sort/factorsubcategory`;
const FACTOR_SUBCATEGORY_SORT_DETAIL_URL = `${API_URL}core/sort/factorsubcategory/{id}`;

const FACTOR_CATEGORY_SORT_LIST_URL = `${API_URL}core/sort/factorcategory`;
const FACTOR_CATEGORY_SORT_DETAIL_URL = `${API_URL}core/sort/factorcategory/{id}`;

const FACTOR_LIST_URL = `${API_URL}core/factor`;
const FACTOR_DETAIL_URL = `${API_URL}core/factor/{id}`;
const FACTOR_DESCRIPTION_URL = `${API_URL}core/factor/{id}/factordescription`;
const FACTOR_FEATURE_URL = `${API_URL}core/factor/{id}/feature`;

const FACTOR_SORT_LIST_URL = `${API_URL}core/sort/factor`;
const FACTOR_SORT_DETAIL_URL = `${API_URL}core/sort/factor/{id}`;

const MODULE_LIST_URL = `${API_URL}core/modules/{id}`;

const REGULATION_LIST_URL = `${API_URL}core/regulation`;
const REGULATION_DETAIL_URL = `${API_URL}core/regulation/{id}`;

const WORKPLACE_LIST_URL = `${API_URL}core/workplace`;
const WORKPLACE_DETAIL_URL = `${API_URL}core/workplace/{id}`;

const NATIONAL_REGISTRY_COMPANY_URL = `${API_URL}system-services/search-company/{ssn}`;

const INSTRUCTIONS_LIST_URL = `${API_URL}core/instructions`;

const CHECK_BOOK_USER_TYPE = `${API_URL}book/check-book-user-type`; /* ADA: símmenntunarviðbót */

const SEARCH_STUDENT_URL = `${API_URL}book/search-student`;
const SEARCH_STUDENT_INFORMATION_URL = `${API_URL}book/search-student-information`;
const SIGNET_CONTRACT_GUARDIAN_CONTACT = `${API_URL}book/book/guardiancontact`;
const SEARCH_SUPERVISOR_URL = `${API_URL}book/search-supervisor`;
const SEARCH_INSTRUCTOR_URL = `${API_URL}book/search-instructor`;
const SEARCH_WORKPLACE_URL = `${API_URL}book/search-workplace`;

const BOOK_BOOK_SUPERVISOR_PUT_URL = `${API_URL}book/booksupervisor/{supervisor}`;
const BOOK_BOOK_SUPERVISOR_LIST_URL = `${API_URL}book/book/{id}/booksupervisors`;

const BOOK_IMAGE_LIST = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/images`;
const BOOK_LIST_URL = `${API_URL}book/book`;
const BOOK_DETAIL_URL = `${API_URL}book/book/{id}`;
const BOOK_COPY_URL = `${API_URL}book/copy`;
const BOOK_APPLICATION_LIST_URL = `${API_URL}book/bookapplication`;
const BOOK_HISTORY_DETAIL_URL = `${API_URL}book/book/history/{id}`;
const BOOK_CONFIRM_URL = `${API_URL}book/book/{id}/confirm`;
const BOOK_CATEGORY_LIST_URL = `${API_URL}book/book/{id}/factorcategories`;
// const BOOK_CATEGORY_FACTOR_COMPLETED = `${API_URL}book/book/categoryfactorcompleted`;
const BOOK_CATEGORY_FACTOR_COMPLETED = `${API_URL}book/book/{id}/categoryfactorcompleted`;
const BOOK_CATEGORY_FACTOR_COMPLETED_COUNT = `${API_URL}book/book/{id}/categoryfactorcompleted/count`;
// const BOOK_CATEGORY_FACTOR_COMPLETED_COUNT = `${API_URL}book/book/{id}/bookcompleted`;
const BOOK_CATEGORY_NOTIFICATIONS_URL = `${API_URL}book/book/notification`;
const BOOK_CATEGORY_NOTIFICATIONS_PUT_URL = `${API_URL}book/book/notification`;
const BOOK_TO_EXCEL = `${API_URL}book/bookexcel`;
const HISTORY_TO_EXCEL = `${API_URL}book/book/historyexcel`;
const BOOK_CATEGORY_COUNT_URL = `${API_URL}book/book/{id}/factorcategories/{category}/count`;
const BOOK_FACTOR_LIST_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors`;
const BOOK_FACTOR_DETAIL_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}`;
const BOOK_PROBE_URL = `${API_URL}book/book/{id}/probe`;
const BOOK_FACTOR_NOTIFICATIONS_PUT_URL = `${API_URL}book/book/factor/notification`;
const BOOK_FACTOR_COUNT_URL = `${API_URL}book/book/{id}/factor/{bookFactor}/count`;
const BOOK_FACTOR_FILE_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/files`;
const BOOK_FACTOR_FILE_DOWNLOAD_URL = `${API_URL}book/download-file`;
const BOOK_FACTOR_FILE_DETAIL_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/files/{file}`;
const BOOK_FACTOR_FILE_LIST_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/files`;
const BOOK_FACTOR_IMAGE_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/images`;
const BOOK_FACTOR_IMAGE_THUMBNAIL_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/images/{image}/thumbnail`;
const BOOK_FACTOR_IMAGE_DOWNLOAD_URL = `${API_URL}book/download-image`;
const BOOK_FACTOR_IMAGE_DETAIL_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/images/{image}`;
const BOOK_FACTOR_IMAGE_INFO_URL = `${API_URL}book/imageInfo/{mid}`;
const BOOK_FACTOR_IMAGE_LIST_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/images`;
const BOOK_FACTOR_MESSAGE_LIST_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/messages`;
const BOOK_FACTOR_MESSAGE_DETAIL_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/messages/{message}`;
const BOOK_FACTOR_MESSAGE_POST_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/messages`;
const BOOK_FACTOR_TIMELOG_POST_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/timelogs`;
const BOOK_FACTOR_TIMELOG_LIST_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/timelogs`;
const BOOK_FACTOR_TIMELOG_DETAIL_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/timelogs/{timelog}`;
const BOOK_FACTOR_EVALUATION_POST_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/evaluations`;
const BOOK_FACTOR_EVALUATION_LIST_URL = `${API_URL}book/book/{id}/factorcategories/{category}/factors/{factor}/evaluations`;
const BOOK_BOOK_WORKPLACE_PUT_URL = `${API_URL}book/bookworkplace/{workplace}`;
const BOOK_BOOK_WORKPLACE_LIST_URL = `${API_URL}book/book/{id}/bookworkplaces`;
const BOOK_BOOK_UPGRADE = `${API_URL}book/book/{id}/upgrade`;

const COMPETENCE_CATEGORY_LIST_URL = `${API_URL}core/competencecategory`;
const COMPETENCE_CATEGORY_DETAIL_URL = `${API_URL}core/competencecategory/{id}`;
const COMPETENCE_LIST_URL = `${API_URL}core/competence`;
const COMPETENCE_DETAIL_URL = `${API_URL}core/competence/{id}`;
const COMPETENCE_CONNECT_URL = `${API_URL}core/competence/connect`;

const STATISTICS_STUDENTS_URL = `${API_URL}statistics/students`;
const STATISTICS_STUDENTS_EXCEL_URL = `${API_URL}statistics/students/excel`;
const STATISTICS_WORKPLACES_URL = `${API_URL}statistics/workplaces`;
const STATISTICS_WORKPLACES_EXCEL_URL = `${API_URL}statistics/workplaces/excel`;
const STATISTICS_CONTRACTS_URL = `${API_URL}statistics/contracts`;
const STATISTICS_CONTRACTS_EXCEL_URL = `${API_URL}statistics/contracts/excel`;

/* eslint-enable */
export default {
  LOGIN_TOKEN,
  LOGIN_TOKEN_EXPIRE,
  PING_URL,
  ME_URL,
  CHECK_LOGIN_URL,
  CHECK_LOGIN_SSO_URL,
  USER_ACCESS_URL,
  CHANGE_SYSTEM,
  USER_CHECK_URL,
  USER_LIST_URL,
  USER_BOOK_LIST_URL,
  USER_BOOK_INSTRUCTOR_LIST_URL,
  USER_BOOK_SUPERVISOR_LIST_URL,
  USER_DETAIL_URL,
  SHOW_SYSTEM_STATUS_LOGIN,
  SHOW_SYSTEM_STATUS_LOGGED_IN,
  PROFESSION_DETAIL_URL,
  PROFESSION_LIST_URL,
  PROFESSION_DESCRIPTION_URL,
  CONTRACT_DETAIL_URL,
  CONTRACT_LIST_URL,
  CONTRACT_INSTITUTIONS_LIST_URL,
  CONTRACT_WORKPLACES_LIST_URL,
  SIGNET_CONTRACT_LIST_URL,
  SIGNET_CONTRACT_URL,
  SIGNET_CONTRACT_DETAIL_URL,
  SIGNET_CONTRACT_DELETE_URL,
  SIGNET_CONTRACT_UPDATE_URL,
  CONTACT_LIST_URL,
  CONTACT_DETAILL_URL,
  WORKPLACE_CONTACT_LIST_URL,
  INSTITUTION_CONTACT_LIST_URL,
  CONTRACT_CONTACTS_URL,
  INSTITUTION_LIST_URL,
  INSTITUTION_DETAIL_URL,
  PROFESSION_CATEGORY_LIST_URL,
  PROFESSION_CATEGORY_DETAIL_URL,
  CAREERBOOK_VERSION_POST_URL,
  VERSION_GET_URL,
  FACTOR_GET_URL,
  BOOK_TO_EXCEL,
  HISTORY_TO_EXCEL,
  FACTOR_POST_URL,
  FACTOR_CATEGORY_LIST_URL,
  FACTOR_CATEGORY_DETAIL_URL,
  FACTOR_CATEGORY_POST_URL,
  FACTOR_SUBCATEGORY_LIST_URL,
  FACTOR_SUBCATEGORY_DETAIL_URL,
  FACTOR_LIST_URL,
  FACTOR_DETAIL_URL,
  FACTOR_DESCRIPTION_URL,
  MODULE_LIST_URL,
  FACTOR_CATEGORY_SORT_LIST_URL,
  FACTOR_CATEGORY_SORT_DETAIL_URL,
  FACTOR_SUBCATEGORY_SORT_LIST_URL,
  FACTOR_SUBCATEGORY_SORT_DETAIL_URL,
  FACTOR_SORT_LIST_URL,
  FACTOR_SORT_DETAIL_URL,
  FACTOR_FEATURE_URL,
  REGULATION_LIST_URL,
  REGULATION_DETAIL_URL,
  WORKPLACE_LIST_URL,
  WORKPLACE_DETAIL_URL,
  NATIONAL_REGISTRY_COMPANY_URL,
  CHECK_BOOK_USER_TYPE /* ADA: símmenntunarviðbót */,
  SEARCH_STUDENT_URL,
  SEARCH_STUDENT_INFORMATION_URL,
  SIGNET_CONTRACT_GUARDIAN_CONTACT,
  SEARCH_SUPERVISOR_URL,
  SEARCH_INSTRUCTOR_URL,
  SEARCH_WORKPLACE_URL,
  BOOK_IMAGE_LIST,
  BOOK_LIST_URL,
  BOOK_DETAIL_URL,
  BOOK_COPY_URL,
  BOOK_APPLICATION_LIST_URL,
  BOOK_HISTORY_DETAIL_URL,
  BOOK_CONFIRM_URL,
  BOOK_CATEGORY_LIST_URL,
  BOOK_CATEGORY_FACTOR_COMPLETED,
  BOOK_CATEGORY_FACTOR_COMPLETED_COUNT,
  BOOK_CATEGORY_NOTIFICATIONS_URL,
  BOOK_CATEGORY_NOTIFICATIONS_PUT_URL,
  BOOK_CATEGORY_COUNT_URL,
  BOOK_FACTOR_LIST_URL,
  BOOK_PROBE_URL,
  BOOK_FACTOR_NOTIFICATIONS_PUT_URL,
  BOOK_FACTOR_COUNT_URL,
  BOOK_FACTOR_FILE_URL,
  BOOK_FACTOR_FILE_DOWNLOAD_URL,
  BOOK_FACTOR_FILE_LIST_URL,
  BOOK_FACTOR_IMAGE_DOWNLOAD_URL,
  BOOK_FACTOR_IMAGE_URL,
  BOOK_FACTOR_IMAGE_THUMBNAIL_URL,
  BOOK_FACTOR_IMAGE_INFO_URL,
  BOOK_FACTOR_IMAGE_LIST_URL,
  BOOK_FACTOR_MESSAGE_LIST_URL,
  BOOK_FACTOR_MESSAGE_POST_URL,
  BOOK_FACTOR_FILE_DETAIL_URL,
  BOOK_FACTOR_IMAGE_DETAIL_URL,
  BOOK_FACTOR_MESSAGE_DETAIL_URL,
  BOOK_FACTOR_TIMELOG_POST_URL,
  BOOK_FACTOR_TIMELOG_LIST_URL,
  BOOK_FACTOR_TIMELOG_DETAIL_URL,
  BOOK_FACTOR_DETAIL_URL,
  BOOK_FACTOR_EVALUATION_POST_URL,
  BOOK_FACTOR_EVALUATION_LIST_URL,
  COMPETENCE_CATEGORY_LIST_URL,
  COMPETENCE_CATEGORY_DETAIL_URL,
  COMPETENCE_LIST_URL,
  COMPETENCE_DETAIL_URL,
  COMPETENCE_CONNECT_URL,
  INSTRUCTIONS_LIST_URL,
  PROFESSION_VERSION_LIST_URL,
  PROFESSION_VERSION_DETAIL_URL,
  BOOK_BOOK_WORKPLACE_PUT_URL,
  BOOK_BOOK_WORKPLACE_LIST_URL,
  BOOK_BOOK_SUPERVISOR_PUT_URL,
  BOOK_BOOK_SUPERVISOR_LIST_URL,
  BOOK_BOOK_UPGRADE,
  STATISTICS_STUDENTS_URL,
  STATISTICS_STUDENTS_EXCEL_URL,
  STATISTICS_WORKPLACES_URL,
  STATISTICS_WORKPLACES_EXCEL_URL,
  STATISTICS_CONTRACTS_URL,
  STATISTICS_CONTRACTS_EXCEL_URL,
};
