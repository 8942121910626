<template>
  <div>
    <b-tabs class="displayTabs">
      <b-tab title="Raða eftir verkþáttum" @click="loadCategory" active>
        <br />
        <i v-if="loading.category" class="fa fa-spin fa-spinner"></i>
        <div v-for="category in categoryList" :key="category.id">
          <h5 style="padding-left: 0.75rem">{{ category.name }}</h5>
          <table class="table">
            <tr v-if="category.factors && category.factors.length > 0">
              <th>Verkþáttur</th>
              <th>Hæfnikröfur</th>
            </tr>
            <tr v-if="category.factors && category.factors.length == 0">
              <td><i>Engir verkþættir skráðir</i></td>
            </tr>
            <tbody v-for="factor in category.factors" :key="factor.id">
              <tr v-bind:class="{ 'collapsed collapsed-border': factor.collapse }">
                <td>{{ factor.name }}</td>
                <td>
                  <div v-for="competence in factorCompetence(factor)" :key="competence.id">{{ competence.name }}</div>
                  <a href @click.stop.prevent="toggleFactor(factor)" v-if="canEdit">
                    <i>
                      <span v-if="factor.collapse">Loka</span>
                      <span v-else>Tengja</span>
                    </i>
                  </a>
                </td>
              </tr>
              <tr v-if="factor.collapse" class="collapsed-border">
                <td colspan="2">
                  <div v-if="factor.competenceList.length == 0"><i>Engar hæfnikröfur skráðar fyrir þessa starfsgrein</i></div>
                  <div v-for="c in factor.competenceList" :key="c.id">
                    <strong>{{ c.name }}</strong>
                    <table class="table" style="background-color: inherit">
                      <tr v-if="c.competence.length == 0">
                        <td><i>Engar hæfnikröfur skráðar</i></td>
                      </tr>
                      <tr v-for="competence in c.competence" :key="competence.id">
                        <td width="5%">
                          <b-form-checkbox
                            v-model="competence.selected"
                            @change="saveCompetence($event, factor, competence)"
                            :value="true"
                            :unchecked-value="false"
                          ></b-form-checkbox>
                        </td>
                        <td>
                          {{ competence.name }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-tab>
      <b-tab title="Raða eftir hæfnikröfum" @click="loadCompetence">
        <br />
        <i v-if="loading.competence" class="fa fa-spin fa-spinner"></i>
        <div v-if="competenceList && competenceList.length == 0 && !loading.competence">
          <i>Engar hæfnikröfur skráðar fyrir þessa starfsgrein</i>
        </div>
        <div v-for="category in competenceList" :key="category.id">
          <h5 style="padding-left: 0.75rem">{{ category.name }}</h5>
          <table class="table">
            <tr v-if="category.competence && category.competence.length > 0">
              <th>Hæfnikröfur</th>
              <th>Verkþáttur</th>
            </tr>
            <tr v-if="category.competence.length == 0">
              <td><i>Engar hæfnikröfur skráðar</i></td>
            </tr>
            <tbody v-for="competence in category.competence" :key="competence.id">
              <tr v-bind:class="{ 'collapsed collapsed-border': competence.collapse }">
                <td>{{ competence.name }}</td>
                <td>
                  <div v-for="connected in competenceFactors(competence)" :key="connected.id">{{ connected.name }}</div>
                  <!--div v-for="connected in competence.connected">{{connected.factor.name}}</div-->
                  <a href @click.stop.prevent="toggleCompetence(competence)" v-if="canEdit">
                    <i>
                      <span v-if="competence.collapse">Loka</span>
                      <span v-else>Tengja</span>
                    </i>
                  </a>
                </td>
              </tr>
              <tr v-if="competence.collapse" class="collapsed-border">
                <td colspan="2">
                  <div v-for="c in competence.categoryList" :key="c.id">
                    <strong>{{ c.name }}</strong>
                    <table class="table" style="background-color: inherit">
                      <tr v-if="c.factors.length == 0">
                        <td><i>Engir verkþættir skráðir</i></td>
                      </tr>
                      <tr v-for="factor in c.factors" :key="factor.id">
                        <td width="5%">
                          <b-form-checkbox
                            v-model="factor.selected"
                            @change="saveCompetence($event, factor, competence)"
                            :value="true"
                            :unchecked-value="false"
                          ></b-form-checkbox>
                        </td>
                        <td>
                          {{ factor.name }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';

export default {
  name: 'connect-competence',
  props: ['professionId', 'canEdit'],
  data() {
    return {
      loading: {
        competence: false,
        category: false,
        compentenceList: false,
        categoryList: false,
      },
      competenceList: [],
      categoryList: [],
    };
  },
  methods: {
    async loadCompetence() {
      this.loading.competence = true;
      let categoryList = {};
      try {
        const response = await core.factorCategoryList(this.professionId);
        categoryList = response.data;
        const promises = [];
        categoryList.forEach((c) => {
          promises.push(
            core.factorList(c.id).then((response2) => {
              this.$set(c, 'factors', response2.data);
            }),
          );
        });
        await Promise.all(promises);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }

      this.competenceList = {};
      try {
        const response = await core.competenceCategoryList(this.professionId);
        this.competenceList = response.data;

        this.competenceList.forEach((c) => {
          if (c.competence) {
            c.competence.forEach((c2) => {
              this.$set(c2, 'categoryList', JSON.parse(JSON.stringify(categoryList)));
              c2.categoryList.forEach((c3) => {
                c3.factors.forEach((c4) => {
                  const item = c2.connected.filter((v) => v.factor.id === c4.id);
                  this.$set(c4, 'selected', item.length > 0);
                });
              });
            });
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.competence = false;
      }
    },
    async loadCategory() {
      this.loading.category = true;
      let competenceList = {};
      try {
        const response = await core.competenceCategoryList(this.professionId);
        competenceList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }

      this.categoryList = {};
      try {
        const response = await core.factorCategoryList(this.professionId);
        this.categoryList = response.data;
        const promises = [];
        this.categoryList.forEach((c) => {
          promises.push(
            core.factorList(c.id).then((response2) => {
              this.$set(c, 'factors', response2.data);
              if (c.factors) {
                c.factors.forEach((f) => {
                  this.$set(f, 'competenceList', JSON.parse(JSON.stringify(competenceList)));
                  f.competenceList.forEach((cc) => {
                    cc.competence.forEach((co) => {
                      const item = co.connected.filter((v) => v.factor.id === f.id);
                      this.$set(co, 'selected', item.length > 0);
                    });
                  });
                });
              }
            }),
          );
        });
        await Promise.all(promises);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.category = false;
      }
    },
    toggleFactor(factor) {
      this.$set(factor, 'collapse', factor.collapse !== undefined ? !factor.collapse : true);
    },
    toggleCompetence(competence) {
      this.$set(competence, 'collapse', competence.collapse !== undefined ? !competence.collapse : true);
    },
    factorCompetence(factor) {
      const items = [];
      factor.competenceList.forEach((category) => {
        category.competence.forEach((competence) => {
          if (competence.selected) {
            items.push(competence);
          }
        });
      });
      return items;
    },
    competenceFactors(competence) {
      const items = [];
      competence.categoryList.forEach((category) => {
        category.factors.forEach((factor) => {
          if (factor.selected) {
            items.push(factor);
          }
        });
      });
      return items;
    },
    async saveCompetence(event, factor, competence) {
      try {
        await core.competenceConnect({
          selected: event ? 1 : 0,
          factor: factor.id,
          competence: competence.id,
        });
        if (event === true) {
          this.displaySuccess(`${competence.name} vistað sem hæfnikrafa fyrir ${factor.name}`);
        } else {
          this.displaySuccess(`${competence.name} aftengt hæfnikröfunni ${factor.name}`);
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCategory();
  },
};
</script>
<style lang="scss">
.collapsed {
  background-color: #e7e7e7;
}
.collapsed-border {
  border: 2px solid #e7e7e7;
}

.displayTabs {
  .nav-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
</style>
