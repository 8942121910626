<template>
  <b-form @submit.prevent="validateBeforeSubmit" novalidate v-on:keyup.esc="editField(false)" v-click-outside="editField">
    <inline-edit class="mb-0" field="name" :doEdit="edit" @editField="editField" @saveField="validateBeforeSubmit">
      <span slot="content"><span v-html="description"></span> <i v-if="!description">Smelltu hér til að skrá</i></span>
      <span slot="input">
        <quill-editor id="description" name="description" v-model="description" ref="descriptionQuillEditor" :options="editorOption">
        </quill-editor>
      </span>
    </inline-edit>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex';

import { quillEditor } from 'vue-quill-editor';
import vClickOutside from 'v-click-outside';
import core from '@/api/core';
import InlineEdit from '@/components/InlineEdit.vue';

export default {
  name: 'factor-description-edit',
  components: {
    quillEditor,
    InlineEdit,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: ['factorId'],
  data() {
    return {
      loading: false,
      edit: false,
      description: '',
      editorOption: {
        /* quill options */
      },
    };
  },

  methods: {
    async getFactorDescription() {
      this.loading = true;
      try {
        const response = await core.factorDescription(this.factorId);
        this.description = response.data.factor_description;
      } catch (e) {
        this.$log.debug(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja lýsingu verkþáttar. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        // Engar villur í formi.
        this.loading = true;
        try {
          await core.factorPatch(this.factorId, {
            factor_description: this.description,
          });
          this.displaySuccess('Lýsing verkþáttar uppfærð');
          this.$emit('successful', {});
          this.edit = false;
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að vista lýsingu verkþáttar. Vinsamlegast reyndu aftur.`,
          );
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    editField({ doEdit }) {
      if (doEdit) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.getFactorDescription();
  },
};
</script>

<style></style>
