<template>
  <div>
    <b-alert variant="danger" :show="failed && errorList.length > 0">
      <ul class="list-unstyled mb-0">
        <li v-for="(err, idx) in errorList" :key="idx">{{ err }}</li>
      </ul>
    </b-alert>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-group
        label="Kennitala:"
        label-for="username"
        :state="(submitted || usernameCheckSuccess !== null) && errors.has('username') ? false : null"
        :feedback="errors.first('username')"
        description="Kennitala notanda (án bandstriks)"
      >
        <b-input-group>
          <b-form-input
            id="username"
            name="username"
            type="text"
            v-model="form.username"
            required
            placeholder="Sláðu inn kennitölu"
            :disabled="id !== null"
            :state="(submitted || usernameCheckSuccess !== null) && errors.has('username') ? false : null"
            v-validate="'required|length:10'"
            data-vv-as="kennitala"
            @input="checkUsername"
          ></b-form-input>
          <b-input-group-addon v-if="usernameCheckSuccess !== null" is-text>
            <strong class="text-danger" v-if="usernameCheckSuccess === false"><i class="fa fa-exclamation"></i></strong>
            <strong class="text-success" v-if="usernameCheckSuccess === true"><i class="fa fa-check"></i></strong>
          </b-input-group-addon>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Nafn:"
        label-for="name"
        :state="submitted && errors.has('name') ? false : null"
        :feedback="errors.first('name')"
        description="Fullt nafn notanda"
      >
        <b-form-input
          id="name"
          name="name"
          type="text"
          v-model="form.name"
          required
          placeholder=""
          :state="submitted && errors.has('name') ? false : null"
          v-validate="'required'"
          data-vv-as="nafn"
          :readonly="true"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Netfang:"
        label-for="email"
        :state="submitted && errors.has('email') ? false : null"
        :feedback="errors.first('email')"
        description="Netfang notanda"
      >
        <b-form-input
          id="email"
          name="email"
          type="email"
          v-model="form.email"
          required
          placeholder=""
          :state="submitted && errors.has('email') ? false : null"
          data-vv-as="netfang"
          :readonly="false"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Farsími:"
        label-for="mobile"
        :state="submitted && errors.has('mobile') ? false : null"
        :feedback="errors.first('mobile')"
        description="Farsími notanda"
      >
        <b-form-input
          id="mobile"
          name="mobile"
          type="tel"
          v-model="form.mobile"
          required
          placeholder=""
          :state="submitted && errors.has('mobile') ? false : null"
          data-vv-as="farsími"
          :readonly="false"
        ></b-form-input>
      </b-form-group>
      <!-- <b-form-group
        label="Tilsjónaraðili:"
        label-for="supervisor"
        :state="submitted && errors.has('supervisor') ? false : null"
        :feedback="errors.first('supervisor')"
        v-if="loggedInUser.is_admin === 1"
        description="Hvort notandi eigi að birtast í fellilista yfir tilsjónaraðila ferilbóka."
      >
        <b-form-checkbox id="supervisor" name="supervisor" type="text" v-model="form.is_supervisor" :value="true" :unchecked-value="false">
        </b-form-checkbox> </b-form-group
      ><b-form-group
        label="Umsýsluaðili:"
        label-for="instructor"
        :state="submitted && errors.has('instructor') ? false : null"
        :feedback="errors.first('instructor')"
        v-if="loggedInUser.is_admin === 1"
        description="Hvort notandi eigi að birtast í fellilista yfir umsýsluaðila ferilbóka."
      >
        <b-form-checkbox id="instructor" name="instructor" type="text" v-model="form.is_instructor" :value="true" :unchecked-value="false">
        </b-form-checkbox>
      </b-form-group>-->
      <!-- ADA: símmenntunviðbót: hak fyrir master -->
      <b-form-group
        label="Master Admin:"
        label-for="masterAdmin"
        :state="submitted && errors.has('masterAdmin') ? false : null"
        :feedback="errors.first('masterAdmin')"
        v-if="loggedInUser.is_master_admin === 1"
        description="Hvort notandi sé master admin notandi eða ekki."
      >
        <b-form-checkbox
          id="masterAdmin"
          name="masterAdmin"
          type="text"
          v-model="form.is_master_admin"
          :value="true"
          :unchecked-value="false"
        >
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Admin:"
        label-for="admin"
        :state="submitted && errors.has('admin') ? false : null"
        :feedback="errors.first('admin')"
        v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_admin === 1"
        description="Hvort notandi sé admin notandi eða ekki."
      >
        <b-form-checkbox id="admin" name="admin" type="text" v-model="form.is_admin" :value="true" :unchecked-value="false">
        </b-form-checkbox>
      </b-form-group>
      <!-- ADA: símmenntunviðbót: hak fyrir mms og símmenntun -->
      <b-form-group
        label="MMS notandi:"
        label-for="mmsuser"
        :state="submitted && errors.has('mmsuser') ? false : null"
        :feedback="errors.first('mmsuser')"
        v-if="loggedInUser.is_master_admin === 1 || (loggedInUser.is_mms === 1 && loggedInUser.is_education_center === 1)"
        description="Hvort notandi sé MMS notandi eða ekki."
      >
        <b-form-checkbox id="mmsuser" name="mmsuser" type="text" v-model="form.is_mms_user" :value="true" :unchecked-value="false">
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Símmenntunarnotandi:"
        label-for="educationCenterUser"
        :state="submitted && errors.has('educationCenterUser') ? false : null"
        :feedback="errors.first('educationCenterUser')"
        v-if="loggedInUser.is_master_admin === 1 || (loggedInUser.is_mms === 1 && loggedInUser.is_education_center === 1)"
        description="Hvort notandi sé símmenntunar notandi eða ekki."
      >
        <b-form-checkbox
          id="educationCenterUser"
          name="educationCenterUser"
          type="text"
          v-model="form.is_educationCenter_user"
          :value="true"
          :unchecked-value="false"
        >
        </b-form-checkbox>
      </b-form-group>

      <b-form-group label="Stofnanir:" description="Aðgangur notanda að stofnunum.">
        <div v-for="institution in institutionList" :key="institution.id">
          <div style="padding-top: 8px" :class="{ 'institution-selected': institution.selected }">
            <div class="d-inline-block" style="vertical-align: top; margin-left: 5px">
              <b-form-checkbox
                :id="`selected${institution.id}`"
                :name="`selected${institution.id}`"
                type="text"
                v-model="institution.selected"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </div>
            <div class="d-inline-block" style="vertical-align: top; padding-top: 2px">
              <h5 style="font-weight: 300">{{ institution.name }}</h5>
            </div>
          </div>
          <div v-if="institution.selected" style="border: 2px solid #cecece; padding: 10px">
            <b-row>
              <b-col>
                <div><strong>Skrifaðgangur</strong></div>
                <b-form-checkbox
                  :id="`edit${institution.id}`"
                  :name="`edit${institution.id}`"
                  type="text"
                  v-model="institution.edit"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </b-col>
              <b-col>
                <div><strong>Má stofna notendur</strong></div>
                <b-form-checkbox
                  :id="`createUsers${institution.id}`"
                  :name="`createUsers${institution.id}`"
                  type="text"
                  v-model="institution.create_users"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </b-col>
              <b-col>
                <div><strong>Má stofna ferilbók</strong></div>
                <b-form-checkbox
                  :id="`createBook${institution.id}`"
                  :name="`createBook${institution.id}`"
                  type="text"
                  v-model="institution.create_book"
                  @change="removeLimit(institution)"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </b-col>
              <b-col>
                <div><strong>Má breyta ferilbók</strong></div>
                <b-form-checkbox
                  :id="`updateBook${institution.id}`"
                  :name="`updateBook${institution.id}`"
                  type="text"
                  v-model="institution.update_book"
                  @change="removeLimit(institution)"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </b-col>
              <b-col>
                <div><strong>Má skoða tölfræði</strong></div>
                <b-form-checkbox
                  :id="`viewStatistics${institution.id}`"
                  :name="`viewStatistics${institution.id}`"
                  type="text"
                  v-model="institution.view_statistics"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div style="padding-top: 10px">
                  <div class="d-inline-block" style="vertical-align: top">
                    <b-form-checkbox
                      :id="`limit${institution.id}`"
                      :name="`limit${institution.id}`"
                      type="text"
                      v-model="institution.limit"
                      @change="limitChange(institution)"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                  </div>
                  <div class="d-inline-block" style="vertical-align: top; padding-top: 2px">Takmarka aðgang við ákveðnar starfsgreinar</div>
                </div>
              </b-col>
            </b-row>
            <div v-if="institution.limit">
              <b-row>
                <b-col>
                  <strong>Starfsgrein</strong>
                </b-col>
                <b-col>
                  <strong>Lesaðgangur</strong>
                </b-col>
                <b-col>
                  <strong>Skrifaðgangur</strong>
                </b-col>
                <b-col>
                  <strong>Má stofna ferilbók</strong>
                </b-col>
                <b-col>
                  <strong>Má breyta ferilbók</strong>
                </b-col>
              </b-row>
            </div>
            <!-- <div v-if="institution.limit"> -->
            <div v-for="profession in institution.professions" :key="profession.id">
              <div>
                <b-row>
                  <b-col>
                    {{ profession.name }}
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      :id="`read${institution.id}${profession.id}`"
                      :name="`read${institution.id}${profession.id}`"
                      type="text"
                      v-model="profession.selected"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      :id="`edit${institution.id}${profession.id}`"
                      :name="`edit${institution.id}${profession.id}`"
                      type="text"
                      v-model="profession.edit"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      :id="`createBook${institution.id}${profession.id}`"
                      :name="`createBook${institution.id}${profession.id}`"
                      type="text"
                      v-model="profession.create_book"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      :id="`updateBook${institution.id}${profession.id}`"
                      :name="`updateBook${institution.id}${profession.id}`"
                      type="text"
                      v-model="profession.update_book"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import auth from '@/api/auth';
import core from '@/api/core';
// import Shuttle from '@/components/Shuttle.vue';

import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'user-create-form',
  components: {
    // Shuttle,
  },
  props: ['id'],
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {
      submitted: false,
      loading: false,
      failed: false,
      created: false,
      usernameCheckSuccess: null,
      errorList: [],
      professionList: [],
      selected: [],
      institutionList: [],
      form: {
        name: '',
        username: '',
        is_admin: false,
        is_master_admin: false /* ADA: símmenntunarviðbót */,
        is_mms_user: false /* ADA: símmenntunarviðbót */,
        is_educationCenter_user: false /* ADA: símmenntunarviðbót */,
        institutions: [],
        professions: [],
      },
    };
  },
  methods: {
    async checkUsername(val) {
      this.form.name = '';
      if (val && val.length === 10) {
        try {
          this.loading = true;

          const response = await auth.userCheck(val);

          this.usernameCheckSuccess = response.data.name && !response.data.user;
          if (!response.data.name) {
            this.displayError('Kennitala finnst ekki í þjóðskrá');
            this.errors.add({
              field: 'username',
              msg: 'Þessi kennitala finnst ekki í þjóðskrá',
              scope: 'server',
            });
          }

          if (response.data.user) {
            this.displayError('Kennitala er nú þegar skráð');
            this.errors.add({
              field: 'username',
              msg: 'Þessi notandi er nú þegar til',
              scope: 'server',
            });
          }
          if (response.data.name && !response.data.user) {
            this.form.name = response.data.name;
          }
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að skrá notanda.
              Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
          this.errors.clear();
        }
      }
    },
    removeLimit(institution) {
      this.$set(institution, 'limit', false);
    },
    limitChange(institution) {
      if (!institution.limit) {
        this.$set(institution, 'edit', false);
        this.$set(institution, 'create_book', false);
        this.$set(institution, 'update_book', false);
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          const item = this.institutionList
            .filter((x) => x.selected)
            .map((i) => ({
              id: i.id,
              edit: i.edit ? 1 : 0,
              create_users: i.create_users ? 1 : 0,
              create_book: i.create_book ? 1 : 0,
              update_book: i.update_book ? 1 : 0,
              view_statistics: i.view_statistics ? 1 : 0,
              professions: i.limit
                ? i.professions
                    .filter((p) => p.selected)
                    .map((p) => ({
                      id: p.id,
                      edit: p.edit ? 1 : 0,
                      create_book: p.create_book ? 1 : 0,
                      update_book: p.update_book ? 1 : 0,
                    }))
                : [],
            }));

          const response = await auth.userCreate({
            username: this.form.username,
            name: this.form.name,
            email: this.form.email,
            mobile: this.form.mobile,
            admin: this.form.is_admin ? 1 : 0,
            master_admin: this.form.is_master_admin ? 1 : 0 /* ADA: símmenntunarviðbót */,
            mms_user: this.form.is_mms_user ? 1 : 0 /* ADA: símmenntunarviðbót */,
            educationCenter_user: this.form.is_educationCenter_user ? 1 : 0 /* ADA: símmenntunarviðbót */,
            institutions: JSON.stringify(item),
          });
          this.id = response.data.id;
          this.created = true;
          this.displaySuccess(`Notandinn „${this.form.name}“ skráður.`);
          this.$emit('successful', { id: this.id });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    async loadUser() {
      const response = await auth.userDetail(this.id);
      this.$set(this.form, 'name', response.data.name);
      this.$set(this.form, 'username', response.data.ssn);
      this.$set(this.form, 'email', response.data.email);
      this.$set(this.form, 'mobile', response.data.mobile);
      this.$set(this.form, 'is_admin', response.data.is_admin === 1);
      this.$set(this.form, 'is_master_admin', response.data.is_master_admin === 1); /* ADA: símmenntunarviðbót */
      this.$set(this.form, 'is_mms_user', response.data.is_mms === 1); /* ADA: símmenntunarviðbót */
      this.$set(this.form, 'is_educationCenter_user', response.data.is_education_center === 1); /* ADA: símmenntunarviðbót */
      this.institutionList.forEach((i) => {
        response.data.institutions.forEach((i2) => {
          if (i.id === i2.id) {
            this.$set(i, 'selected', true);
            this.$set(i, 'create_users', i2.can_create_users === 1);
            this.$set(i, 'edit', i2.can_edit === 1);
            this.$set(i, 'create_book', i2.can_create_book === 1);
            this.$set(i, 'update_book', i2.can_update_book === 1);
            this.$set(i, 'view_statistics', i2.can_view_statistics === 1);
            if (i2.professions.length > 0) {
              this.$set(i, 'limit', true);
              i.professions.forEach((p) => {
                i2.professions.forEach((p2) => {
                  if (p.id === p2.id) {
                    this.$set(p, 'selected', true);
                    this.$set(p, 'edit', p2.can_edit === 1);
                    this.$set(p, 'create_book', p2.can_create_book === 1);
                    this.$set(p, 'update_book', p2.can_update_book === 1);
                  }
                });
              });
            }
          }
        });
      });
    },
    async load() {
      try {
        this.loading = true;
        this.institutionList = [];
        const response = await core.institutionList(null, null, 1);
        response.data.forEach((r) => {
          this.institutionList.push({
            id: r.id,
            name: r.name,
            selected: false,
            create_users: false,
            edit: false,
            create_book: false,
            update_book: false,
            limit: false,
            professions: r.professions.map((p) => ({
              id: p.id,
              name: p.name,
              institution: r.id,
              selected: false,
              edit: false,
              create_book: false,
              update_book: false,
            })),
          });
        });
        if (this.id) {
          this.loadUser();
        }
      } catch (e) {
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    professionsChanged(val) {
      if (this.selected.length > 0) {
        this.selected = [];
      }

      val.forEach((v) =>
        this.form.professions.push({
          id: v.value,
          name: v.label,
          institution: v.institution,
          read_profession: false,
          write_profession: false,
          create_book: false,
        }),
      );
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.load();
  },
};
</script>
<style lang="scss">
.institution-selected {
  background-color: #cecece;
}
</style>
