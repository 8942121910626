<template>
  <fbk-page>
    <b-card>
      <div class="text-center" v-if="loading"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div>
      <div v-else>
        <h5 class="my-4">Yfirlit yfir útgáfur</h5>
        <div v-if="categoryList.length == 0"><i>Engar niðurstöður fundust.</i></div>
        <profession-category-item class="mb-3" v-for="(category, index) in categoryList" :key="index" :item="category" ref="category">
        </profession-category-item>
      </div>
    </b-card>
  </fbk-page>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import ProfessionCategoryItem from '@/components/version/ProfessionCategoryItem.vue';

export default {
  name: 'version-list',
  components: {
    ProfessionCategoryItem,
  },
  data() {
    return {
      loading: false,
      categoryList: [],
    };
  },
  methods: {
    /*
     * Sækja yfirlit yfir starfsgreinaflokkar.
     */
    async loadList() {
      this.loading = true;
      this.categoryList = [];
      try {
        const response = await core.professionCategoryList();
        this.categoryList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  created() {
    this.loadList();
  },
};
</script>
