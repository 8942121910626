<template>
  <div>
    <!-- <div>
      <h5>Útgáfa 1.1.0</h5>
      <p>Hér koma upplýsingar um útgáfur</p>
    </div>
    <div class="line-separator"></div> -->
    <div>
      <h5>Útgáfa 1.0.0</h5>
      <p>Ný útgáfa hefur verið sett inn</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'versions-modal',
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style>
.line-separator {
  border-bottom: 1px solid #d4c9c9;
  /* margin-left: 50px;
  margin-right: 50px; */
  margin: 25px 50px 25px 50px;
}
</style>
