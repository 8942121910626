<template>
  <div>
    <navbar></navbar>
    <b-container>
      <slot></slot>
    </b-container>
    <!-- <div style="text-align: right; padding-right: 400px; font-size: 11px; font-color: gray">
      <b-link href @click.prevent="versionModal" v-b-tooltip.hover title="Skoða útgáfur">
        Útgáfa 1.0.0
      </b-link>
    </div> -->
    <b-modal
      centered
      id="versionModal"
      size="lg"
      lazy
      :no-close-on-backdrop="false"
      ok-title="Loka"
      :ok-only="true"
      title="Útgáfur"
      ref="versionModal"
    >
      <versions-modal ref="versionModal"></versions-modal>
    </b-modal>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import VersionsModal from '@/components/careerbook/modal/VersionsModal.vue';

export default {
  name: 'page',
  components: {
    Navbar,
    VersionsModal,
  },
  methods: {
    versionModal() {
      this.$refs.versionModal.show();
    },
  },
};
</script>
