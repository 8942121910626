<template>
  <div>
    <div class="text-center" v-if="loading"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div>
    <div v-else>
      <div v-for="(factor, index) in factorList" :key="index">
        <factor-item
          :item="factor"
          :id="id"
          :index="index"
          :category="category"
          @updateCompleted="updateFactorCount"
          :student="student"
          :supervisor="supervisor"
          :instructor="instructor"
          :prevSubCategory="getPrevCategory(index)"
          :openForum="factor.id == factorForum"
          :openPhotos="factor.id == factorPhoto"
          :openOverview="factor.id == factorOverview"
          :competenceName="competenceName"
        >
        </factor-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import book from '@/api/book';
import FactorItem from '@/components/careerbook/category/FactorItem.vue';

export default {
  name: 'careerbook-category-factor-list',
  components: {
    FactorItem,
  },
  props: ['id', 'category', 'student', 'supervisor', 'instructor', 'factorForum', 'factorPhoto', 'factorOverview', 'competenceName'],
  data() {
    return {
      loading: false,
      factorList: null,
      finishedFactors: 0,
      confirmedFactors: 0,
      showPhotos: false,
      showForum: false,
      showOverview: false,
    };
  },
  methods: {
    async getFactors() {
      this.loading = true;
      try {
        let response = null;
        if (this.id) {
          response = await book.careerbookFactorList(this.id, this.category);
          response.data.forEach((r) => {
            this.$set(r, 'complete', r.complete === 1);
            this.$set(r, 'supervisor_confirmed', r.supervisor_confirmed === 1);
            this.$set(r, 'school_confirmed', r.school_confirmed === 1);
          });
        } else {
          response = await core.factorList(this.category);
        }
        this.factorList = response.data;
        this.updateFactorCount();
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja verkþætti. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    updateFactorCount() {
      this.finishedFactors = 0;
      this.confirmedFactors = 0;
      this.showPhotos = this.factorList.filter((f) => f.features.photos).length > 0;
      this.showForum = this.factorList.filter((f) => f.features.forum).length > 0;
      this.factorList.forEach((element) => {
        if (element.complete) {
          this.finishedFactors += 1;
        }
        if (element.supervisor_confirmed) {
          this.confirmedFactors += 1;
        }
        if (element.school_confirmed) {
          this.confirmedFactors += 1;
        }
      });
      this.$emit('factorInfo', {
        total: this.factorList.length,
        finished: this.finishedFactors,
        confirmed: this.confirmedFactors,
        photos: this.showPhotos,
        forum: this.showForum,
      });
    },
    getPrevCategory(index) {
      if (index > 0) {
        return this.factorList[index - 1].subcategory;
      }
      return null;
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.getFactors();
  },
};
</script>

<style></style>
