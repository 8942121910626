<template>
  <div>
    <div>
      Ertu viss um að þú viljir gera útgáfu fyrir <strong>{{ professionName }}</strong
      >?
    </div>
    <br />
    <div>
      Þegar þú gerir útgáfu eru eftirfarandi þættir afritaðir í núverandi mynd og ekki er hægt að breyta þeim nema gera aðra útgáfu:
    </div>
    <ul>
      <li>Verkflokkar</li>
      <li>Verkþættir</li>
      <li>Hæfnikröfur</li>
      <li>Tengingar hæfnikrafna við verkþætti</li>
      <li>Starfalýsing</li>
      <li>Upplýsingar</li>
      <li>Lög og reglugerðir</li>
    </ul>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';

export default {
  name: 'careerbook-version-modal',
  props: ['professionId', 'professionName'],
  data() {
    return {
      loading: false,
      submitted: false,
      failed: false,
      errorList: [],
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.$emit('loading', true);
          this.loading = true;

          const response = await core.professionVersionCreate(this.professionId, {});
          this.displaySuccess('Útgáfa hefur verið stofnuð.');
          this.$emit('successful', { id: response.data.id });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að vista útgáfu. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
          this.$emit('loading', false);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
