<template>
  <div>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate v-on:keyup.esc="editField(false)">
      <inline-edit class="mb-0" field="name" :doEdit="edit" @editField="editField" @saveField="validateBeforeSubmit">
        <span slot="content"><span v-html="contractText.text"></span> <i v-if="!contractText.text">Smelltu hér til að skrá</i></span>
        <span slot="input">
          <quill-editor id="contractText" name="contractText" v-model="text" ref="contractQuillEditor" :options="editorOption">
          </quill-editor>
        </span>
      </inline-edit>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { quillEditor } from 'vue-quill-editor';
import vClickOutside from 'v-click-outside';
import core from '@/api/core';
import InlineEdit from '@/components/InlineEdit.vue';

export default {
  name: 'contract-text-edit',
  components: {
    quillEditor,
    InlineEdit,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: ['contractText'],
  data() {
    return {
      edit: false,
      text: null,
      editorOption: {
        /* quill options */
      },
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        // Engar villur í formi.
        try {
          await core.contractCreate({
            id: this.contractText.id,
            text: this.text,
          });
          // this.description = this.text;
          this.displaySuccess('Upplýsingar uppfærðar.');
          this.$emit('successful', {});
          this.text = null;
          this.edit = false;
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    editField({ doEdit }) {
      if (doEdit) {
        this.edit = true;
        this.text = this.contractText.text;
      } else {
        this.edit = false;
        this.text = null;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
};
</script>

<style></style>
