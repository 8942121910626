<template>
  <b-form @submit.prevent="validateBeforeSubmit" novalidate v-on:keyup.esc="editField(false)" v-click-outside="editField">
    <inline-edit class="mb-0" field="name" :doEdit="edit" @editField="editField" @saveField="validateBeforeSubmit">
      <span slot="content">{{ item.name }}</span>
      <span slot="input">
        <b-form-input
          id="name"
          name="name"
          type="text"
          v-model="name"
          required
          placeholder="Sláðu inn heiti"
          :state="submitted && errors.has('name') ? false : null"
          v-validate="'required'"
          data-vv-as="heiti"
          v-on:keyup.enter="validateBeforeSubmit"
          ref="input"
        ></b-form-input>
      </span>
    </inline-edit>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex';

import vClickOutside from 'v-click-outside';
import core from '@/api/core';
import InlineEdit from '@/components/InlineEdit.vue';

export default {
  name: 'factor-item-edit',
  components: {
    InlineEdit,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: ['item'],
  data() {
    return {
      edit: false,
      submitted: false,
      loading: false,
      name: null,
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;

          await core.factorUpdate(this.item.id, {
            category: this.item.category.id,
            name: this.name,
            factor_description: this.item.factor_description,
          });

          this.updated = true;
          this.item.name = this.name;
          this.displaySuccess(`Verkþátturinn „${this.name}“ uppfærður.`);
          this.$emit('successful', { id: this.item.id });
          this.name = null;
          this.edit = false;
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að uppfæra verkþátt. Vinsamlegast reyndu aftur.`,
          );
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    editField({ doEdit }) {
      if (doEdit) {
        this.edit = true;
        this.name = this.item.name;

        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);
      } else {
        this.edit = false;
        this.name = null;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
};
</script>

<style></style>
