<template>
  <div>
    <b-modal
      id="cancelContractModal"
      title="Ástæða afturköllunar samnings"
      size="sm"
      lazy
      ok-title="Staðfesta"
      cancel-title="Hætta við"
      :no-close-on-backdrop="true"
      @ok.prevent="cancelContract"
      ref="cancelModal"
    >
      <contract-cancel ref="contractCancel" :id="id" @successful="successful"></contract-cancel>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ContractCancel from '@/components/careerbook/regulation/ContractCancel.vue';

export default {
  name: 'contract-cancel-modal',
  props: [],
  components: {
    ContractCancel,
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    show(id = null) {
      this.id = id;
      this.$refs.cancelModal.show();
    },
    isDisabled(reason) {
      const { cancel } = this;
      return cancel.length === 1 && cancel[0] !== reason.text;
    },
    cancelContract() {
      this.$refs.contractCancel.validateBeforeSubmit();
    },
    successful() {
      this.$emit('successful', {});
      this.$refs.cancelModal.hide();
    },
    hideModal() {
      this.$refs.cancelModal.hide();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {},
};
</script>
