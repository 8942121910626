<template>
  <div>
    <b-alert variant="danger" :show="failed && errorList.length > 0">
      <ul class="list-unstyled mb-0">
        <li v-for="(err, idx) in errorList" :key="idx">{{ err }}</li>
      </ul>
    </b-alert>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-group
        label="Heiti flokks:"
        label-for="name"
        :state="submitted && errors.has('name') ? false : null"
        :feedback="errors.first('name')"
      >
        <b-form-input
          id="name"
          name="name"
          type="text"
          v-model="form.name"
          required
          placeholder="Sláðu inn heiti"
          :state="submitted && errors.has('name') ? false : null"
          v-validate="'required'"
          data-vv-as="heiti"
        ></b-form-input>
      </b-form-group>
    </b-form>
    <i>Hæfnikröfur</i>
    <div v-for="(competence, index) in form.competence" :key="index">
      <b-input-group class="pb-1" v-on:keyup.enter="addCompetence()">
        <b-form-input
          :id="`competence-${index}`"
          :name="`competence-${index}`"
          type="text"
          v-model="competence.name"
          placeholder="Sláðu inn hæfnikröfu"
        >
        </b-form-input>
        <b-input-group-append v-if="index == form.competence.length - 1">
          <button type="button" class="btn btn-sm btn-secondary" @click="addCompetence()" :disabled="competence.name.length == 0">
            Bæta við
          </button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';

import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'category-create-form',
  props: ['professionId'],
  data() {
    return {
      submitted: false,
      loading: false,
      failed: false,
      created: false,
      errorList: [],
      form: {
        name: '',
        competence: [
          {
            name: '',
          },
        ],
      },
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;

          const response = await core.competenceCategoryCreate({
            name: this.form.name,
            profession: this.professionId,
          });

          this.id = response.data.id;
          this.created = true;

          const promises = [];
          this.form.competence.forEach((element) => {
            if (element.name.length > 0) {
              promises.push(
                core.competenceCreate({
                  category: this.id,
                  name: element.name,
                }),
              );
            }
          });

          await Promise.all(promises);

          this.displaySuccess(`Flokkur „${this.form.name}“ stofnaður.`);
          this.$emit('successful', { id: this.id });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(
              `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að vista flokk. Vinsamlegast reyndu aftur.`,
            );
          }
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    addCompetence() {
      this.form.competence.push({ name: '' });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    loading(val) {
      this.$emit('loading', val);
    },
  },
};
</script>
