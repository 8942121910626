<template>
  <div>
    <b-card header="Nemandi" header-bg-variant="secondary" header-text-variant="white" header-class="h5" class="mt-3">
      <div class="card-text">
        <b-row class="mt-2">
          <b-col>
            <strong>Nafn</strong>
            <div v-if="id">
              <div>{{ book.student.name }}</div>
              <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1">
                <div v-if="studentId" style="color: #c1c1c1; font-size: 9px">Nemandanúmer: {{ studentId }}</div>
                <div v-else style="color: #c1c1c1; font-size: 9px">Nemandanúmer: Nemandi finnst ekki</div>
              </div>
            </div>
            <div v-else>
              <div>"Nafn nemanda"</div>
              <div style="color: #c1c1c1">Nemandanúmer: "000000"</div>
            </div>
          </b-col>
          <b-col>
            <strong>Kennitala</strong>
            <div v-if="id">{{ book.student.id | subStr }}</div>
            <div v-else>"Kennitala nemanda"</div>
          </b-col>
          <b-col>
            <strong>Netfang</strong>
            <div v-if="id">{{ book.student.email }}</div>
            <div v-else>"Netfang nemanda"</div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <strong>Heimilisfang</strong>
            <div v-if="id">{{ book.student.address }}</div>
            <div v-else>"Heimilisfang nemanda"</div>
          </b-col>
          <b-col>
            <strong>Sveitafélag</strong>
            <div v-if="id">{{ book.student.postal }} {{ book.student.city }}</div>
            <div v-else>"Sveitafélag nemanda"</div>
          </b-col>
          <b-col>
            <strong>Farsími</strong>
            <div v-if="id">{{ book.student.mobile }}</div>
            <div v-else>"Farsími nemanda"</div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-btn
              class="pull-right"
              variant="primary"
              size="sm"
              v-if="(loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1) && (!id || (id && student && !book.agreement_date))"
              :disabled="!id"
              @click="confirmAgreement"
            >
              <i class="fa fa-fw fa-check-square-o"></i>
              Staðfesta nemanda
            </b-btn>
            <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1" style="font-size: 0.9rem">
              <i>
                Staðfest:
                <span v-if="book.agreement_date">{{ book.agreement_date | moment('DD.MM.YYYY HH:mm:ss') }}</span>
                <span v-else>Ekki búið að staðfesta</span>
              </i>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="studentRelatives" class="mt-2 border-bottom" style="padding: 30px 0 0 0">
          <b-col>
            <strong v-if="studentRelatives.length > 1">Aðstandendur</strong>
            <strong v-else>Aðstandandi</strong>
          </b-col>
          <b-col>
            <strong>Netfang</strong>
          </b-col>
          <b-col>
            <strong>Símanúmer</strong>
          </b-col>
        </b-row>
        <div v-for="relative in studentRelatives" :key="relative.forrad_id">
          <b-row class="mt-2">
            <b-col>
              <div v-if="id">{{ relative.nafn }}</div>
              <div v-else>"Nafn forráðamanns"</div>
            </b-col>
            <b-col>
              <div v-if="id">{{ relative.netfang }}</div>
              <div v-else>"Netfang forráðamanns"</div>
            </b-col>
            <b-col>
              <div v-if="id">{{ relative.simi_as }}</div>
              <div v-else>"Farsími forráðamanns"</div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <b-card
      header="Vinnustaður/Iðnmeistari/Ábyrgðaraðili"
      header-bg-variant="secondary"
      header-text-variant="white"
      header-class="h5"
      class="mt-3"
      v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1"
    >
      <div class="card-text">
        <div v-if="id">
          <div v-for="(workplace, index) in book.workplaces" :key="workplace.id">
            <b-row :class="{ break: index !== 0 }">
              <b-col>
                <strong>Nafn</strong>
                <div>{{ workplace.name }}</div>
              </b-col>
              <b-col>
                <strong>Kennitala</strong>
                <div>{{ workplace.ssn | subStr }}</div>
              </b-col>
              <b-col>
                <strong>Netfang</strong>
                <div>{{ workplace.email }}</div>
                <!-- Eftir að laga -->
              </b-col>
            </b-row>
            <b-row class="mt-2 mb-2">
              <b-col>
                <strong>Heimilisfang</strong>
                <div>{{ workplace.address }}</div>
              </b-col>
              <b-col>
                <strong>Sveitafélag</strong>
                <div>{{ workplace.postal }} {{ workplace.city }}</div>
              </b-col>
              <b-col>
                <strong>Sími</strong>
                <div>{{ workplace.phone_number }}</div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-else>
          <b-row>
            <b-col>
              <strong>Nafn</strong>
              <div>"Nafn vinnustaðar"</div>
            </b-col>
            <b-col>
              <strong>Kennitala</strong>
              <div>"Kennitala vinnustaðar"</div>
            </b-col>
            <b-col>
              <strong>Netfang</strong>
              <div>"Netfang vinnustaðar"</div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <strong>Heimilisfang</strong>
              <div>"Heimilisfang vinnustaðar"</div>
            </b-col>
            <b-col>
              <strong>Sími</strong>
              <div>"Sími vinnustaðar"</div>
            </b-col>
            <b-col>
              <strong>Sveitafélag</strong>
              <div>"Sveitafélag vinnustaðar"</div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
    <b-card
      :header="loggedInUser.is_education_center === 1 ? 'Matsaðili' : 'Tilsjónaraðili/Leiðbeinandi'"
      header-bg-variant="secondary"
      header-text-variant="white"
      header-class="h5"
      class="mt-3"
    >
      <div class="card-text">
        <div v-if="id">
          <div v-for="(sup, index) in book.supervisors" :key="sup.id">
            <b-row :class="{ break: index !== 0 }">
              <b-col>
                <strong>Nafn</strong>
                <div>{{ sup.name }}</div>
              </b-col>
              <b-col>
                <strong>Kennitala</strong>
                <div>{{ sup.id | subStr }}</div>
              </b-col>
              <b-col>
                <strong>Netfang</strong>
                <div>{{ sup.email }}</div>
              </b-col>
            </b-row>
            <b-row class="mt-2 mb-2">
              <b-col>
                <strong>Heimilisfang</strong>
                <div>{{ sup.address }}</div>
              </b-col>
              <b-col>
                <strong>Sveitafélag</strong>
                <div>{{ sup.postal }} {{ sup.city }}</div>
              </b-col>
              <b-col>
                <strong>Farsími</strong>
                <div>{{ sup.mobile }}</div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-btn
                  class="pull-right"
                  variant="primary"
                  size="sm"
                  v-if="
                    (loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1) &&
                    (!id || (id && supervisor && !sup.agreement_date && sup.is_me == 1))
                  "
                  :disabled="!id"
                  @click="confirmAgreement"
                >
                  <i class="fa fa-fw fa-check-square-o"></i>
                  Staðfesta tilsjónaraðila
                </b-btn>
                <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1" style="font-size: 0.9rem">
                  <i>
                    Staðfest:
                    <span v-if="sup.agreement_date">{{ sup.agreement_date | moment('DD.MM.YYYY HH:mm:ss') }}</span>
                    <span v-else>Ekki búið að staðfesta</span>
                  </i>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-else>
          <b-row>
            <b-col>
              <strong>Nafn</strong>
              <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1">"Nafn tilsjónaraðila"</div>
              <div v-else>"Nafn matsaðila"</div>
            </b-col>
            <b-col>
              <strong>Kennitala</strong>
              <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1">"Kennitala tilsjónaraðila"</div>
              <div v-else>"Kennitala matsaðila"</div>
            </b-col>
            <b-col>
              <strong>Netfang</strong>
              <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1">"Netfang tilsjónaraðila"</div>
              <div v-else>"Netfang matsaðila"</div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <strong>Heimilisfang</strong>
              <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1">"Heimilisfang tilsjónaraðila"</div>
              <div v-else>"Heimilisfang matsaðila"</div>
            </b-col>
            <b-col>
              <strong>Farsími</strong>
              <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1">"Farsími tilsjónaraðila"</div>
              <div v-else>"Farsími matsaðila"</div>
            </b-col>
            <b-col>
              <strong>Sveitafélag</strong>
              <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1">"Sveitafélag tilsjónaraðila"</div>
              <div v-else>"Sveitafélag matsaðila"</div>
            </b-col>
          </b-row>
          <b-row v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1" class="mt-2">
            <b-col>
              <b-btn class="pull-right" variant="primary" size="sm" disabled>
                <i class="fa fa-fw fa-check-square-o"></i>
                Staðfesta tilsjónaraðila
              </b-btn>
              <div style="font-size: 0.9rem">
                <i>
                  Staðfest:
                  <span>"dags"</span>
                </i>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <b-card
      :header="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1 ? 'Umsýsluaðili/Skóli' : 'Umsýsluaðili'"
      header-bg-variant="secondary"
      header-text-variant="white"
      header-class="h5"
      class="mt-3"
    >
      <div class="card-text">
        <div v-if="id">
          <div v-if="book.instructors.length === 0">
            <i v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1">Enginn umsýsluaðili/skóli skráður</i>
            <i v-else>Enginn umsýsluaðili skráður</i>
          </div>
          <div v-for="(inst, index) in book.instructors" :key="inst.id">
            <b-row :class="{ break: index !== 0 }">
              <b-col>
                <strong>Nafn</strong>
                <div>{{ inst.name }}</div>
              </b-col>
              <!-- <b-col>
                <strong>Kennitala</strong>
                <div>{{ inst.id | subStr }}</div>
              </b-col> -->
              <b-col>
                <strong>Netfang</strong>
                <div>{{ inst.email }}</div>
              </b-col>
              <b-col> </b-col>
            </b-row>
            <!-- <b-row class="mt-2 mb-2">
              <b-col>
                <strong>Heimilisfang</strong>
                <div>{{ inst.address }}</div>
              </b-col>
              <b-col>
                <strong>Sveitafélag</strong>
                <div>{{ inst.postal }} {{ inst.city }}</div>
              </b-col>
              <b-col>
                <strong>Farsími</strong>
                <div>{{ inst.mobile }}</div>
              </b-col>
            </b-row> -->
            <b-row class="mt-2 mb-2">
              <b-col>
                <b-btn
                  class="pull-right"
                  variant="primary"
                  size="sm"
                  v-if="
                    ((loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1) && !id) ||
                    (id && instructor && !inst.agreement_date && inst.is_me == 1)
                  "
                  :disabled="!id"
                  @click="confirmAgreement"
                >
                  <i class="fa fa-fw fa-check-square-o"></i>
                  Staðfesta umsýsluaðila
                </b-btn>
                <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1" style="font-size: 0.9rem">
                  <i>
                    Staðfest:
                    <span v-if="inst.agreement_date">{{ inst.agreement_date | moment('DD.MM.YYYY HH:mm:ss') }}</span>
                    <span v-else>Ekki búið að staðfesta</span>
                  </i>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-else>
          <b-row>
            <b-col>
              <strong>Nafn</strong>
              <div>"Nafn umsýsluaðila"</div>
            </b-col>
            <b-col>
              <strong>Kennitala</strong>
              <div>"Kennitala umsýsluaðila"</div>
            </b-col>
            <b-col>
              <strong>Netfang</strong>
              <div>"Netfang umsýsluaðila"</div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <strong>Heimilisfang</strong>
              <div>"Heimilisfang umsýsluaðila"</div>
            </b-col>
            <b-col>
              <strong>Póstnúmer</strong>
              <div>"Póstnúmer umsýsluaðila"</div>
            </b-col>
            <b-col>
              <strong>Sveitafélag</strong>
              <div>"Sveitafélag umsýsluaðila"</div>
            </b-col>
          </b-row>
          <b-row v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1" class="mt-2">
            <b-col>
              <b-btn class="pull-right" variant="primary" size="sm" disabled>
                <i class="fa fa-fw fa-check-square-o"></i>
                Staðfesta umsýsluaðila
              </b-btn>
              <div style="font-size: 0.9rem">
                <i>
                  Staðfest:
                  <span>"dags"</span>
                </i>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import book from '@/api/book';

export default {
  name: 'agreement-list',
  props: ['professionName', 'book', 'id', 'student', 'studentId', 'studentRelatives', 'supervisor', 'instructor'],
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {
      loading: true,
    };
  },
  filters: {
    subStr(string) {
      const newString = `${string.substring(0, 6)}-${string.substring(6, 10)}`;
      return newString;
    },
  },
  methods: {
    async confirmAgreement() {
      await book.confirmAgreement(this.id);
      this.$emit('reloadBook');
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
};
</script>

<style lang="scss">
.break {
  border-top: 2px solid #868e96;
  padding-top: 10px;
}

.table-border {
  thead,
  tbody {
    tr {
      td,
      th {
        border: 1px solid #868e96;
      }
    }
  }
}
</style>
