<template>
  <div>
    <b-alert variant="danger" :show="failed">
      <ul class="list-unstyled mb-0">
        <li v-for="(err, idx) in errorList" :key="idx">{{ err }}</li>
      </ul>
    </b-alert>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <!-- ADA: símmenntunviðbót: velja ef er master eða bæði mms og símmenntun -->
      <div v-if="loggedInUser.is_master_admin === 1 || (loggedInUser.is_mms === 1 && loggedInUser.is_education_center === 1)">
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-checkbox
                id="is_mms"
                name="is_mms"
                v-model="form.is_mms"
                :disabled="form.is_educationCenter"
                :value="true"
                :unchecked-value="false"
                v-b-popover.hover.left="'Sé hakað við hér stofnast stofnunin sem mms'"
              >
                Stofnun fyrir mms
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                id="is_educationcenter"
                name="is_educationcenter"
                v-model="form.is_educationCenter"
                :disabled="form.is_mms"
                :value="true"
                :unchecked-value="false"
                v-b-popover.hover.left="'Sé hakað við hér stofnast stofnunin sem símenntun'"
              >
                Stofnun fyrir símenntun
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
      </div>
      <b-form-group
        label="Heiti:"
        label-for="name"
        :state="submitted && errors.has('name') ? false : null"
        :feedback="errors.first('name')"
        description="Heiti stofnunar"
      >
        <b-form-input
          id="name"
          name="name"
          type="text"
          v-model="form.name"
          required
          placeholder="Sláðu inn heiti"
          :state="submitted && errors.has('name') ? false : null"
          v-validate="'required'"
          data-vv-as="heiti"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Kennitala:"
        label-for="ssn"
        :state="submitted && errors.has('ssn') ? false : null"
        :feedback="errors.first('ssn')"
        description="Kennitala stofnunar"
      >
        <b-form-input
          id="ssn"
          name="ssn"
          type="text"
          v-model="form.ssn"
          required
          placeholder="Sláðu inn kennitölu"
          :state="submitted && errors.has('ssn') ? false : null"
          v-validate="'required'"
          data-vv-as="kennitala"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Símanúmer:"
        label-for="phonenumber"
        :state="submitted && errors.has('phonenumber') ? false : null"
        :feedback="errors.first('phonenumber')"
        description="Símanúmer stofnunar"
      >
        <b-form-input
          id="phonenumber"
          name="phonenumber"
          type="tel"
          v-model="form.phone_number"
          required
          placeholder="Sláðu inn símanúmer"
          :state="submitted && errors.has('phonenumber') ? false : null"
          v-validate="'required'"
          data-vv-as="símanúmer"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Skóli:"
        label-for="is_school"
        :state="submitted && errors.has('is_school') ? false : ''"
        :feedback="errors.first('is_school')"
        description="Er stofnun skóli?"
      >
        <b-form-checkbox id="is_school" name="is_school" v-model="form.is_school" :value="true" :unchecked-value="false"> </b-form-checkbox>
      </b-form-group>

      <b-form-group
        label="Aðgangur að starfsgreinum:"
        label-for="professions"
        :state="submitted && errors.has('professions') ? false : null"
        description="Veldu hlut úr listanum til þess að bæta við aðgang"
        :feedback="errors.first('professions')"
        class="style-chooser"
      >
        <v-select
          id="professions"
          name="professions"
          multiple
          placeholder="Skrifaðu inn í reitinn til að leita að starfsgreinum"
          @input="professionsChanged"
          :debounce="250"
          v-model="selected"
          :options="options"
        >
          <template slot="no-options">Engar fleiri stofnanir</template>
        </v-select>
      </b-form-group>

      <table class="table table-sm">
        <thead>
          <tr>
            <th>Starfsgrein</th>
            <th>Lesaðgangur</th>
            <th>Skrifaðgangur</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in form.professions" :key="p.id">
            <td>{{ p.name }}</td>
            <td>
              <b-form-checkbox v-model="p.read" :disabled="p.write" :value="true" :unchecked-value="false"> </b-form-checkbox>
            </td>
            <td>
              <b-form-checkbox v-model="p.write" @change="p.read = true" :value="true" :unchecked-value="false"> </b-form-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import core from '@/api/core';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'institution-create-form',
  data() {
    return {
      isMMS: 0 /* ADA: símmenntunviðbót */,
      isEducationCenter: 0 /* ADA: símmenntunviðbót */,
      submitted: false,
      loading: false,
      failed: false,
      created: false,
      errorList: [],
      professionList: [],
      schoolList: [],
      selected: [],
      form: {
        name: '',
        ssn: '',
        phone_number: '',
        is_school: false,
        is_mms: false /* ADA: símmenntunviðbót */,
        is_educationCenter: false /* ADA: símmenntunviðbót */,
        professions: [],
      },
      contactForm: {
        name: '',
        mobile: '',
        email: '',
      },
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
    options() {
      return this.professionList
        .filter((v) => this.form.professions.filter((p) => p.id === v.id).length === 0)
        .map((v) => ({ value: v.id, label: v.name }));
    },
  },
  methods: {
    professionsChanged(val) {
      if (this.selected.length > 0) {
        this.selected = [];
      }

      val.forEach((v) =>
        this.form.professions.push({
          id: v.value,
          name: v.label,
          read: true,
          write: false,
        }),
      );
    },
    async listProfessions() {
      this.loading = true;
      try {
        const response = await core.professionList({});
        this.professionList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    addContact() {
      this.contactForm.push({ name: '', mobile: '', email: '' });
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];

        if (this.loggedInUser.is_master_admin === 1) {
          this.isMMS = this.form.is_mms === true ? 1 : 0;
          this.isEducationCenter = this.form.is_educationCenter === true ? 1 : 0;
        }

        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          const professions = this.form.professions.map((g) => ({
            id: g.id,
            write: g.write === true ? 1 : 0,
          }));
          const response = await core.institutionCreate({
            name: this.form.name,
            ssn: this.form.ssn,
            phone_number: this.form.phone_number,
            is_school: this.form.is_school ? 1 : 0,
            // is_mms: 1,
            // is_educationCenter: 0,
            is_mms: this.loggedInUser.is_master_admin === 1 ? this.isMMS : this.loggedInUser.is_mms /* ADA: símmenntunviðbót */,
            is_educationCenter:
              this.loggedInUser.is_master_admin === 1
                ? this.isEducationCenter
                : this.loggedInUser.is_education_center /* ADA: símmenntunviðbót */,
            professions: JSON.stringify(professions),
          });
          this.id = response.data.id;
          this.created = true;
          this.displaySuccess(`Stofnunin „${this.form.name}“ skráð.`);
          this.$emit('successful', { id: this.id });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    /*
     * Modal gluggar.
     */

    /*
     * Annað
     */
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.listProfessions();
  },
};
</script>
<style lang="scss">
input,
textarea {
  width: 100%;
}
.style-chooser .vs__open-indicator {
  fill: white;
}
</style>
