<template>
  <div class="rows">
    <div class="iconleft">
      <i v-if="imageIndex > 0" v-on:click="getNextImage(currImage, false)" class="fa fa-fw fa-angle-left"></i>
    </div>
    <div class="image cols">
      <img
        style="border: 1px solid black; width: 500px"
        id="myImage"
        alt="myImage"
        :src="`/fbk/book/download-image?key=${currImage.local_source}&type=1&book=${bookId}&token=${token}`"
      />
    </div>
    <div class="textarea cols" style="word-break: break-word">
      <div style="text-align: left; font-weight: bold">{{ currImage.factor_name }}</div>
      <div style="text-align: left; color: #1a8cff">#{{ currImage.factor_name }}</div>
      <div style="text-align: left; padding-top: 15px">
        {{ currImage.name }}
        <a
          v-show="!editName && student"
          v-b-tooltip.hover
          title="Smelltu til að bæta við/breyta heiti"
          @click.prevent="startEditing('image_name')"
          class="fa fa-fw fa-pencil grab"
        ></a>
      </div>
      <div style="padding-left: 0px">
        <input
          autofocus
          v-show="editName"
          type="text"
          ref="photoName"
          :id="'item-user-' + currImage.id"
          @keyup.esc="stopEditing('image_name')"
          @keydown.enter="save('image_name', currImage.local_source)"
          v-model="edit.image_name"
          maxlength="50"
        />
        <a
          v-show="editName === true"
          v-b-tooltip.hover
          title="Vista"
          class="fa fa-fw fa-check grab"
          style="color: green"
          @click="save('image_name', currImage.local_source)"
        ></a>
        <a
          v-show="editName === true"
          v-b-tooltip.hover
          title="Hætta við"
          class="fa fa-fw fa-times grab"
          style="color: red"
          @click="stopEditing('image_name')"
        ></a>
      </div>
      <div style="text-align: left; padding-top: 5px; font-size: 11px">
        {{ currImage.caption }}
        <a
          v-show="!editCaption && student"
          v-b-tooltip.hover
          title="Smelltu til að bæta við/breyta lýsingu"
          @click.prevent="startEditing('image_caption')"
          class="fa fa-fw fa-pencil grab"
        ></a>
      </div>
      <div>
        <input
          autofocus
          v-show="editCaption"
          type="text"
          ref="photoCaption"
          :id="'item-user-' + currImage.id"
          @keyup.esc="stopEditing('image_caption')"
          @keydown.enter="save('image_caption', currImage.local_source)"
          v-model="edit.image_caption"
          maxlength="150"
        />
        <a
          v-show="editCaption === true"
          v-b-tooltip.hover
          title="Vista"
          class="fa fa-fw fa-check grab"
          style="color: green"
          @click="save('image_caption', currImage.local_source)"
        ></a>
        <a
          v-show="editCaption === true"
          v-b-tooltip.hover
          title="Hætta við"
          class="fa fa-fw fa-times grab"
          style="color: red"
          @click="stopEditing('image_caption')"
        ></a>
      </div>
      <div style="position: absolute; bottom: 15px; right: 20px">{{ currImage.date_created | moment('DD.MM.YYYY HH:mm') }}</div>
    </div>
    <div class="iconright">
      <i v-if="imageIndex < this.imageList.length - 1" v-on:click="getNextImage(currImage, true)" class="fa fa-fw fa-angle-right"></i>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import book from '@/api/book';
import auth from '@/api/auth';

export default {
  name: 'photo-modal',
  props: ['imageId', 'image', 'bookId', 'categoryId', 'factorId', 'student'],
  data() {
    return {
      currImage: {
        id: '',
        name: '',
        local_source: null,
        caption: '',
        date_created: '',
        factor_id: '',
        factor_name: '',
      },
      nextImage: {
        id: '',
        name: '',
        local_source: null,
        caption: '',
        date_created: '',
        factor_id: '',
        factor_name: '',
      },
      edit: {
        image_id: null,
        image_name: '',
        image_caption: '',
      },
      imageList: [],
      imageIndex: '',
      imageItem: '',
      url: '',
      loading: false,
      ImageURL: '',
      editCaption: false,
      editName: false,
      token: auth.getToken(),
    };
  },
  methods: {
    async refetchImageInfo() {
      this.loading = true;
      try {
        const response = await book.factorImageInfo(this.currImage.local_source);
        this.imageList = response.data;
        this.currImage.id = response.data.id;
        this.currImage.name = response.data.image_name;
        this.currImage.caption = response.data.caption;
        this.currImage.local_source = response.data.local_source;
        this.currImage.date_created = response.data.date_created;
        this.currImage.factor_id = response.data.factor_id;
        this.currImage.factor_name = response.data.factor_name;
        this.loading = false;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async getImages() {
      this.loading = true;
      try {
        const response = await book.imageList(this.bookId, this.categoryId, this.factorId);
        this.imageList = response.data;
        this.imageIndex = this.findIndex(this.currImage.id, null);
        this.loading = false;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    setImage() {
      this.currImage.id = this.image.id;
      this.currImage.name = this.image.name;
      this.currImage.caption = this.image.caption;
      this.currImage.local_source = this.image.local_source;
      this.currImage.date_created = this.image.date_created;
      this.currImage.factor_id = this.image.factor_id;
      this.currImage.factor_name = this.image.factor_name;
    },
    getNextImage(image, isNext) {
      const nextImage = this.findIndex(image.id, isNext);
      if (nextImage !== -1) {
        const vm = this;
        vm.$set(this.currImage, 'id', nextImage.id);
        vm.$set(this.currImage, 'name', nextImage.name);
        vm.$set(this.currImage, 'local_source', nextImage.local_source);
        vm.$set(this.currImage, 'caption', nextImage.caption);
        vm.$set(this.currImage, 'date_created', nextImage.date_created);
        vm.$set(this.currImage, 'factor_id', nextImage.factor_id);
        vm.$set(this.currImage, 'factor_name', nextImage.factor_name);
      }
    },
    findIndex(id, isNext) {
      let i = 0;
      for (i = 0; i < this.imageList.length; i += 1) {
        if (this.imageList[i].id === id) {
          if (isNext) {
            this.imageIndex = i + 1;
            return this.imageList[i + 1];
          }
          if (isNext === null) {
            this.imageIndex = i;
            return this.imageIndex;
          }
          if (!isNext) {
            this.imageIndex = i - 1;
            return this.imageList[i - 1];
          }
        }
      }
      return -1;
    },
    startEditing(type) {
      if (type === 'image_name') {
        this.editName = true;
      } else {
        this.editCaption = true;
      }
    },
    stopEditing(type) {
      if (type === 'image_name') {
        this.editName = false;
        this.edit.image_name = '';
      } else {
        this.editCaption = false;
        this.edit.image_caption = '';
      }
    },
    /*
      Vista dálka
    */
    async save(feature, id) {
      if (this.bookId) {
        const data = {};
        let successResponse = '';
        switch (feature) {
          case 'image_name':
            data.type = 'image_name';
            data.image_name = this.edit.image_name;
            data.photo_id = id;
            successResponse = 'Heiti myndar vistað';
            this.edit.image_name = '';
            book.careerbookCategoryNotification({
              id: this.bookId,
              notification: 0,
              category: this.categoryId,
              notificationType: 1,
              factor: this.currImage.factor_id,
              message: 'Heiti myndar vistað',
            });
            break;
          case 'image_caption':
            data.type = 'image_caption';
            data.image_caption = this.edit.image_caption;
            data.photo_id = id;
            successResponse = 'Lýsing myndar vistað';
            this.edit.image_caption = '';
            book.careerbookCategoryNotification({
              id: this.bookId,
              notification: 0,
              category: this.categoryId,
              notificationType: 1,
              factor: this.currImage.factor_id,
              message: 'Lýsing myndar vistað',
            });
            break;
          default:
            break;
        }
        try {
          await book.factorPatch(this.bookId, this.categoryId, this.factorId, data);
          switch (feature) {
            case 'image_name':
              this.currImage.image_name = this.edit.image_name;
              this.refetchImageInfo();
              this.editName = false;
              break;
            case 'image_caption':
              this.currImage.image_caption = this.edit.image_caption;
              this.refetchImageInfo();
              this.editCaption = false;
              break;
            default:
              break;
          }
          this.displaySuccess(successResponse);
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) við vistun kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    getNewCaption() {},
    close() {
      this.$emit('close');
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.setImage();
    this.getImages();
  },
};
</script>

<style>
div.image {
  padding-left: 5px;
}
div.textarea {
  padding-top: 10px;
  padding-left: 15px;
}
div.iconleft {
  position: absolute;
  top: 50%;
  left: -1.5%;
  font-size: 35px;
}
div.iconright {
  position: absolute;
  top: 50%;
  left: 95.5%;
  font-size: 35px;
}
div.rows {
  display: flex;
  height: 100%;
}
div.cols {
  flex: 1;
  width: 100%;
}
</style>
