<template>
  <div>
    <b-row>
      <b-col>
        <div class="mb-3 d-inline-block pr-1" v-if="id == null && canEdit">
          <b-link class="btn btn-primary" href @click.prevent="careerbookCategoryCreateModal" v-b-tooltip.hover title="Stofna verkflokk">
            <i class="fa fa-fw fa-plus-square"></i>
          </b-link>
        </div>
        <div class="mb-3 d-inline-block pr-1" v-if="id == null && canEdit">
          <b-link
            class="btn btn-secondary"
            href
            @click.prevent="orderByAlphabet()"
            v-b-tooltip.hover
            title="Raða öllum verkflokkum í stafrófsröð"
          >
            <i class="fa fa-sort-alpha-asc"></i>
          </b-link>
        </div>
        <div class="mb-3 d-inline-block pr-1" v-if="id == null && canEdit">
          <b-link
            class="btn btn-secondary"
            href
            @click.prevent="orderByDate()"
            v-b-tooltip.hover
            title="Raða öllum verkflokkum eftir stofnunardagsetningu"
          >
            <i class="fa fa-sort-amount-asc"></i>
          </b-link>
        </div>
        <div class="mb-3 d-inline-block pull-right pr-1">
          <b-link class="btn btn-secondary" href @click.prevent="openAllCategories()" v-b-tooltip.hover title="Opna alla verkflokka">
            <i class="fa fa-fw fa-folder-open"></i>
          </b-link>
        </div>
        <div class="mb-3 d-inline-block pull-right pr-1">
          <b-link class="btn btn-secondary" href @click.prevent="closeAllCategories()" v-b-tooltip.hover title="Loka öllum verkflokkum">
            <i class="fa fa-fw fa-folder"></i>
          </b-link>
        </div>
        <div class="mb-3 d-inline-block pull-right pr-1" v-if="id">
          <b-link class="btn btn-secondary" href @click.prevent="getExcel()" v-b-tooltip.hover title="Taka upplýsingar út í Excel">
            <i class="fa fa-fw fa-file-excel-o"></i>
          </b-link>
        </div>
        <div class="mb-3 d-inline-block pull-right pr-1" v-else-if="versionId">
          <b-link class="btn btn-secondary" href @click.prevent="getFactorExcel()" v-b-tooltip.hover title="Taka upplýsingar út í Excel">
            <i class="fa fa-fw fa-file-excel-o"></i>
          </b-link>
        </div>
        <div class="mb-3 d-inline-block pr-1" v-if="id && (instructor || loggedInUser.is_admin) && !book.copied_to">
          <b-button
            variant="primary"
            href
            @click.prevent="canGetConfirmationFile()"
            v-b-tooltip.hover
            title="Staðfesting á að ferilbók sé lokið"
          >
            <i>Staðfesting</i>
          </b-button>
        </div>
        <div v-if="book.copied_to" class="d-inline-block pr-1">
          <b-alert show variant="info">
            <i class="fa fa-exclamation mr-1" aria-hidden="true"></i>
            Þessi ferilbók hefur verið uppfærð og eiga allar skráningar að eiga sér stað í
            <a :href="`/ferilbok/${book.copied_to}`">nýju bókinni</a>.
          </b-alert>
        </div>
      </b-col>
    </b-row>
    <div class="text-center" v-if="loading"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div>
    <div v-else>
      <div v-if="categoryList.length == 0"><i>Engar niðurstöður fundust</i></div>
      <category-item
        v-for="(category, index) in categoryList"
        :key="index"
        :index="index"
        :item="category"
        :id="id"
        :professionId="professionId"
        :not-collapsed="category.id === createdId"
        :student="student"
        :supervisor="supervisor"
        :instructor="instructor"
        :canEdit="canEdit && !book.copied_to"
        @reload="loadCategory"
        ref="category"
      ></category-item>
    </div>
    <!-- Modal til að skrá starfsgreinaflokk -->
    <category-modals
      ref="careerbookCategoryCreateModal"
      :type="0"
      :professionId="professionId"
      :id="id"
      @successful="careerbookCategoryCreated"
    ></category-modals>
    <category-modals
      ref="careerbookCompetenceConnectModal"
      :type="5"
      :professionId="professionId"
      :id="id"
      :canEdit="canEdit"
      @successful="careerbookCompetenceConnected"
    ></category-modals>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Jspdf from 'jspdf';
import moment from 'moment';

import core from '@/api/core';
import book from '@/api/book';

import CategoryModals from '@/components/careerbook/modal/CategoryModals.vue';
import CategoryItem from '@/components/careerbook/category/CategoryItem.vue';

export default {
  name: 'category-list',
  components: {
    CategoryModals,
    CategoryItem,
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  props: ['professionId', 'versionId', 'id', 'student', 'supervisor', 'instructor', 'canEdit', 'book', 'professionName'],
  data() {
    return {
      loading: true,
      categoryList: null,
      createdId: null,
      isBookCompleted: false,
    };
  },
  methods: {
    async loadCategory() {
      this.loading = true;
      this.categoryList = {};
      try {
        let response = null;
        if (this.id) {
          response = await book.careerbookCategoryList(this.id);
        } else {
          response = await core.factorCategoryList(this.professionId, this.versionId);
        }
        this.categoryList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja verkflokka. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    openAllCategories() {
      this.$refs.category.forEach((c) => c.openCategory());
    },
    closeAllCategories() {
      this.createdId = null;
      this.$refs.category.forEach((c) => c.closeCategory());
    },
    /*
     * Aðgerðir á modal gluggum.
     */
    careerbookCategoryCreateModal() {
      this.$refs.careerbookCategoryCreateModal.show();
    },
    careerbookCategoryCreated(data) {
      this.createdId = data.id;
      this.loadCategory();
    },
    careerbookCompetenceConnectModal() {
      this.$refs.careerbookCompetenceConnectModal.show();
    },
    careerbookCompetenceConnected() {},
    async orderByAlphabet() {
      await core.factorCategorySortAll({
        profession: this.professionId,
        alpha: 1,
      });
      this.loadCategory();
    },
    async orderByDate() {
      await core.factorCategorySortAll({
        profession: this.professionId,
        alpha: 0,
      });
      this.loadCategory();
    },
    async getExcel() {
      try {
        await book.bookToExcel(this.id);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja ferilbók.
        Vinsamlegast reyndu aftur.`);
      }
    },
    getFactorExcel() {
      try {
        core.factorToExcel(this.professionId, this.versionId);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja ferilbók.
        Vinsamlegast reyndu aftur.`);
      }
    },
    async canGetConfirmationFile() {
      try {
        const response = await book.getCareerbookCategoryCompleted(this.book.id);

        this.isBookCompleted = !!this.book.date_to && response.data.total_uncompleted === 0;

        if (!this.isBookCompleted) {
          this.displayError('Ekki er hægt að taka út staðfestingarskjal nema lokadagsetning sé og öllum verkflokkum er lokið');
          throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        } else this.getConfirmationFile();
      } catch (e) {
        this.$log.debug(e);
      }
    },
    getConfirmationFile() {
      const doc = new Jspdf({
        orientation: 'portrait',
        unit: 'in',
        format: 'letter',
      });

      const pdfName = `Staðfesting_${this.book.student.name}_${this.book.student.id}`;

      // doc.setFontSize(16).setFont(undefined, 'bold').text(`Umsókn nr. ${app.application_id}: ${app.name}`, 0.5, 1.0);
      doc.setFontSize(14).setFont(undefined).text('Staðfesting á að', 4, 2, 'center'); // x y

      // Nafn og kennitala
      // eslint-disable-next-line max-len
      doc.setFontSize(14).setFont(undefined, 'bold').text(`${this.book.student.name}`, 4, 2.5, 'center'); // 'Arnar Dóri Ásgeirsson', 4, 2.5, 'center');

      doc.setFontSize(10).setFont(undefined, 'bold').text(`${this.book.student.id}`, 4, 2.7, 'center'); // '0608893339', 4, 2.7, 'center');

      // Texti
      doc
        .setFontSize(14)
        .setFont(undefined, 'normal')
        .text('hefur lokið öllum verkflokkum ferilbókar fyrir starfsgreinina', 4, 4.6, 'center');

      doc.setFontSize(18).setFont(undefined, 'bold').text(`${this.professionName}`, 4, 5.5, 'center'); // 'Smiður', 4, 5.5, 'center');

      doc.setFontSize(14).setFont(undefined, 'normal').text('Staðfest af', 4, 8, 'center');
      // eslint-disable-next-line max-len
      doc.setFontSize(14).setFont(undefined, 'bold').text(`${this.loggedInUser.name}`, 4, 8.6, 'center'); // 'Arnar Dóri Ásgeirsson', 4, 8.6, 'center');
      // eslint-disable-next-line max-len
      doc.setFontSize(14).setFont(undefined, 'bold').text(`${this.book.school.name}`, 4, 9, 'center'); // 'Borgarholtsskóli', 4, 9, 'center');
      // doc.setFontSize(10).setFont(undefined, 'normal').text('23.05.2022', 4, 9.3, 'center');
      doc.setFontSize(10).setFont(undefined, 'normal').text(moment().format('DD.MM.YYYY').toString(), 4, 9.3, 'center');

      // eslint-disable-next-line max-len
      doc.setFontSize(14).setFont(undefined, 'normal').text('__________________________________', 4, 10, 'center');

      doc.save(pdfName || '.pdf');
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.loadCategory();
  },
};
</script>

<style></style>
