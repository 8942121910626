<template>
  <div>
    <b-row>
      <b-col>
        <h5 class="my-4">Yfirlit yfir umsýsluaðila</h5>
      </b-col>
      <b-col>
        <div class="my-4 pull-right" v-if="loggedInUser.can_create_users === 1">
          <b-link class="btn btn-secondary pull-right" href @click.prevent="userCreateModal">
            <i class="fa fa-fw fa-plus-circle"></i>
            Skrá notanda
          </b-link>
        </div>
      </b-col>
    </b-row>
    <table class="table">
      <tr>
        <th style="padding: 10px 10px 10px 0px">
          <b-form-input id="name" name="name" type="text" v-model="searchName" placeholder="Leita eftir nafni eða kennitölu">
          </b-form-input>
        </th>
        <!-- <th style="padding: 10px 0px 10px 0px">
          <b-form-select id="institution" name="institution" v-model="searchInstitution">
            <option :value="null">-- Veldu stofnun --</option>
            <option v-for="(institution, index) in institutionList" :key="index" :value="institution">{{ institution.name }}</option>
          </b-form-select>
        </th> -->
      </tr>
    </table>

    <table class="table table-hover">
      <thead>
        <tr>
          <th style="width: 40px"></th>
          <th>Nafn</th>
          <th>Farsími</th>
          <th>Netfang</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="userList.length == 0">
          <td colspan="3">
            <i>Engar leitarniðurstöður fundust.</i>
          </td>
        </tr>
        <tr v-for="(user, index) in userList" :key="index">
          <td style="width: 40px" class="pr-0 pt-3">
            <avatar :name="user.name" class="d-inline-block mr-2" :monocolor="true"></avatar>
          </td>
          <td>
            <div class="d-inline-block">
              <span v-if="loggedInUser.can_create_users === 1">
                <a href @click.prevent="userUpdateModal(user.id)">{{ user.name }}</a>
              </span>
              <span v-else>
                {{ user.name }}
              </span>
              <br />
              <span class="small text-muted">{{ user.ssn }}</span>
            </div>
          </td>
          <td>{{ user.mobile }}</td>
          <td>{{ user.email }}</td>
        </tr>
      </tbody>
    </table>
    <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage"></pagination>
    <!-- Modal til að skrá notanda -->
    <user-crud-modal ref="userCreateModal" @successful="userUpdated"></user-crud-modal>
    <!-- Modal til að uppfæra notanda -->
    <user-crud-modal ref="userUpdateModal" @successful="userUpdated"></user-crud-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import auth from '@/api/auth';
import core from '@/api/core';
import Avatar from '@/components/Avatar.vue';
import Pagination from '@/components/Pagination.vue';
import UserCrudModal from '@/components/user/UserCrudModal.vue';

import { debounce, paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'instructors-list',
  mixins: [paginationMixin],
  components: {
    Avatar,
    Pagination,
    UserCrudModal,
  },
  data() {
    return {
      loading: {
        workplace: false,
        profession: false,
      },
      page: null,
      pagination: null,
      tabsFixed: false,
      userList: [],
      searchName: '',
      institutionList: [],
      searchInstitution: null,
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    /*
     * Sækja yfirlit yfir notendur.
     */
    async loadList(page = null, limit = null) {
      this.loading.workplace = true;
      this.pagination = null;
      this.userList = [];

      try {
        const response = await auth.instructorBookUserList(page, limit, this.searchName, this.searchInstitution);
        this.userList = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja nema. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.workplace = false;
      }
    },
    async loadInstitution() {
      this.loading.Institution = true;
      try {
        const response = await core.institutionList(null, null, null);
        this.institutionList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja yfirlit yfir stofnanir. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading.profession = false;
      }
    },

    /*
     * Aðgerðir á modal gluggum.
     */
    userCreateModal() {
      this.$refs.userCreateModal.show();
    },
    userUpdateModal(id) {
      this.$refs.userUpdateModal.show(id);
    },
    userCreated() {
      this.loadList();
    },
    userUpdated() {
      this.loadList();
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  watch: {
    searchName: debounce(function () {
      this.loadList(null, null);
    }, 500),
    searchInstitution: debounce(function () {
      this.loadList(null, null);
    }, 500),
  },
  created() {
    this.loadList();
    this.loadInstitution();
  },
};
</script>
<style lang="scss" scoped>
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
