<template>
  <div>
    <b-alert variant="danger" :show="failed">
      <ul class="list-unstyled mb-0">
        <li v-for="(err, idx) in errorList" :key="idx">{{ err }}</li>
      </ul>
    </b-alert>

    <b-row>
      <b-col>
        <div style="float: right; margin-bottom: 10px">
          <b-button @click="showUploadDocument()" v-if="hideUpload == true" size="sm"
            ><i class="fa fa-file-excel-o"></i> Hlaða upp skjali</b-button
          >
          <b-button @click="showUploadDocument()" v-if="hideUpload == false" size="sm">Handskrá</b-button>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div>
          <b-form @submit.prevent="validateBeforeSubmit" novalidate>
            <b-form-group
              label="Heiti starfsgreinaflokks:"
              label-for="name"
              :state="submitted && errors.has('name') ? false : null"
              :feedback="errors.first('name')"
            >
              <b-form-input
                id="name"
                name="name"
                type="text"
                v-model="form.name"
                required
                placeholder="Sláðu inn heiti"
                :state="submitted && errors.has('name') ? false : null"
                v-validate="'required'"
                data-vv-as="heiti"
              >
              </b-form-input>
              <p style="margin-top: 20px">Starfsgreinar sem tilheyra starfsgreinaflokki:</p>

              <!-- Ef á að lesa inn starfsgreinaflokk og starfsgreinar úr .csv eða .txt skrá -->
              <vue-csv-import
                v-if="hideUpload == false"
                v-model="csv"
                :map-fields="['Starfsgreinar']"
                :professionCategoryName="form.name"
                ref="VueCsvImport"
                @successful="successful"
              >
                <template slot="thead">
                  <tr>
                    <th>{{ form.name }}</th>
                    <td>{{ csv }}</td>
                  </tr>
                </template>
                <template slot="submit" slot-scope="{ submit }">
                  <button @click.prevent="submit">Skrá</button>
                </template>
              </vue-csv-import>
              <!-- </div>   -->
            </b-form-group>

            <!-- Ef á að handskrá starfsgreinaflokk og starfsgreinar -->
            <div v-if="hideUpload == true">
              <div v-for="(profession, index) in form.professions" :key="index">
                <b-input-group class="pb-1" v-on:keyup.enter="addProfession()">
                  <b-form-input
                    :id="`profession-${index}`"
                    :name="`profession-${index}`"
                    type="text"
                    v-model="profession.name"
                    placeholder="Sláðu inn heiti starfsgreinar"
                  >
                  </b-form-input>
                  <b-input-group-button slot="right" v-if="index == form.professions.length - 1">
                    <button type="button" class="btn btn-sm btn-secondary" @click="addProfession()" :disabled="profession.name.length == 0">
                      Bæta við
                    </button>
                  </b-input-group-button>
                </b-input-group>
              </div>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';

import { djangoErrorsToVee } from '@/utils';
import VueCsvImport from '@/components/shared/VueCsvImport.vue';

export default {
  name: 'profession-category-create-form',
  components: {
    VueCsvImport,
  },
  data() {
    return {
      hideUpload: false,
      submitted: false,
      loading: false,
      failed: false,
      created: false,
      errorList: [],
      form: {
        name: '',
        professions: [
          {
            name: '',
          },
        ],
      },
      csv: [
        {
          profession: '',
        },
      ],
    };
  },
  methods: {
    async validateBeforeSubmit() {
      if (this.hideUpload === true) {
        try {
          this.submitted = true;
          this.errorList = [];
          await this.$validator.validateAll().then((response) => {
            if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          });

          // Engar villur í formi.
          try {
            this.loading = true;

            const response = await core.professionCategoryCreate({
              name: this.form.name,
            });
            this.id = response.data.id;
            this.created = true;

            const promises = [];
            this.form.professions.forEach((element) => {
              if (element.name.length > 0) {
                promises.push(
                  core.professionCreate({
                    category: this.id,
                    name: element.name,
                  }),
                );
              }
            });

            await Promise.all(promises);
            this.displaySuccess(`Starfsgreinaflokkur „${this.form.name}“ stofnaður.`);
            this.$emit('successful', { id: this.id });
          } catch (e) {
            // Aðgerð mistókst.
            this.failed = true;
            this.$log.debug(e);
            if (e.response && e.response.status === 400) {
              djangoErrorsToVee(e.response.data, this.errors, this.errorList);
            } else {
              this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að stofna starfsgreinaflokk
                . Vinsamlegast reyndu aftur.`);
            }
          } finally {
            this.loading = false;
          }
        } catch (e) {
          // Villur í formi.
          this.$log.debug(e);
        }
      } else {
        this.$refs.VueCsvImport.submit();
      }
    },
    addProfession() {
      this.form.professions.push({ name: '' });
    },
    showUploadDocument() {
      this.hideUpload = !this.hideUpload;
    },
    successful(data) {
      this.$emit('successful', data);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    loading(val) {
      this.$emit('loading', val);
    },
  },
};
</script>
