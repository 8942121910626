<template>
  <div>
    <b-modal
      title="Uppfæra ferilbók"
      size="lg"
      :no-close-on-backdrop="true"
      ok-title="Afrita í nýja ferilbók"
      cancel-title="Hætta við"
      @ok="ok"
      @hidden="hidden"
      ref="copyModal"
      :ok-disabled="isUpgrading"
      :cancel-disabled="isUpgrading"
      :hide-header-close="isUpgrading"
    >
      Til að uppfæra starfsgrein ferilbókar verður hún afrituð í nýja ferilbók með nýrri útgáfu af starfsgreininni.
      <ul>
        <li>Aðeins virkir vinnustaðir verða afritaðir.</li>
        <li>
          Allir samningar verða afritaðir <strong>nema</strong> þeir sem er búið að afvirkja, afþakka eða sem eru tengdir við óvirka
          vinnustaði.
        </li>
        <li>Allir tilsjónaraðilar verða afritaðir.</li>
        <li>Allir umsjónaraðilar verða afritaðir.</li>
        <li>Verkþættir verða <strong>ekki</strong> afritaðir, heldur verður þeim skipt út fyrir verkþætti nýju útgáfunnar.</li>
      </ul>
      <b-progress v-if="isUpgrading" height="2rem" class="mt-2">
        <b-progress-bar :value="100" variant="info" striped animated>
          <span class="progress-font">Afrita ferilbók...</span>
        </b-progress-bar>
      </b-progress>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import book from '@/api/book';

export default {
  name: 'upgrade-profession-modal',
  data() {
    return {
      isUpgrading: false,
      bookIdToCopy: null,
      newBookId: null,
    };
  },
  methods: {
    show(bookId) {
      this.bookIdToCopy = bookId;
      this.$refs.copyModal.show();
    },
    async ok() {
      this.isUpgrading = true;
      try {
        const response = await book.upgrade(this.bookIdToCopy);
        this.$emit('ok', { bookId: response.data.bookId });
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að afrita ferilbók. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.isUpgrading = false;
      }
    },
    hidden() {
      this.$emit('hidden');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style scoped lang="scss">
.progress-font {
  font-size: 1rem;
}
</style>
