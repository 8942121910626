<template>
  <div class="rotateBox">
    <div>
      <span class="rotate" aria-hidden="true">{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RotateText',
  props: ['name'],
};
</script>

<style lang="scss" scoped>
.rotate {
  white-space: nowrap;
  display: block;
  line-height: 40px;
  position: absolute;
  bottom: 3px;
  -webkit-transform: rotate(-75deg);
  -webkit-transform-origin: 20px;
  -moz-transform: rotate(-75deg);
  -moz-transform-origin: 20px;
  -ms-transform: rotate(-75deg);
  -ms-transform-origin: 20px;
  -o-transform: rotate(-75deg);
  -o-transform-origin: 20px;
  transform: rotate(-75deg);
  transform-origin: 20px;
}

.rotateBack {
  white-space: nowrap;
  display: block;
  line-height: 40px;
  position: absolute;
  bottom: 0px;
  -webkit-transform: rotate(-75deg);
  -webkit-transform-origin: 20px;
  -moz-transform: rotate(-75deg);
  -moz-transform-origin: 20px;
  -ms-transform: rotate(-75deg);
  -ms-transform-origin: 20px;
  -o-transform: rotate(-75deg);
  -o-transform-origin: 20px;
  transform: rotate(-75deg);
  transform-origin: 20px;
}

.rotateBox {
  position: relative;
  height: 130px;
  width: 5px;
  margin-left: -7px;
}
</style>
