<template>
  <b-card class="mb-2" no-body>
    <div slot="header" @click="collapseCategory()" style="cursor: pointer">
      <b-row>
        <b-col md="7" lg="8">
          <h5 class="mb-0">
            <div v-if="edit && !id && canEdit">
              <b-input-group>
                <b-form-input
                  id="name"
                  name="name"
                  type="text"
                  v-model="name"
                  required
                  placeholder="Sláðu inn heiti"
                  :state="errors.has('name') ? false : null"
                  v-validate="'required'"
                  v-on:keyup.enter="saveCategory()"
                  v-on:keyup.esc="editCategory()"
                  ref="input"
                >
                </b-form-input>
                <b-input-group-append>
                  <button type="button" class="btn btn-sm btn-primary" @click.stop.prevent="saveCategory()" :disabled="submitting">
                    <i class="fa fa-fw fa-save"></i>
                  </button>
                  <button type="button" class="btn btn-sm btn-secondary" @click.stop.prevent="editCategory()" :disabled="submitting">
                    <i class="fa fa-fw fa-remove"></i>
                  </button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div v-else>
              <div
                class="pull-left d-inline"
                style="min-width: 40px"
                v-if="id && (instructor || loggedInUser.is_admin)"
                v-b-tooltip.hover
                title="Verkflokki lokið"
              >
                <b-form-checkbox
                  v-model="categoryItemConfirmed"
                  @click.native="editCategoryItem($event)"
                  @change="editCategoryItemConfirmed()"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="!instructor"
                ></b-form-checkbox>
              </div>
              <div class="pull-left d-inline" style="min-width: 28px">{{ index + 1 }}.</div>
              <div class="d-inline">
                <!-- <p class="d-inline" style="padding-right: 1px" v-b-popover.hover.html="getFactorDescription"
                title="Lýsing verkþáttar"> -->
                <p class="d-inline" style="padding-right: 1px" v-b-popover.hover.html="getFactorDescription" title="Verkþættir flokks">
                  {{ item.name }}
                </p>
                <i
                  v-if="!id && !editFactors && canEdit"
                  class="fa fa-fw fa-pencil hover-edit"
                  @click.stop.prevent="editCategory()"
                  v-b-tooltip.hover
                  title="Breyta verkflokki"
                >
                </i>
                <i
                  v-if="!id && !editFactors && !edit && canEdit"
                  class="fa fa-fw fa-check-square-o hover-edit"
                  @click.stop.prevent="enableFactorEdit()"
                  v-b-tooltip.hover
                  title="Breyta verkþáttum"
                >
                </i>
                <i
                  v-if="!id && !editFactors && !edit && canEdit"
                  class="fa fa-fw fa-sitemap hover-edit"
                  @click.stop.prevent="groupEdit()"
                  v-b-tooltip.hover
                  title="Breyta flokkun"
                >
                </i>
                <i
                  v-if="!id && !editFactors && !edit && canEdit"
                  class="fa fa-fw fa-commenting-o hover-edit"
                  @click.stop.prevent="descriptionEdit()"
                  v-b-tooltip.hover
                  title="Breyta lýsingum"
                >
                </i>
                <i
                  v-if="!id && !editFactors && !edit && canEdit && totalFactors === 0"
                  class="fa fa-fw fa-trash hover-edit"
                  @click.stop.prevent="deleteCategory()"
                  v-b-tooltip.hover
                  title="Eyða verkflokki"
                >
                </i>
              </div>
            </div>
          </h5>
        </b-col>
        <b-col md="5" lg="4">
          <div class="pull-right h5 mb-0 d-inline-block">
            <i class="fa fa-fw" :class="{ 'fa-chevron-down': collapse, 'fa-chevron-right': !collapse }"></i>
          </div>
          <div class="pull-right h5 mb-0 text-secondary d-inline-block" @click.stop.prevent="orderMoveUp()" v-if="!id && canEdit">
            <i class="fa fa-fw fa-arrow-up"></i>
          </div>
          <div class="pull-right h5 mb-0 text-secondary d-inline-block" @click.stop.prevent="orderMoveDown()" v-if="!id && canEdit">
            <i class="fa fa-fw fa-arrow-down"></i>
          </div>

          <div
            class="pull-right h5 mb-0 pr-2 text-secondary d-inline-block"
            v-bind:class="{ invisible: !showForum }"
            @click.stop.prevent="careerbookCategoryForumModal()"
            v-if="id"
            v-b-tooltip.hover
            title="Skoða umræður í verkflokki"
          >
            <div v-if="messages_counter != 0" @click="updateCategoryNotifications(2)">
              <i class="fa fa-fw fa-comment-o" style="color: red"></i>
            </div>
            <div v-if="messages_counter == 0"><i class="fa fa-fw fa-comment-o"></i></div>
          </div>
          <div
            class="pull-right h5 mb-0 pr-2 text-secondary d-inline-block"
            v-bind:class="{ invisible: !showPhotos }"
            @click.stop.prevent="careerbookCategoryPhotoModal()"
            v-if="id"
            v-b-tooltip.hover
            title="Skoða myndir í verkflokki"
          >
            <div v-if="images_counter != 0" @click="updateCategoryNotifications(1)">
              <i class="fa fa-fw fa-picture-o" style="color: red"></i>
            </div>
            <div v-if="images_counter == 0"><i class="fa fa-fw fa-picture-o"></i></div>
          </div>
          <div
            class="pull-right h5 mb-0 pr-2 text-secondary d-inline-block"
            @click.stop.prevent="careerbookCategoryOverviewModal()"
            v-if="id"
            v-b-tooltip.hover
            title="Yfirlit"
          >
            <div><i class="fa fa-fw fa-info-circle"></i></div>
          </div>
          <div class="pull-right h5 mb-0 pr-2 text-secondary d-inline-block" v-bind:class="{ invisible: !showForum }" v-if="id">
            <div v-if="is_new_notification == true">
              <i class="fa fa-fw fa-send-o" style="color: red" v-b-tooltip.hover title="Verkþáttur hefur verið uppfærður"></i>
            </div>
            <div v-if="is_new_notification != true"><i class="fa fa-fw fa-send-o"></i></div>
          </div>
          <div
            class="pull-right h5 mb-0 pr-2 d-inline-block"
            v-if="id"
            v-b-tooltip.hover
            :title="unconfirmed ? `${finishedFactors - confirmedFactors} óstaðfestir verkþættir` : 'Hæfni náð/Fjöldi verkþátta'"
          >
            <span v-if="loadingFactors"><i class="fa fa-fw fa-spin fa-spinner"></i></span>
            <span v-else
              ><span v-bind:class="{ 'font-weight-bold': unconfirmed }">{{ finishedFactors }}</span
              >/{{ totalFactors }} - {{
            }}</span>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-collapse id="collapse" v-model="collapse">
      <div class="card-body">
        <!-- <div v-if="!id && canEdit">
          <div class="pb-2">
            <strong>Tilgangur: </strong>
              <inline-edit
                class="mb-0"
                field="purpose"
                :doEdit="editPurpose"
                @editField="editPurposeField"
                @saveField="validatePurposeBeforeSubmit">
              <span slot="content"><i>{{item.purpose ? item.purpose : 'Ekki skráð'}}</i></span>
              <span slot="input">
                <b-form-textarea id="purpose" name="purpose"
                              type="text" v-model="purpose" required
                              placeholder="Sláðu inn tilgang"
                              :state="submitted && errors.has('purpose') ? false : null"
                              v-validate="'required'"
                              data-vv-as="tilgangur"
                              v-on:keyup.enter="validatePurposeBeforeSubmit"
                              ref="purposeInput"
                ></b-form-textarea>
              </span>
            </inline-edit>
          </div>
          <div class="pb-2">
              <strong>Markmið: </strong>
              <inline-edit
                class="mb-0"
                field="goal"
                :doEdit="editGoal"
                @editField="editGoalField"
                @saveField="validateGoalBeforeSubmit">
              <span slot="content"><i>{{item.goal ? item.goal : 'Ekki skráð'}}</i></span>
              <span slot="input">
                <b-form-textarea id="goal" name="goal"
                              type="text" v-model="goal" required
                              placeholder="Sláðu inn markmið"
                              :state="submitted && errors.has('goal') ? false : null"
                              v-validate="'required'"
                              data-vv-as="markmið"
                              v-on:keyup.enter="validateGoalBeforeSubmit"
                              ref="goalInput"
                ></b-form-textarea>
              </span>
            </inline-edit>
          </div>
        </div>
        <div v-else>
          <div v-if="item.goal != null && item.goal.length > 0" class="pb-2"><strong>Markmið: </strong><i>{{item.goal}}</i></div>
          <div v-if="item.purpose != null && item.purpose .length > 0"
            class="pb-2"><strong>Tilgangur: </strong><i>{{item.purpose}}</i></div>
        </div> -->
        <strong class="pb-2" style="font-size: 15px">Verkþættir: </strong>
        <factor-list-edit
          :category="item.id"
          v-if="!id && editFactors && canEdit"
          @factorInfo="updateFactorInfo"
          @disableEdit="disabledFactorEdit"
        ></factor-list-edit>
        <factor-list
          :id="id"
          :category="item.id"
          @factorInfo="updateFactorInfo"
          :student="student"
          :supervisor="supervisor"
          :instructor="instructor"
          :factorForum="factorForum"
          :factorPhoto="factorPhoto"
          :factorOverview="factorOverview"
          :competenceName="competenceName"
          v-else
        ></factor-list>
      </div>
    </b-collapse>
    <category-modals
      ref="careerbookCategoryOverviewModal"
      :type="1"
      :professionId="professionId"
      :category="item.id"
      :id="id"
      @successful="overviewSuccess"
    ></category-modals>
    <category-modals
      ref="careerbookCategoryPhotoModal"
      :type="2"
      :professionId="professionId"
      :category="item.id"
      :id="id"
      @successful="photoSuccess"
    ></category-modals>
    <category-modals
      ref="careerbookCategoryForumModal"
      :type="3"
      :professionId="professionId"
      :category="item.id"
      :id="id"
      @successful="forumSuccess"
    ></category-modals>
    <category-modals ref="factorDescriptionEditModal" :type="6" :category="item.id" @successful="descriptionEditSuccess"></category-modals>
    <category-modals ref="factorGroupEditModal" :type="7" :category="item.id" @successful="groupEditSuccess"></category-modals>
  </b-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import vClickOutside from 'v-click-outside';
import core from '@/api/core';
import book from '@/api/book';

// import InlineEdit from '@/components/InlineEdit.vue';

import FactorList from '@/components/careerbook/category/FactorList.vue';
import FactorListEdit from '@/components/careerbook/category/FactorListEdit.vue';
import CategoryModals from '@/components/careerbook/modal/CategoryModals.vue';

export default {
  name: 'careerbook-factor-category-item',
  components: {
    FactorList,
    FactorListEdit,
    CategoryModals,
    // InlineEdit,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: ['item', 'notCollapsed', 'id', 'professionId', 'student', 'supervisor', 'instructor', 'canEdit', 'index', 'competenceName'],
  data() {
    return {
      collapse: false,
      submitted: false,
      edit: false,
      editFactors: false,
      loading: false,
      submitting: false,
      name: null,
      editGoal: false,
      goal: null,
      editPurpose: false,
      purpose: null,
      totalFactors: null,
      finishedFactors: null,
      confirmedFactors: null,
      loadingFactors: true,
      showPhotos: null,
      showForum: null,
      factorForum: null,
      factorPhoto: null,
      factorOverview: null,
      images_counter: null,
      messages_counter: null,
      is_new_notification: false,
      categoryItemConfirmed: false,
    };
  },
  computed: {
    unconfirmed() {
      return this.supervisor && this.finishedFactors > 0 && this.finishedFactors !== this.confirmedFactors;
    },
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    getCategoryItemConfirmed() {
      const counterFactors = this.item.factors.filter((v) => v.id).length;
      const counterComplete = this.item.factors.filter((v) => v.category_factor_completed).length;

      this.categoryItemConfirmed = counterFactors === counterComplete;
    },
    editCategoryItem(event) {
      event.stopPropagation();
    },
    async editCategoryItemConfirmed() {
      const factors = [];

      try {
        this.item.factors.forEach((e) => {
          factors.push({ id: e.id });
        });

        const item = {
          is_completed: this.categoryItemConfirmed,
          category_factors: JSON.stringify(factors),
        };

        await book.careerbookCategoryCompleted(this.id, item);
      } catch (e) {
        this.$log.debug(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að uppfæra verkflokk. Vinsamlegast reyndu aftur.`,
        );
      }
    },
    // Sækir verkþætti fyrir tooltip
    getFactorDescription() {
      let html = '<ul>';
      this.item.factors.forEach((c) => {
        html += `<li>${c.name}</li>`;
      });
      html += '</ul>';

      if (this.item.factors.length === 0) {
        html = '<i>Engir tengdir</i>';
      }
      return html;
    },

    openCategory() {
      this.collapse = true;
    },
    closeCategory() {
      this.collapse = false;
      this.editFactors = false;
    },
    collapseCategory() {
      if (this.edit !== true) {
        this.collapse = !this.collapse;
        // this.careerbookCategoryNotificationUpdate();
        if (!this.collapse) {
          this.editFactors = false;
        }
      }
    },
    editCategory() {
      this.edit = !this.edit;
      if (this.edit) {
        this.name = this.item.name;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);
      } else {
        this.name = null;
      }
    },
    async saveCategory() {
      try {
        this.submitted = true;
        await this.$validator.validate().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        try {
          await core.factorCategoryPatch(this.item.id, {
            name: this.name,
          });
          this.item.name = this.name;
          this.displaySuccess(`Verkflokkur „${this.name}“ uppfærður.`);
          this.name = null;
          this.edit = false;
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að uppfæra verkflokk. Vinsamlegast reyndu aftur.`,
          );
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    forumSuccess(data) {
      this.factorForum = null;
      if (data.factor) {
        this.openCategory();
        this.factorForum = data.factor;
        this.$nextTick(() => {
          this.factorForum = null;
        });
      }
    },
    photoSuccess(data) {
      this.factorPhoto = null;
      if (data.factor) {
        this.openCategory();
        this.factorPhoto = data.factor;
        this.$nextTick(() => {
          this.factorPhoto = null;
        });
      }
    },
    overviewSuccess(data) {
      this.factorOverview = null;
      if (data.factor) {
        this.openCategory();
        this.factorOverview = data.factor;
        this.$nextTick(() => {
          this.factorOverview = null;
        });
      }
    },
    enableFactorEdit() {
      this.collapse = true;
      this.editFactors = true;
    },
    disabledFactorEdit() {
      this.editFactors = false;
    },
    updateFactorInfo(data) {
      this.loadingFactors = false;
      this.totalFactors = data.total;
      this.finishedFactors = data.finished;
      this.confirmedFactors = data.confirmed;
      this.showPhotos = data.photos;
      this.showForum = data.forum;
    },
    async orderMoveUp() {
      await core.factorCategorySortOne(this.item.id, {
        up: 1,
      });
      this.$emit('reload');
    },
    async orderMoveDown() {
      await core.factorCategorySortOne(this.item.id, {
        down: 1,
      });
      this.$emit('reload');
    },
    groupEditSuccess() {
      this.$emit('reload');
      this.openCategory();
    },
    descriptionEditSuccess() {
      this.$emit('reload');
      this.openCategory();
    },
    /**
     * Breyta markmiði
     */
    async validateGoalBeforeSubmit() {
      try {
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          await core.factorCategoryPatch(this.item.id, {
            goal: this.goal,
          });
          this.item.goal = this.goal;
          this.displaySuccess(`Markmið fyrir „${this.item.name}“ uppfært.`);
          this.goal = null;
          this.editGoal = false;
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að uppfæra markmið. Vinsamlegast reyndu aftur.`,
          );
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    editGoalField({ doEdit }) {
      if (doEdit) {
        this.editGoal = true;
        this.goal = this.item.goal;

        setTimeout(() => {
          this.$refs.goalInput.focus();
        }, 0);
      } else {
        this.editGoal = false;
        this.goal = null;
      }
    },
    /**
     * Breyta tilgangi
     */
    async validatePurposeBeforeSubmit() {
      try {
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          await core.factorCategoryPatch(this.item.id, {
            purpose: this.purpose,
          });
          this.item.purpose = this.purpose;
          this.displaySuccess(`Tilgangur fyrir „${this.item.name}“ uppfærður.`);
          this.purpose = null;
          this.editPurpose = false;
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að uppfæra tilgang. Vinsamlegast reyndu aftur.`,
          );
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    editPurposeField({ doEdit }) {
      if (doEdit) {
        this.editPurpose = true;
        this.purpose = this.item.purpose;

        setTimeout(() => {
          this.$refs.purposeInput.focus();
        }, 0);
      } else {
        this.editPurpose = false;
        this.purpose = null;
      }
    },
    async deleteCategory() {
        const conf = confirm(`Ertu viss um að þú viljir eyða verkþættinum "${this.item.name}"?`); //eslint-disable-line
      if (conf) {
        try {
          await core.factorCategoryDelete(this.item.id);
          this.$emit('reload', {});
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að eyða verkþætti. Vinsamlegast reyndu aftur.`,
          );
        }
      }
    },

    /**
     * Tilkynningar
     */
    async getNotifications() {
      this.loading = true;
      this.images_counter = null;
      this.messages_counter = null;
      this.is_new_notification = false;
      try {
        const response = await book.careerbookCategoryCount(this.id, this.item.id);
        this.images_counter = response.data[0].type;
        this.messages_counter = response.data[1].type;
        this.is_new_notification = response.data[11].new;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að búa til tilkynningu. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },

    async updateCategoryNotifications(updateNotification) {
      this.loading = true;
      try {
        const response = await book.careerbookCategoryNotificationUpdate({
          updateNotification,
          id: this.id,
          category: this.item.id,
        });
        this.$set(this.item, 'categorynotifications', response.data);
        this.getNotifications();
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Modal gluggar
     */
    careerbookCategoryOverviewModal() {
      this.$refs.careerbookCategoryOverviewModal.show();
    },
    careerbookCategoryPhotoModal() {
      this.$refs.careerbookCategoryPhotoModal.show();
    },
    careerbookCategoryForumModal() {
      this.$refs.careerbookCategoryForumModal.show();
    },
    groupEdit() {
      this.$refs.factorGroupEditModal.show();
    },
    descriptionEdit() {
      this.$refs.factorDescriptionEditModal.show();
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    if (this.notCollapsed) {
      this.collapseCategory();
    }
    this.getNotifications(this.id, this.item.id);

    this.getCategoryItemConfirmed();
  },
};
</script>

<style></style>
