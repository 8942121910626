<template>
  <fbk-page>
    <b-card class="careerbook-tabs">
      <b-row>
        <b-col>
          <h5 class="my-4">Tölfræði</h5>
        </b-col>
      </b-row>
      <b-row cols="2" class="d-none d-sm-none d-md-none d-lg-block">
        <b-col>
          <span ref="tabs"></span>
          <div class="pb-2">
            <b-nav class="mobile-navbar">
              <b-nav-item @click.prevent="tabIndex = 0" :active="tabIndex === 0">Nemendur</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 1" :active="tabIndex === 1">Vinnustaðir</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 2" :active="tabIndex === 2">Samningar</b-nav-item>
            </b-nav>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="12">
          <student-statistics v-if="tabIndex === 0"> </student-statistics>
          <workplace-statistics v-if="tabIndex === 1"> </workplace-statistics>
          <profession-statistics v-if="tabIndex === 2"> </profession-statistics>
        </b-col>
      </b-row>
    </b-card>
  </fbk-page>
</template>

<script>
import StudentStatistics from './StudentStatistics.vue';
import WorkplaceStatistics from './WorkplaceStatistics.vue';
import ProfessionStatistics from './ProfessionStatistics.vue';

export default {
  name: 'statistics',
  components: {
    StudentStatistics,
    WorkplaceStatistics,
    ProfessionStatistics,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
};
</script>
