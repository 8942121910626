<template>
  <div>
    <div style="text-align: left; font-weight: bold">{{ image.categry_name }}</div>
    <img id="myImage" alt="myImage" :src="`/fbk/book/download-image?key=${image.local_source}&type=2&book=${id}&token=${token}`" />
    <!-- <canvas id="myCanvas" width="150" height="150" style="border:1px solid #000000;">Your browser
      does not support the HTML5 canvas tag.</canvas> -->
    <!-- {{image.local_source}} -->
    <div v-if="isModal === true" style="text-align: left; color: #1a8cff; padding-top: 5px">#{{ image.factor_name }}</div>
    <div v-if="isModal === true" style="text-align: left; padding-top: 15px">{{ image.caption }}</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import auth from '@/api/auth';

export default {
  name: 'photo-box-item',
  props: ['image', 'id', 'isModal'],
  data() {
    return {
      imageItem: '',
      book_id: '',
      loading: false,
      token: auth.getToken(),
    };
  },

  methods: {
    draw() {
      const canvas = document.getElementById('myCanvas');
      const ctx = canvas.getContext('2d');
      const img = document.getElementById('myImage');
      ctx.drawImage(img, 0, 0);
      // const c = document.getElementById('canvas');
      // const img = document.getElementById('myImage');
      // let cnvsSize = '';
      // let isHorizontal = true;
      // if (img.width > img.height) {
      //   cnvsSize = img.width;
      //   isHorizontal = true;
      // } else {
      //   cnvsSize = img.height;
      //   isHorizontal = false;
      // }
      // c.width = img.width;
      // c.height = img.height;

      // const ctx = c.getContext('2d');
      // ctx.clearRect(0, 0, c.width, c.height);

      // let Y = -cnvsSize;
      // let X = -cnvsSize;

      // if (isHorizontal) {
      //   Y = (-img.height) / 2;
      // } else {
      //   X = (-img.width) / 2;
      // }
      // ctx.drawImage(img, X, Y);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  // created() {
  //   this.draw();
  // },
};
</script>
