/* eslint-disable import/prefer-default-export */

/**
 * Breytir stærð á mynd.
 * @param {File} file Mynd sem á að breyta um stærð.
 * @param {number} maxHeight Ný hæð myndar.
 * @param {number} maxWidth Ný breidd myndar.
 * @returns {string} Base64 strengur af nýrri mynd.
 */
export const resizeImage = async (file, maxHeight, maxWidth) => {
  return new Promise((resolve, reject) => {
    if (file.type.match(/image.*/)) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const image = new Image();
        image.onload = () => {
          // Resize the image
          const canvas = document.createElement('canvas');
          let { width } = image;
          let { height } = image;
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL(file.type);
          resolve(dataUrl);
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      reject();
    }
  });
};
