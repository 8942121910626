<template>
  <div>
    <b-alert variant="danger" :show="failed && errorList.length > 0">
      <ul class="list-unstyled mb-0">
        <li v-for="(err, idx) in errorList" :key="idx">{{ err }}</li>
      </ul>
    </b-alert>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-group
        label="Heiti verkflokks:"
        label-for="name"
        :state="submitted && errors.has('name') ? false : null"
        :feedback="errors.first('name')"
      >
        <b-form-input
          id="name"
          name="name"
          type="text"
          v-model="form.name"
          required
          placeholder="Sláðu inn heiti"
          :state="submitted && errors.has('name') ? false : null"
          v-validate="'required'"
          data-vv-as="heiti"
          ref="input"
        ></b-form-input>
      </b-form-group>
      Verkþættir
      <div v-for="(factor, index) in form.factors" :key="index" style="padding-top: 5px">
        <b-input-group class="pb-1" v-on:keyup.enter="addFactor()">
          <b-form-input
            :id="`factor-${index}`"
            :name="`factor-${index}`"
            type="text"
            v-model="factor.name"
            placeholder="Sláðu inn heiti verkþáttar"
          >
          </b-form-input>
          <b-input-group-append v-if="index == form.factors.length - 1">
            <button type="button" class="btn btn-sm btn-secondary" @click="addFactor()" :disabled="factor.name.length == 0">
              Bæta við
            </button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';

import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'category-create-form',
  props: ['professionId'],
  data() {
    return {
      submitted: false,
      loading: false,
      failed: false,
      created: false,
      errorList: [],
      form: {
        name: '',
        factors: [
          {
            name: '',
          },
        ],
      },
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;

          const response = await core.factorCategoryCreate({
            name: this.form.name,
            profession: this.professionId,
            goal: null,
            purpose: null,
          });

          this.id = response.data.id;
          this.created = true;

          /* eslint-disable no-restricted-syntax, guard-for-in, no-await-in-loop, prefer-const */
          for (let idx in this.form.factors) {
            if (this.form.factors[idx].name.length > 0) {
              const response2 = await core.factorCreate({
                category: this.id,
                name: this.form.factors[idx].name,
              });
              const promises = [
                core.factorUpdateFeature(response2.data.id, {
                  feature: 'complete',
                  value: true,
                }),
                core.factorUpdateFeature(response2.data.id, {
                  feature: 'supervisor_confirmed',
                  value: true,
                }),
                core.factorUpdateFeature(response2.data.id, {
                  feature: 'school_confirmed',
                  value: true,
                }),
              ];
              await Promise.all(promises);
            }
          }
          /* eslint-enable no-restricted-syntax, guard-for-in, no-await-in-loop, prefer-const */

          this.displaySuccess(`Verkflokkur „${this.form.name}“ stofnaður.`);
          this.$emit('successful', { id: this.id });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(
              `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að stofna verkflokk. Vinsamlegast reyndu aftur.`,
            );
          }
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    addFactor() {
      this.form.factors.push({ name: '' });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    loading(val) {
      this.$emit('loading', val);
    },
  },
  created() {
    setTimeout(() => {
      this.$refs.input.focus();
    }, 0);
  },
};
</script>
