<template>
  <fbk-page>
    <b-card class="pt-2 pl-2 pr-2">
      <h5 class="pl-2 pb-2">Almennar upplýsingar</h5>
      <instructions-edit
        v-if="loggedInUser.is_admin === 1 || loggedInUser.is_master_admin === 1"
        :instructions="instructions"
        @successful="loadInstructions"
      >
      </instructions-edit>
      <div v-else>
        <b-row class="p-2 pt-3">
          <b-col>
            <span v-html="instructions"></span>
            <span v-if="instructions == null || instructions.length == 0"><i>Upplýsingar ekki skráðar</i></span>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <br />
    <b-card class="pt-2 pl-2 pr-2">
      <h5 class="pl-2 pb-2">Almenn lög og reglugerðir</h5>
      <table class="table">
        <tr v-for="(regulation, index) in regulationList" :key="index">
          <td v-if="loggedInUser.is_admin === 1" class="p-2">
            <regulation-list-item :item="regulation"></regulation-list-item>
          </td>
          <td v-else class="p-2">
            <a :href="regulation.url" target="_blank">{{ regulation.name }} <i class="fa fa-external-link" aria-hidden="true"></i></a>
          </td>
        </tr>
      </table>

      <table class="table">
        <tr v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_admin === 1">
          <td class="p-2 pt-3">
            <b-form @submit.prevent="saveRegulation" novalidate>
              <b-row>
                <b-col>
                  <b-form-group label-for="name" :state="submitting && errors.has('name') ? false : null" :feedback="errors.first('name')">
                    <b-form-input
                      id="name"
                      name="name"
                      type="text"
                      v-model="regulation.name"
                      required
                      placeholder="Sláðu inn heiti reglugerðar"
                      :state="submitting && errors.has('name') ? false : null"
                      v-validate="'required'"
                      class="mr-2"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label-for="url" :state="submitting && errors.has('url') ? false : null" :feedback="errors.first('url')">
                    <b-input-group>
                      <b-form-input
                        id="url"
                        name="url"
                        type="text"
                        v-model="regulation.url"
                        required
                        placeholder="Sláðu inn hlekk á reglugerðina"
                        :state="submitting && errors.has('url') ? false : null"
                        v-validate="'required'"
                        v-on:keyup.enter="saveRegulation()"
                      >
                      </b-form-input>
                      <b-input-group-append>
                        <button type="button" class="btn btn-sm btn-primary" @click="saveRegulation()">Bæta við</button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </td>
        </tr>
      </table>
    </b-card>
  </fbk-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import core from '@/api/core';
import { djangoErrorsToVee } from '@/utils';

import RegulationListItem from '@/components/regulation/RegulationListItem.vue';
import InstructionsEdit from '@/components/regulation/InstructionsEdit.vue';

export default {
  name: 'regulation-list',
  components: {
    RegulationListItem,
    InstructionsEdit,
  },
  data() {
    return {
      loading: false,
      regulation: { name: '', url: '' },
      regulationList: [],
      submitting: false,
      instructions: '',
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    async loadList() {
      this.loading = true;
      this.regulationList = [];
      try {
        const response = await core.regulationList({});
        this.regulationList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadInstructions() {
      try {
        const response = await core.instructionsList({});
        this.instructions = response.data.instructions;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    validateUrl() {
      const regex = /^(http|https)/;
      if (this.regulation.url.length > 3 && !this.regulation.url.match(regex)) {
        this.regulation.url = `http://${this.regulation.url}`;
      }
    },
    async saveRegulation() {
      try {
        this.submitting = true;
        await (this.$validator.validate('url') && this.$validator.validate('name')).then((response) => {
          if (!response) {
            this.submitting = false;
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });
        try {
          this.validateUrl();
          await core.regulationCreate({
            url: this.regulation.url,
            name: this.regulation.name,
          });
          this.loadList();
          this.displaySuccess(`Regulgerðin „${this.regulation.name}“ stofnuð.`);
          this.regulation.name = '';
          this.regulation.url = '';
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.submitting = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.loadList();
    this.loadInstructions();
  },
};
</script>
