<template>
  <div
    :class="['gavatar', { 'gavatar-sm': size === 'sm', 'gavatar-outlined': outlined }]"
    :style="outlined ? { 'border-color': color } : { 'background-color': color }"
    :title="title || name"
  >
    {{ initials }}
  </div>
</template>

<script>
export default {
  name: 'avatar',
  props: {
    name: String,
    title: String,
    monocolor: Boolean,
    size: {
      type: String,
      default: 'md',
    },
    outlined: Boolean,
  },
  data() {
    return {
      colors: ['#3c6382', '#b71540', '#1e3799', '#60a3bc', '#e58e26', '#b71540', '#0c2461', '#0a3d62', '#079992'],
    };
  },
  computed: {
    initials() {
      if (!this.name) return '';
      const v = this.name.toString();
      return v
        .split(' ')
        .map((s) => s.charAt(0))
        .join('');
    },
    color() {
      if (this.monocolor) {
        return '#3c6382';
      }

      const userName = this.name;

      let sumChars = 0;
      for (let i = 0; i < userName.length; i += 1) {
        sumChars += userName.charCodeAt(i);
      }

      return this.colors[sumChars % this.colors.length];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

/*CSS fyrir avatar component*/
.gavatar {
  color: #fff;
  display: inline-block;
  font-family: Arial, &quot;
  font-size: 10px;
  border-radius: 80px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  margin: 0 5px;
  text-align: center;
  cursor: default;

  &.gavatar-sm {
    width: 27px;
    height: 27px;
    line-height: 27px;
    font-size: 10px;
    margin: 0 2px;
  }

  &.gavatar-outlined {
    color: black;
    border-width: 3px;
    border-style: solid;
    background-color: white;
    white-space: nowrap;
    line-height: 29px;
    &.gavatar-sm {
      border-width: 2px;
      line-height: 23px;
    }
  }
}
</style>
