<template>
  <div class="vue-csv-uploader">
    <div class="form">
      <div class="vue-csv-uploader-part-one">
        <!-- Tekið út að hægt sé að velja hvort það sé header í skjali. Ef breytt þarf að breyta hasHeaders í true líka -->
        <!-- <div class="form-check form-group csv-import-checkbox" v-if="headers === null">
                    <slot name="hasHeaders" :headers="hasHeaders" :toggle="toggleHasHeaders">
                        <input :class="checkboxClass" type="checkbox" id="hasHeaders" :value="hasHeaders" @change="toggleHasHeaders">
                        <label class="form-check-label" for="hasHeaders">
                            File Has Headers
                        </label>
                    </slot>
                </div> -->
        <div class="form-group csv-import-file">
          <i style="padding: 10px 10px 10px 0px"
            >Veldu .csv skrá sem inniheldur starfsgreinar. Skjal skal einungis innihalda heiti á starfsgreinum, ekki dálkheiti</i
          >
          <input ref="csv" type="file" :class="inputClass" name="csv" @change="load" />
        </div>
        <!-- <div class="form-group csv-import-file dropbox">
                    <input ref="csv" type="file" :class="inputClass" name="csv" class="input-file" @change="load">
                      <p>
                        Smelltu hér til að hlaða upp .csv skrá
                    </p>
                </div> -->
        <!-- <div class="form-group">
                    <slot name="next" :load="load">
                        <input type="submit" :class="buttonClass" @click.prevent="load" :value="loadBtnText">
                    </slot>
                </div> -->
      </div>
      <div class="vue-csv-uploader-part-two">
        <div class="vue-csv-mapping" v-if="sample">
          <table :class="tableClass" style="padding-left: -12px">
            <tbody>
              <tr v-for="(field, key) in fieldsToMap" :key="key">
                <td style="width: 40%">Veldu dálk sem inniheldur starfsgreinar</td>
                <td>
                  <select class="form-control" v-model="map[field.key]">
                    <option v-for="(column, key) in firstRow" :key="key" :value="key">{{ column }}</option>
                  </select>
                </td>
              </tr>
            </tbody>
            <!-- <slot name="thead">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>CSV Column</th>
                            </tr>
                            </thead>
                        </slot> -->
          </table>
          <!-- <div class="form-group">
                        <slot name="submit" :submit="submit">
                            <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                        </slot>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
    import _ from 'lodash';
    import axios from 'axios';
    import Papa from 'papaparse';
    import core from '@/api/core';

    export default {
        props: {
            value: Array,
            url: {
                type: String
            },
            mapFields: {
                required: true
            },
            callback: {
                type: Function,
                default: () => {
                }
            },
            catch: {
                type: Function,
                default: () => {
                }
            },
            finally: {
                type: Function,
                default: () => {
                }
            },
            parseConfig: {
                type: Object,
                default() {
                    return {};
                }
            },
            headers: {
                default: null
            },
            loadBtnText: {
                type: String,
                default: "Next"
            },
            submitBtnText: {
                type: String,
                default: "Submit"
            },
            tableClass: {
                type: String,
                default: "table"
            },
            checkboxClass: {
                type: String,
                default: "form-check-input"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            professionCategoryName: {
                type: String,
                required: true
            },
        },

        data: () => ({
            form:
              {
                name: '',
                csv: [
                    {
                        name:'',
                    },
                ],
              },

            fieldsToMap: [],
            map: {},
            hasHeaders: false,
            csv: null,
            sample: null,
        }),

        created() {
            this.hasHeaders = this.headers;
            if (_.isArray(this.mapFields)) {
                this.fieldsToMap = _.map(this.mapFields, (item) => {
                    return {
                        key: item,
                        label: item
                    };
                });
            } else {
                this.fieldsToMap = _.map(this.mapFields, (label, key) => {
                    return {
                        key: key,
                        label: label
                    };
                });
            }
        },

        methods: {
            async submit() {
                const _this = this;
                _this.form.csv = _this.buildMappedCsv();
                _this.$emit('input', _this.form.csv);
                try {
                    _this.submitted = true;
                    _this.errorList = [];
                    await _this.$validator.validateAll().then((response) => {
                        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
                        }
                    );

                    // Engar villur í formi.
                    try {
                        _this.loading = true;
                        const response = await core.professionCategoryCreate({
                            name: _this.form.name,
                        });
                        _this.id = response.data.id;
                        _this.created = true;

                        const promises = [];
                        // _this.callback(this.form.csv);
                        _this.form.csv.forEach((element) => {
                            if (element.Starfsgreinar.length > 0) {
                                promises.push(core.professionCreate({
                                    category: this.id, name: element.Starfsgreinar,
                                }));
                            }
                        });

                    await Promise.all(promises);
                    // _this.displaySuccess(`Starfsgreinaflokkur „${_this.form.name}“ stofnaður.`);
                    this.$emit('successful', { id: this.id });
                    } catch (e) {
                        // Aðgerð mistókst.
                        _this.failed = true;
                        _this.$log.debug(e);
                        if (e.response && e.response.status === 400) {
                        djangoErrorsToVee(e.response.data, _this.errors, _this.errorList);
                        } else {
                        _this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
                        }
                    } finally {
                        _this.loading = false;
                    }
                } catch (e) {
                    // Villur í formi.
                    _this.$log.debug(e);
                }
            },
            buildMappedCsv() {
                const _this = this;

                let csv = this.hasHeaders ? _.drop(this.csv) : this.csv;
                return _.map(csv, (row) => {
                    let newRow = {};
                    _.forEach(_this.map, (column, field) => {
                        _.set(newRow, field, _.get(row, column));
                    });
                    return newRow;
                });
            },
            load() {
                this.form.name = this.professionCategoryName;
                this.readFile((output) => {
                    this.sample = _.get(Papa.parse(output, { preview: 2, skipEmptyLines: true }), "data");
                    this.csv = _.get(Papa.parse(output, { skipEmptyLines: true }), "data");
                });
            },
            readFile(callback) {
                let file = this.$refs.csv.files[0];

                if (file) {
                    let reader = new FileReader();
                    reader.readAsText(file, "ISO-8859-1");
                    reader.onload = function (evt) {
                        callback(evt.target.result);
                    };
                    reader.onerror = function () {
                    };
                }
            },
            toggleHasHeaders() {
                this.hasHeaders = !this.hasHeaders;
            }
        },
        watch: {
            map: {
                handler: function (newVal) {
                    if (!this.url) {
                        var hasAllKeys = this.mapFields.every(function (item) {
                            return newVal.hasOwnProperty(item);
                        });
                        if (hasAllKeys) {
                           // this.submit();
                        }
                    }
                },
                deep: true
            }
        },
        computed: {
            firstRow() {
                return _.get(this, "sample.0");
            }
        },
    };
  /* eslint-enable */
</script>

<style lang="scss">
.dropbox {
  outline: 2px solid grey;
  background: white;
  color: grey;
  padding-top: 20px;
  min-height: 100px;
  width: 50%;
  position: relative;
  cursor: pointer;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}
.dropbox:hover {
  background: whitesmoke; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 20px;
}
</style>
