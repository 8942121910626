<template>
  <fbk-page>
    <b-card header="Námssamningur">
      <b-form-group label="Vinnustaður" label-for="workplace">
        <b-form-select
          id="workplace"
          name="workplace"
          v-model="form.workplace"
          data-vv-as="vinnustaður"
          :disabled="workplaceList.length == 0"
        >
          <option :value="null">-- Enginn vinnustaður --</option>
          <option v-for="workplace in workplaceList" :key="workplace.uuid" :value="workplace">{{ workplace.name }}</option>
        </b-form-select>
      </b-form-group>

      <b-form-group label="Leið" label-for="contract">
        <b-form-select id="contract" v-model="form.contract" :options="contracts" />
      </b-form-group>
      <b-form-group label="Dagsetning samnings við undirritun" label-for="date">
        <flat-pickr
          id="date"
          name="date"
          class="form-control"
          type="text"
          v-model="form.date"
          placeholder="Veldu dagsetningu"
          :config="flatpickrConfig"
          data-vv-as="dags"
          v-validate="'required'"
          ref="datePickr"
        >
        </flat-pickr>
      </b-form-group>
      <b-form-group label="Braut" label-for="course">
        <b-form-input
          id="course"
          name="course"
          type="text"
          v-model="form.course"
          required
          placeholder="Skráðu inn heiti brautar"
          data-vv-as="heiti brautar"
        >
        </b-form-input>
      </b-form-group>

      <div class="pb-2 line" style="width: 100%"><label>Samningstexti</label></div>
      <div>{{ contractText }}</div>
      <div class="pb-2 line" style="width: 100%"></div>
      <div>
        <b-btn style="width: 25%" variant="secondary" class="mb-2 pull-right" :to="{ name: 'CareerbookById', params: { id: this.id } }">
          Til baka
        </b-btn>
        <b-btn style="width: 25%" variant="primary" class="mr-2 pull-right" @click.prevent="registerContract"> Vista </b-btn>
      </div>
    </b-card>
  </fbk-page>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { flatpickrConfig } from '@/utils';
import book from '@/api/book';

export default {
  name: 'register-contract',

  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
    currentValueContract() {
      return this.contracts.find((contract) => contract.value === this.form.contract);
    },
  },
  data() {
    return {
      id: '',
      institution: '',
      loading: false,
      contractText: '„Samningur er undirritaður og er vistaður í viðkomandi skóla.“',
      flatpickrConfig: flatpickrConfig(),
      workplaceList: [],
      contracts: [
        {
          text: '-- Veldu leið --',
          value: null,
        },
        {
          value: 'SKOLALEID',
          text: 'Skólasamningur um vinnustaðarnám',
        },
        {
          value: 'MEISTARALEID',
          text: 'Iðnmeistarar-fyrirtæki, stofnanasamningur um vinnustaðarnám ',
        },
      ],
      form: {
        workplace: null,
        contract: null,
        date: '',
        course: '',
      },
    };
  },
  methods: {
    async registerContract() {
      this.loading = true;

      try {
        const item = {
          book_id: this.id,
          contract: this.currentValueContract.value,
          institution: this.institution,
          workplace: this.form.workplace?.uuid,
          course: this.form.course,
          weeks: null,
          termination: null,
          contract_text: this.contractText,
          register_contract: 1,
          register_date: this.form.date,
        };
        await book.signetContractDraft(item);
        this.$router.push({ name: 'CareerbookById', params: { id: this.id } });
        this.displaySuccess('Samningur hefur verið vistaður');
        this.$emit('successful', {});
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1})
          kom upp við að vista samning. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadWorkplaces() {
      this.loading = true;
      this.workplaceList = [];
      try {
        const response = await book.workplacesList(this.id);
        this.workplaceList = response.data.results;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja vinnustaði. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.id = this.$route.params.id;
    this.institution = this.$route.params.institution;
    this.loadWorkplaces();
  },
};
</script>
