<template>
  <fbk-page>
    <b-card>
      <!-- <div class="text-center" v-if="loading"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div> -->
      <!-- <div v-else> -->
      <b-row>
        <b-col>
          <h5 class="my-4">Yfirlit yfir stofnanir</h5>
        </b-col>
        <b-col>
          <!-- ADA: símmenntunarviðbót -->
          <div class="my-4" v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_admin === 1">
            <b-link class="btn btn-secondary pull-right" href @click.prevent="institutionCreateModal">
              <i class="fa fa-fw fa-plus-circle"></i> Skrá stofnun
            </b-link>
          </div>
        </b-col>
      </b-row>
      <table class="table">
        <tr>
          <th style="padding: 10px 0px 10px 0px; width: 50%">
            <b-form-input id="name" name="name" type="text" v-model="searchName" placeholder="Leita eftir heiti eða kennitölu">
            </b-form-input>
          </th>
          <!-- Checkbox fyrir er skóli eða stofnun -->
        </tr>
      </table>

      <table class="table table-hover">
        <thead>
          <tr>
            <th>Heiti</th>
            <th>Kennitala</th>
            <th>Símanúmer</th>
            <th>Skóli</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="institutionList.length == 0">
            <td colspan="3">
              <i>Engar leitarniðurstöður fundust.</i>
            </td>
          </tr>
          <tr v-for="(institution, index) in institutionList" :key="index">
            <td>
              {{ institution.name }}
              <b-link
                class="text-secondary"
                v-if="loggedInUser.is_master_admin === 1 || (loggedInUser.is_admin === 1 && loggedInUser.is_mms === 1)"
                @click.prevent="institutionUpdateModal(institution.id)"
                v-b-tooltip.hover
                title="Breyta stofnun"
                ><i class="fa fa-fw fa-pencil hover-edit"></i
              ></b-link>
            </td>
            <td>{{ institution.ssn }}</td>
            <td>{{ institution.phonenumber }}</td>
            <td>{{ institution.is_school ? 'Já' : 'Nei' }}</td>
          </tr>
        </tbody>
      </table>
      <!-- </div> -->
    </b-card>
    <!-- Modal til að skrá stofnun -->
    <institution-crud-modal ref="institutionCreateModal" @successful="institutionUpdated"></institution-crud-modal>
    <!-- Modal til að uppfæra stofnun -->
    <institution-crud-modal ref="institutionUpdateModal" @successful="institutionUpdated"></institution-crud-modal>
  </fbk-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import core from '@/api/core';
import InstitutionCrudModal from '@/components/institution/InstitutionCrudModal.vue';
import { debounce } from '@/utils';

export default {
  name: 'institution-list',
  components: {
    InstitutionCrudModal,
  },
  data() {
    return {
      loading: false,
      institutionList: [],
      searchName: null,
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    /*
     * Sækja yfirlit yfir stofnanir.
     */
    async loadList() {
      this.loading = true;
      this.institutionList = [];

      try {
        const response = await core.institutionList(null, null, null, this.searchName);
        this.institutionList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
     * Aðgerðir á modal gluggum.
     */
    institutionCreateModal() {
      this.$refs.institutionCreateModal.show();
    },
    institutionUpdateModal(id) {
      this.$refs.institutionUpdateModal.show(id);
    },
    institutionCreated() {
      this.loadList();
    },
    institutionUpdated() {
      this.loadList();
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  watch: {
    searchName: debounce(function () {
      this.loadList(null, null);
    }, 500),
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss">
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
