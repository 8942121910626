<template>
  <div>
    <div style="clear: left">
      <h4 style="font-weight: 300; padding-top: 20px">
        {{ factorName }}
      </h4>
      <div style="text-align: center">
        <div class="line"></div>
      </div>
      <div v-if="imageList.length == 0" style="padding-top: 10px; padding-bottom: 10px">Engar myndir skráðar</div>
      <div v-if="isModal === true">
        <div v-for="image in imageList" :key="image.local_source" class="photoBoxBorder">
          <div>
            <photo-box-item :image="image" :id="careerbookId" :isModal="isModal"></photo-box-item>
          </div>
        </div>
      </div>

      <div v-if="isModal !== true">
        <div v-for="image in imageList" :key="image.local_source" class="photoBox">
          <div @click.prevent="photoModal(image)">
            <photo-box-item :image="image" :id="careerbookId" :isModal="isModal"></photo-box-item>
          </div>
        </div>
      </div>
    </div>
    <careerbook-photo-modal
      ref="photoModal"
      :type="9"
      :image="imageToView"
      :bookId="careerbookId"
      :categoryId="categoryId"
      :factorId="factorId"
      :student="student"
      @successful="photoModal"
    ></careerbook-photo-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import book from '@/api/book';
import PhotoBoxItem from '@/components/careerbook/image/PhotoBoxItem.vue';
import CareerbookPhotoModal from '@/components/careerbook/modal/CareerbookPhotoModal.vue';

export default {
  name: 'category-factor-photos',
  props: ['factorId', 'categoryId', 'careerbookId', 'factorName', 'isModal', 'student'],
  components: {
    CareerbookPhotoModal,
    PhotoBoxItem,
  },
  data() {
    return {
      imageList: [],
      loading: false,
      image: '',
      imageToView: '',
    };
  },
  methods: {
    async getImages() {
      this.loading = true;
      try {
        if (this.careerbookId) {
          const response = await book.imageList(this.careerbookId, this.categoryId, this.factorId);
          this.imageList = response.data;
          this.loading = false;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /**
     * Modal gluggi
     */
    photoModal(image) {
      this.imageToView = image;
      this.$refs.photoModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.getImages();
  },
};
</script>

<style lang="scss">
.photoBoxBorder {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  width: 32%;
  padding: 10px;
  min-height: 300px;
  img {
    width: 100%;
    height: auto;
  }
}
.photoBox {
  display: inline-block;
  margin: 5px;
  width: 24%;
  padding: 10px;
  img {
    width: 100%;
    height: auto;
  }
}
.line {
  border-bottom: 1px solid #ccc;
  display: inline-block;
  width: 50%;
  margin-bottom: 10px;
}
</style>
