<template>
  <div>
    <table class="table">
      <tr>
        <th style="padding-right: 1em">
          <b-form-group label="Dagsetning frá" label-for="date-from-picker">
            <flat-pickr
              id="date-from-picker"
              name="date-from-picker"
              class="form-control mb-2"
              type="text"
              v-model="dateFrom"
              @input="loadList()"
              :class="{ 'is-invalid': submitted && (errors.has('date_to') || invalid.date == true) }"
              :config="flatpickrConfig"
              data-vv-as="dags frá"
              ref="dateFromPickr"
            ></flat-pickr>
          </b-form-group>
        </th>
        <th>
          <b-form-group label="Dagsetning til" label-for="date-to-picker">
            <flat-pickr
              id="date-to-picker"
              name="date-to-picker"
              class="form-control mb-2"
              type="text"
              v-model="dateTo"
              @input="loadList()"
              :class="{ 'is-invalid': submitted && (errors.has('date_to') || invalid.date == true) }"
              :config="flatpickrConfig"
              data-vv-as="dags til"
              ref="dateToPickr"
            ></flat-pickr>
          </b-form-group>
        </th>
      </tr>
    </table>

    <b-alert show>
      <ul style="margin: 0">
        <li>Aðeins sótt þær starfsgreinar þar sem ferilbók eða samningur endaði á tímabilinu.</li>
        <li>Litið er svo á að lokadagsetning á vinnustað samsvari lokadagsetningu samnings.</li>
      </ul>
    </b-alert>

    <b-button @click="downloadExcel()" v-b-tooltip.hover title="Taka upplýsingar út í Excel">
      <i class="fa fa-fw fa-file-excel-o"></i>
    </b-button>
    <div style="width: 100%; overflow-y: scroll">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Starfsgrein</th>
            <th>Meðaltími RF starfsgreinar (vikur)</th>
            <th>Meðal samningstími í starfsgrein (vikur)</th>
            <th
              v-b-tooltip.hover
              title="Fjöldi fyrirtækja er þau fyrirtæki sem eru skráð á þessa starfsgrein fyrir, eða samdægurs, lokadagsetningu tímabils."
            >
              Fj. fyrirtækja <i class="fa fa-info-circle"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="professionsList.length == 0">
            <td colspan="100">
              <i>Engar starfsgreinar fundust með gefnum forsendum.</i>
            </td>
          </tr>
          <tr class="table-row-linkable" v-for="(profession, index) in professionsList" :key="index">
            <td>{{ profession.name }}</td>
            <td>{{ profession.avg_book_time | comma }}</td>
            <td>{{ profession.avg_contract_time | comma }}</td>
            <td>{{ profession.workplace_count }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage"></pagination>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import statistics from '@/api/statistics';
import Pagination from '@/components/Pagination.vue';

import { paginationMixin, parsePagination, flatpickrConfig } from '@/utils';

export default {
  name: 'profession-statistics-list',
  mixins: [paginationMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      loading: false,
      professionsList: [],
      dateFrom: null,
      dateTo: null,
      pagination: null,
      flatpickrConfig: flatpickrConfig(),
    };
  },
  filters: {
    comma(num) {
      return num?.toString()?.replace('.', ',');
    },
  },
  methods: {
    /*
     * Sækja tölfræði starfsgreina.
     */
    async loadList(page = undefined, limit = undefined) {
      this.loading = true;
      this.pagination = null;
      this.workplaceList = [];

      try {
        const response = await statistics.professions(this.dateFrom, this.dateTo, page, limit);
        this.professionsList = response.data.items;

        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    downloadExcel() {
      statistics.professionsToExcel(this.dateFrom, this.dateTo);
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  created() {
    const today = new Date();
    this.dateTo = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    this.dateFrom = `${today.getFullYear() - 1}-${today.getMonth() + 1}-${today.getDate()}`;
    this.loadList();
  },
};
</script>
