<template>
  <div>
    <b-row>
      <b-col>
        <div class="d-inline">
          <competence-item
            v-for="(competence, index) in competenceList"
            :key="index"
            :index="index"
            :categoryIndex="categoryIndex"
            :item="competence"
            :canEditCompetence="canEditCompetence"
            @updateCompetence="updateCompetence"
          >
          </competence-item>
        </div>
        <div v-if="canEditCompetence" style="padding-top: 8px">
          <b-form @submit.prevent="saveCompetence" novalidate>
            <b-input-group>
              <b-form-input
                id="name"
                name="name"
                type="text"
                v-model="name"
                required
                placeholder="Sláðu inn hæfnikröfu"
                :state="submitted && errors.has('name') ? false : null"
                v-validate="'required'"
                v-on:keyup.enter="saveCompetence()"
              >
              </b-form-input>
              <b-input-group-append>
                <button type="button" class="btn btn-sm btn-primary" @click="saveCompetence()" :disabled="submitting">Bæta við</button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';

import CompetenceItem from '@/components/careerbook/competence/CompetenceItem.vue';

export default {
  name: 'competence-list',
  components: {
    CompetenceItem,
  },
  props: ['categoryId', 'canEditCompetence', 'competenceList', 'categoryIndex'],
  data() {
    return {
      loading: true,
      submitting: false,
      submitted: false,
      failed: false,
      name: null,
    };
  },
  methods: {
    updateCompetence() {
      this.$emit('successful', {});
    },
    async saveCompetence() {
      try {
        this.submitted = true;
        this.submitting = true;
        await this.$validator.validate().then((response) => {
          if (!response) {
            this.submitting = false;
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });
        try {
          await core.competenceCreate({
            category: this.categoryId,
            name: this.name,
          });
          this.updateCompetence();
          this.displaySuccess(`Hæfnivimiðið „${this.name}“ stofnað.`);
          this.name = '';
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.submitting = false;
          this.submitted = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
};
</script>

<style>
.tablebox {
  border: 0.5px solid #ccc;
}
</style>
