import Vue from 'vue';

import config from '../config';

export default {
  /**
   * Vefþjónusta til þess að sækja áfanga
   */
  moduleList(ssn) {
    return Vue.http
      .get(config.MODULE_LIST_URL.replace('{id}', encodeURIComponent(ssn)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};
