<template>
  <b-form @submit.prevent="validateBeforeSubmit" novalidate v-on:keyup.esc="editField(false)">
    <b-row>
      <b-col cols="1">
        <b-link
          :to="{ name: 'Careerbook', params: { profession: item.id } }"
          class="btn btn-secondary pt-0 pb-0 pl-1 pr-1 mt-1"
          v-b-tooltip.hover
          title="Skoða uppsetningu"
          ><i class="fa fa-search"></i
        ></b-link>
      </b-col>
      <b-col class="pl-0" v-if="canEdit && (isAdmin || isMasterAdmin)">
        <inline-edit
          class="mb-0"
          field="name"
          :doEdit="edit"
          @editField="editField"
          @saveField="validateBeforeSubmit"
          v-click-outside="editField"
        >
          <span slot="content">{{ item.name }}</span>
          <span slot="input">
            <b-form-input
              id="name"
              name="name"
              type="text"
              v-model="name"
              required
              placeholder="Sláðu inn heiti"
              :state="submitted && errors.has('name') ? false : null"
              v-validate="'required'"
              data-vv-as="heiti"
              v-on:keyup.enter="validateBeforeSubmit"
              ref="input"
            ></b-form-input>
          </span>
        </inline-edit>
      </b-col>
      <b-col style="padding: 10px 0px 10px 0px" v-else>
        {{ item.name }}
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex';

import vClickOutside from 'v-click-outside';
import core from '@/api/core';
import InlineEdit from '@/components/InlineEdit.vue';

export default {
  name: 'profession-list-item',
  components: {
    InlineEdit,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: ['item', 'canEdit', 'isAdmin', 'isMasterAdmin'],
  data() {
    return {
      edit: false,
      submitted: false,
      loading: false,
      name: null,
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;

          await core.professionUpdate(this.item.id, {
            category: this.item.category.id,
            name: this.name,
            modules: JSON.stringify(this.item.modules),
          });

          this.updated = true;
          this.item.name = this.name;
          this.displaySuccess(`Starfsgreinin „${this.name}“ uppfærð.`);
          this.$emit('successful', { id: this.item.id });
          this.name = null;
          this.edit = false;
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    editField({ doEdit }) {
      if (doEdit) {
        this.edit = true;
        this.name = this.item.name;

        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);
      } else {
        this.edit = false;
        this.name = null;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
};
</script>
