<template>
  <div>
    <table class="table">
      <tr>
        <th style="padding-right: 1em">
          <b-form-group label="Dagsetning frá" label-for="date-from-picker">
            <flat-pickr
              id="date-from-picker"
              name="date-from-picker"
              class="form-control mb-2"
              type="text"
              v-model="dateFrom"
              @input="loadList()"
              :class="{ 'is-invalid': submitted && (errors.has('date_to') || invalid.date == true) }"
              :config="flatpickrConfig"
              data-vv-as="dags frá"
              ref="dateFromPickr"
            ></flat-pickr>
          </b-form-group>
        </th>
        <th>
          <b-form-group label="Dagsetning til" label-for="date-to-picker">
            <flat-pickr
              id="date-to-picker"
              name="date-to-picker"
              class="form-control mb-2"
              type="text"
              v-model="dateTo"
              @input="loadList()"
              :class="{ 'is-invalid': submitted && (errors.has('date_to') || invalid.date == true) }"
              :config="flatpickrConfig"
              data-vv-as="dags til"
              ref="dateToPickr"
            ></flat-pickr>
          </b-form-group>
        </th>
      </tr>
    </table>

    <b-alert show>
      <ul style="margin: 0">
        <li>Sótt eru öll fyrirtæki sem voru skráð í kerfið fyrir lokadagsetningu tímabils.</li>
        <li>Skólar geta aðeins séð fyrirtæki sem hafa tengst minnst einni ferilbók skóla.</li>
        <li>Sótt eru allar starfsgreinar fyrirtækis sem skráðar voru í kerfið fyrir lokadagsetningu tímabils.</li>
        <li>Ef fyrirtæki er með margar starfsgreinar þá er það birt einu sinni fyrir hverja starfsgrein.</li>
      </ul>
    </b-alert>

    <b-button @click="downloadExcel()" v-b-tooltip.hover title="Taka upplýsingar út í Excel">
      <i class="fa fa-fw fa-file-excel-o"></i>
    </b-button>
    <div style="width: 100%; overflow-y: scroll">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Nafn</th>
            <th>Kennitala</th>
            <th>Starfsgrein</th>
            <th>Heimilisfang</th>
            <th>Póstnúmer</th>
            <th>Sveitarfélag</th>
            <th>Dagsetning skráð í kerfið</th>
            <th v-b-tooltip.hover title="Staða fyrirtækis er „virkt” ef „Fj. RF tengdar við fyrirtæki” er meira en 0.">
              Staða fyrirtækis <i class="fa fa-info-circle"></i>
            </th>
            <th>Fj. RF tengdar við fyrirtækið</th>
            <th>Stofnaðar ferilbækur á tímabili</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="workplaceList.length == 0">
            <td colspan="100">
              <i>Engin fyrirtæki fundust með gefnum forsendum.</i>
            </td>
          </tr>
          <tr class="table-row-linkable" v-for="(workplace, index) in workplaceList" :key="index">
            <td>{{ workplace.name }}</td>
            <td>{{ workplace.ssn }}</td>
            <td>{{ workplace.profession }}</td>
            <td>{{ workplace.address }}</td>
            <td>{{ workplace.postcode }}</td>
            <td>{{ workplace.city }}</td>
            <td>{{ workplace.time_create }}</td>
            <td>{{ workplace.active_books > 0 ? 'Virkt' : 'Óvirkt' }}</td>
            <td>{{ workplace.active_books }}</td>
            <td>{{ workplace.created_books }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage"></pagination>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import statistics from '@/api/statistics';
import Pagination from '@/components/Pagination.vue';

import { paginationMixin, parsePagination, flatpickrConfig } from '@/utils';

export default {
  name: 'workplace-statistics-list',
  mixins: [paginationMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      loading: false,
      workplaceList: [],
      dateFrom: null,
      dateTo: null,
      pagination: null,
      flatpickrConfig: flatpickrConfig(),
    };
  },
  methods: {
    /*
     * Sækja tölfræði vinnustaða.
     */
    async loadList(page = undefined, limit = undefined) {
      this.loading = true;
      this.pagination = null;
      this.workplaceList = [];

      try {
        const response = await statistics.workplaces(this.dateFrom, this.dateTo, page, limit);
        this.workplaceList = response.data.items;

        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    downloadExcel() {
      statistics.workplacesToExcel(this.dateFrom, this.dateTo);
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  created() {
    const today = new Date();
    this.dateTo = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    this.dateFrom = `${today.getFullYear() - 1}-${today.getMonth() + 1}-${today.getDate()}`;
    this.loadList();
  },
};
</script>
