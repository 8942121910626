<template>
  <fbk-page>
    <student-book-list></student-book-list>
    <br />
    <supervisor-book-list></supervisor-book-list>
    <br />
    <instructor-book-list></instructor-book-list>
  </fbk-page>
</template>

<script>
import StudentBookList from '@/components/index/Student.vue';
import SupervisorBookList from '@/components/index/Supervisor.vue';
import InstructorBookList from '@/components/index/Instructor.vue';

export default {
  name: 'Index',
  components: {
    StudentBookList,
    SupervisorBookList,
    InstructorBookList,
  },
};
</script>

<style scoped></style>
