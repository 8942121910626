<template>
  <div style="padding: 20px">
    <b-row>
      <b-col>
        <div class="d-inline pull-right">
          <b-link
            class="btn btn-secondary"
            style="vertical-align: top"
            href
            @click.prevent="categoryCreateModal"
            v-if="!id && canEditCompetence"
            v-b-tooltip.hover
            title="Bæta við flokki"
          >
            <i class="fa fa-fw fa-plus-square"></i>
          </b-link>
        </div>
        <div class="d-inline pull-right pr-1">
          <b-link
            class="btn btn-secondary"
            style="vertical-align: top"
            href
            @click.prevent="careerbookCompetenceConnect"
            v-if="!id && !versionId"
            v-b-tooltip.hover
            :title="canEdit ? 'Tengja við verkþætti' : 'Skoða tengingu við verkþætti'"
          >
            <i class="fa fa-fw fa-link"></i>
          </b-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="d-inline">
          <category-item
            v-for="(category, index) in categoryList"
            :key="index"
            :index="index"
            :item="category"
            :canEditCompetence="canEditCompetence"
            @successful="load"
          ></category-item>
        </div>
        <div v-if="!loading && categoryList.length === 0">
          <i>Engar hæfnikröfur skráðar</i>
        </div>
      </b-col>
    </b-row>
    <!-- Modal til að skrá starfsgreinaflokk -->
    <category-crud-modals ref="categoryCreateModal" :professionId="professionId" @successful="load"></category-crud-modals>
    <category-modals
      ref="careerbookCompetenceConnectModal"
      :type="5"
      :professionId="professionId"
      :canEdit="canEdit"
      :id="id"
      @successful="load"
    ></category-modals>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';

import CategoryItem from '@/components/careerbook/competence/CategoryItem.vue';
import CategoryCrudModals from '@/components/careerbook/competence/CategoryCrudModals.vue';
import CategoryModals from '@/components/careerbook/modal/CategoryModals.vue';

export default {
  name: 'category-list',
  components: {
    CategoryCrudModals,
    CategoryItem,
    CategoryModals,
  },
  props: ['professionId', 'versionId', 'id', 'canEdit', 'canEditCompetence'],
  data() {
    return {
      loading: true,
      categoryList: null,
    };
  },
  methods: {
    async load() {
      this.loading = true;
      this.categoryList = {};
      try {
        const response = await core.competenceCategoryList(this.professionId, this.versionId);
        this.categoryList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
     * Aðgerðir á modal gluggum.
     */
    categoryCreateModal() {
      this.$refs.categoryCreateModal.show();
    },
    careerbookCompetenceConnectModal() {
      this.$refs.careerbookCompetenceConnectModal.show();
    },
    careerbookCompetenceConnect() {
      this.$emit('connectCompetence', {});
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.load();
  },
};
</script>

<style></style>
