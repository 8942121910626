<template>
  <div>
    <div class="text-center" v-if="loading"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div>
    <div v-else>
      <b-button @click.stop.prevent="disableFactorEdit()" class="pull-right mb-2" variant="secondary" size="sm"
        ><i class="fa fa-fw fa-remove"></i> Loka
      </b-button>
      <table class="table">
        <thead>
          <tr v-if="factorList.length > 0">
            <th class="v-align-bottom p-0">
              <b-link
                @click.prevent="orderByAlphabet()"
                v-b-tooltip.hover
                title="Raða öllum verkþáttum í stafrófsröð"
                class="text-secondary"
                ><i class="fa fa-sort-alpha-asc"></i
              ></b-link>
              <b-link
                @click.prevent="orderByDate()"
                v-b-tooltip.hover
                title="Raða öllum verkþáttum eftir stofnunardagsetningu"
                class="text-secondary"
                ><i class="fa fa-sort-amount-asc"></i
              ></b-link>
            </th>
            <th class="v-align-bottom p-0">Verkþáttur</th>
            <th class="v-align-bottom p-0">
              <rotate-text name="Hæfni náð"></rotate-text>
              <b-form-checkbox v-model="selectAll.complete" :value="true" :unchecked-value="false" disabled></b-form-checkbox>
            </th>
            <th class="v-align-bottom p-0">
              <rotate-text name="Staðfesting tilsjónaraðila"></rotate-text>
              <b-form-checkbox v-model="selectAll.supervisor_confirmed" :value="true" :unchecked-value="false" disabled></b-form-checkbox>
            </th>
            <!-- <th class="v-align-bottom p-0">
              <rotate-text name="Staðfesting skóla"></rotate-text>
              <b-form-checkbox v-model="selectAll.school_confirmed" :value="true" :unchecked-value="false" disabled></b-form-checkbox>
            </th> -->
            <th class="v-align-bottom p-0">
              <rotate-text name="Umsögn nema"></rotate-text>
              <b-form-checkbox
                v-model="selectAll.description"
                :value="true"
                :unchecked-value="false"
                @change="toggleSelectAll($event, 'description')"
              ></b-form-checkbox>
            </th>
            <th class="v-align-bottom p-0">
              <rotate-text name="Umsögn tilsjónaraðila"></rotate-text>
              <b-form-checkbox
                v-model="selectAll.supervisor_evaluation"
                :value="true"
                :unchecked-value="false"
                @change="toggleSelectAll($event, 'supervisor_evaluation')"
              ></b-form-checkbox>
            </th>
            <!-- <th class="v-align-bottom p-0">
              <rotate-text name="Umsögn skóla"></rotate-text>
              <b-form-checkbox
                v-model="selectAll.instructor_evaluation"
                :value="true"
                :unchecked-value="false"
                @change="toggleSelectAll($event, 'instructor_evaluation')"
              ></b-form-checkbox>
            </th> -->
            <!-- <th class="v-align-bottom p-0">
              <rotate-text name="Hversu oft"></rotate-text>
              <b-form-checkbox
                v-model="selectAll.how_often"
                :value="true"
                :unchecked-value="false"
                @change="toggleSelectAll($event, 'how_often')"
              ></b-form-checkbox>
            </th> -->
            <th class="v-align-bottom p-0">
              <rotate-text name="Tímaskráning"></rotate-text>
              <b-form-checkbox
                v-model="selectAll.time_log"
                :value="true"
                :unchecked-value="false"
                @change="toggleSelectAll($event, 'time_log')"
              ></b-form-checkbox>
            </th>
            <th class="v-align-bottom p-0">
              <rotate-text name="Skjöl"></rotate-text>
              <b-form-checkbox
                v-model="selectAll.files"
                :value="true"
                :unchecked-value="false"
                @change="toggleSelectAll($event, 'files')"
              ></b-form-checkbox>
            </th>
            <th class="v-align-bottom p-0">
              <rotate-text name="Myndir"></rotate-text>
              <b-form-checkbox
                v-model="selectAll.photos"
                :value="true"
                :unchecked-value="false"
                @change="toggleSelectAll($event, 'photos')"
              ></b-form-checkbox>
            </th>
            <th class="v-align-bottom p-0">
              <rotate-text name="Umræðuþræðir"></rotate-text>
              <b-form-checkbox
                v-model="selectAll.forum"
                :value="true"
                :unchecked-value="false"
                @change="toggleSelectAll($event, 'forum')"
              ></b-form-checkbox>
            </th>
            <th class="v-align-bottom p-0">
              <rotate-text name="Einkunn tilsjónaraðila"></rotate-text>
              <b-form-checkbox
                v-model="selectAll.grade"
                :value="true"
                :unchecked-value="false"
                @change="toggleSelectAll($event, 'grade')"
              ></b-form-checkbox>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="(factor, index) in factorList" :key="factor.id">
          <tr v-if="factor.subcategory && (!getPrevCategory(index) || factor.subcategory.id != getPrevCategory(index).id)">
            <td colspan="100%">
              <i>{{ factor.subcategory.name }}</i>
            </td>
          </tr>
          <tr>
            <td class="p-0">
              <b-link @click.prevent="orderMoveUp(factor)" v-b-tooltip.hover title="Færa upp" class="text-secondary"
                ><i class="fa fa-arrow-up"></i
              ></b-link>
              <b-link @click.prevent="orderMoveDown(factor)" v-b-tooltip.hover title="Færa niður" class="text-secondary"
                ><i class="fa fa-arrow-down"></i
              ></b-link>
            </td>
            <td class="p-0">
              <factor-item-edit :item="factor"></factor-item-edit>
            </td>
            <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.complete"
                :value="true"
                :unchecked-value="false"
                disabled
                v-b-tooltip.hover
                title="Ekki hægt að breyta"
              ></b-form-checkbox>
            </td>
            <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.supervisor_confirmed"
                :value="true"
                :unchecked-value="false"
                disabled
                v-b-tooltip.hover
                title="Ekki hægt að breyta"
              ></b-form-checkbox>
            </td>
            <!-- <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.school_confirmed"
                :value="true"
                :unchecked-value="false"
                disabled
                v-b-tooltip.hover
                title="Ekki hægt að breyta"
              ></b-form-checkbox>
            </td> -->
            <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.description"
                :value="true"
                :unchecked-value="false"
                @change="saveChange($event, index, 'description')"
              ></b-form-checkbox>
            </td>
            <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.supervisor_evaluation"
                :value="true"
                :unchecked-value="false"
                @change="saveChange($event, index, 'supervisor_evaluation')"
              ></b-form-checkbox>
            </td>
            <!-- <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.instructor_evaluation"
                :value="true"
                :unchecked-value="false"
                @change="saveChange($event, index, 'instructor_evaluation')"
              ></b-form-checkbox>
            </td> -->
            <!-- <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.how_often"
                :value="true"
                :unchecked-value="false"
                @change="saveChange($event, index, 'how_often')"
              ></b-form-checkbox>
            </td> -->
            <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.time_log"
                :value="true"
                :unchecked-value="false"
                @change="saveChange($event, index, 'time_log')"
              ></b-form-checkbox>
            </td>
            <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.files"
                :value="true"
                :unchecked-value="false"
                @change="saveChange($event, index, 'files')"
              ></b-form-checkbox>
            </td>
            <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.photos"
                :value="true"
                :unchecked-value="false"
                @change="saveChange($event, index, 'photos')"
              ></b-form-checkbox>
            </td>
            <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.forum"
                :value="true"
                :unchecked-value="false"
                @change="saveChange($event, index, 'forum')"
              ></b-form-checkbox>
            </td>
            <td class="p-0">
              <b-form-checkbox
                v-model="factor.features.grade"
                :value="true"
                :unchecked-value="false"
                @change="saveChange($event, index, 'grade')"
              ></b-form-checkbox>
            </td>
            <td class="p-0">
              <b-button
                size="sm"
                class="p-0 pull-right"
                style="margin-top: 3px"
                variant="danger"
                v-b-tooltip.hover
                title="Eyða"
                type="button"
                @click.prevent="deleteFactor(factor)"
                ><i class="fa fa-fw fa-trash"></i
              ></b-button>
            </td>
          </tr>
        </tbody>
        <tr v-if="!id">
          <td colspan="14" class="p-0 pt-3">
            <b-form @submit.prevent="saveFactor" novalidate>
              <b-input-group>
                <b-form-input
                  id="factor"
                  name="factor"
                  type="text"
                  v-model="factor"
                  required
                  placeholder="Sláðu inn heiti verkþátts"
                  :state="errors.has('factor') ? false : null"
                  v-validate="'required'"
                  v-on:keyup.enter="saveFactor()"
                  ref="factor"
                >
                </b-form-input>
                <b-input-group-append>
                  <button type="button" class="btn btn-sm btn-primary" @click="saveFactor()" :disabled="submitting">Bæta við</button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import RotateText from '@/components/shared/RotateText.vue';
import FactorItemEdit from '@/components/careerbook/category/FactorItemEdit.vue';

export default {
  name: 'factor-list-edit',
  components: {
    RotateText,
    FactorItemEdit,
  },
  props: ['id', 'category'],
  data() {
    return {
      edit: false,
      loading: false,
      submitting: false,
      factorList: null,
      factor: '',
      selectAll: {
        complete: true,
        supervisor_confirmed: true,
        school_confirmed: false,
        // school_confirmed: true,
        description: false,
        supervisor_evaluation: false,
        instructor_evaluation: false,
        how_often: false,
        time_log: false,
        files: false,
        photos: false,
        forum: false,
        grade: false,
      },
    };
  },
  methods: {
    updateSelectAll() {
      this.selectAll.complete = this.factorList.filter((f) => f.features.complete).length === this.factorList.length; // eslint-disable-line max-len
      this.selectAll.supervisor_confirmed =
        this.factorList.filter((f) => f.features.supervisor_confirmed).length === this.factorList.length; // eslint-disable-line max-len
      // this.selectAll.school_confirmed =
      // this.factorList.filter((f) => f.features.school_confirmed).length === this.factorList.length; // eslint-disable-line max-len
      this.selectAll.description = this.factorList.filter((f) => f.features.description).length === this.factorList.length; // eslint-disable-line max-len
      this.selectAll.supervisor_evaluation =
        this.factorList.filter((f) => f.features.supervisor_evaluation).length === this.factorList.length; // eslint-disable-line max-len
      // this.selectAll.instructor_evaluation =
      // this.factorList.filter((f) => f.features.instructor_evaluation).length === this.factorList.length; // eslint-disable-line max-len
      // this.selectAll.how_often = this.factorList.filter(f => f.features.how_often).length === this.factorList.length;
      this.selectAll.time_log = this.factorList.filter((f) => f.features.time_log).length === this.factorList.length; // eslint-disable-line max-len
      this.selectAll.files = this.factorList.filter((f) => f.features.files).length === this.factorList.length; // eslint-disable-line max-len
      this.selectAll.photos = this.factorList.filter((f) => f.features.photos).length === this.factorList.length; // eslint-disable-line max-len
      this.selectAll.forum = this.factorList.filter((f) => f.features.forum).length === this.factorList.length; // eslint-disable-line max-len
      this.selectAll.grade = this.factorList.filter((f) => f.features.grade).length === this.factorList.length; // eslint-disable-line max-len
    },
    async getFactors() {
      this.loading = true;
      try {
        const response = await core.factorList(this.category);
        this.factorList = response.data;
        this.updateSelectAll();
        this.$emit('factorInfo', {
          total: this.factorList.length,
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja verkþætti. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    async saveFactor() {
      try {
        this.submitted = true;
        this.submitting = true;
        await this.$validator.validate('factor').then((response) => {
          if (!response) {
            this.submitting = false;
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });

        try {
          const response = await core.factorCreate({
            category: this.category,
            name: this.factor,
          });
          // Þegar við búum til nyjan factor vistum við "Lokið" og "Staðfest" sem skyldu
          const promises = [
            core.factorUpdateFeature(response.data.id, {
              feature: 'complete',
              value: true,
            }),
            core.factorUpdateFeature(response.data.id, {
              feature: 'supervisor_confirmed',
              value: true,
            }),
            core.factorUpdateFeature(response.data.id, {
              feature: 'school_confirmed',
              value: false,
              // value: true,
            }),
          ];

          await Promise.all(promises);
          this.getFactors();
          this.displaySuccess(`Verkþátturinn „${this.factor}“ stofnaður.`);
          this.factor = '';
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að stofna verkþátt. Vinsamlegast reyndu aftur.`,
          );
        } finally {
          this.submitting = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    disableFactorEdit() {
      this.$emit('disableEdit', {});
    },
    async orderMoveUp(factor) {
      await core.factorSortOne(factor.id, {
        up: 1,
      });
      this.getFactors();
    },
    async orderMoveDown(factor) {
      await core.factorSortOne(factor.id, {
        down: 1,
      });
      this.getFactors();
    },
    async orderByAlphabet() {
      await core.factorSortAll({
        category: this.category,
        alpha: 1,
      });
      this.getFactors();
    },
    async orderByDate() {
      await core.factorSortAll({
        category: this.category,
        alpha: 0,
      });
      this.getFactors();
    },
    toggleSelectAll(event, column) {
      this.factorList.forEach((factor, index) => {
        if (factor.features[column] !== event) {
          this.$set(factor.features, column, event);
          this.saveChange(event, index, column);
        }
      });
    },
    async saveChange(event, index, column) {
      await core.factorUpdateFeature(this.factorList[index].id, {
        feature: column,
        value: event ? 1 : 0,
      });
      this.updateSelectAll();
      this.displaySuccess('Verkþætti breytt.');
    },
    getPrevCategory(index) {
      if (index > 0) {
        return this.factorList[index - 1].subcategory;
      }
      return null;
    },
    async deleteFactor(factor) {
      const conf = confirm(`Ertu viss um að þú viljir eyða verkþættinum "${factor.name}"?`); //eslint-disable-line
      if (conf) {
        try {
          await core.factorDelete(factor.id);
          this.getFactors();
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að eyða verkþætti. Vinsamlegast reyndu aftur.`,
          );
        }
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.getFactors();
  },
};
</script>

<style lang="scss">
.v-align-bottom {
  vertical-align: bottom !important;
}
</style>
