import is from '@/locale/flatpickr/is';
import auth from '@/api/auth';
/**
 * Vinna úr blaðsíðupplýsingum frá vefþjón.
 *
 * @param {objects} response Svar frá vefþjón.
 */
export const parsePagination = (pagination, data) => {
  if (Array.isArray(pagination)) {
    // eslint-disable-next-line no-param-reassign
    [pagination] = pagination;
  }
  // eslint-disable-line import/prefer-default-export, max-len
  const currentLimit = parseInt(pagination['x-current-limit'], 10);
  const currentPage = parseInt(pagination['x-current-page'], 10);
  const totalCount = parseInt(pagination['x-total-count'], 10);
  const totalPages = parseInt(pagination['x-total-pages'], 10);

  const showingFrom = currentLimit * (currentPage - 1) + 1;
  const showingTo = currentLimit * (currentPage - 1) + data.length;

  return {
    currentLimit,
    currentPage,
    totalCount,
    totalPages,
    showingFrom,
    showingTo,
  };
};

/*
 * Mixin fyrir blaðsíður og limit.
 */
export const paginationMixin = {
  data() {
    return {};
  },
  methods: {
    /*
     * Breyta um blaðsíðu.
     */
    changePage(page) {
      this.loadList(page, this.pagination.currentLimit);
    },
    /*
     * Breyta leitarniðurstöðum á blaðsíðu.
     */
    changeLimit(limit) {
      this.loadList(null, limit);
    },
  },
};

/*
 * Villuskilaboð frá Django yfir í VeeValidate.
 */
export const djangoErrorsToVee = (data, errorBag, errors) => {
  if (Array.isArray(data)) {
    data.forEach((e) => errors.push(e));
  } else {
    const { non_field_errors: nonFieldErrors, ...other } = data;

    if (nonFieldErrors) {
      nonFieldErrors.forEach((err) => {
        errors.push(err);
      });
    }

    Object.entries(other).forEach(([k, v]) => {
      v.forEach((e) => errorBag.add(k, e, 'server'));
    });
  }
};

export const flatpickrConfig = () => ({
  wrap: true,
  dateFormat: 'Y-m-d',
  locale: is,
  allowInput: false,
  altInput: true,
  altFormat: 'd.m.Y',
});

export const fileDropzoneConfig = () => ({
  url: '/',
  maxFilesize: 500,
  maxFiles: 10,
  paramName: 'file',
  acceptedFiles: '.docx,.doc,.pdf,.xlsx,.xls',
  autoProcessQueue: true,
  addRemoveLinks: true,
  dictDefaultMessage: '<i class="fa fa-cloud-upload"></i> Smelltu hér eða dragðu skrá inn á svæðið.',
  dictFileTooBig: 'Skrá er of stór ({{filesize}} MB). Hámarksstærð er {{maxFilesize}} MB.',
  dictInvalidFileType: 'Skrá er ekki á leyfilegu skráarsniði.',
  dictRemoveFile: 'Fjarlægja skrá.',
  dictMaxFilesExceeded: 'Hámarksfjölda skráa náð.',
  headers: {
    token: auth.getToken(),
  },
});

export const imageDropzoneConfig = () => ({
  url: '/',
  maxFilesize: 5, // mb
  maxFiles: 5,
  paramName: 'image',
  acceptedFiles: '.jpg,.jpeg,.png',
  // createImageThumbnails: true,
  // thumbnailWidth: 250, // px
  autoProcessQueue: true,
  addRemoveLinks: true,
  // forceFallback: true,
  dictDefaultMessage: '<i class="fa fa-cloud-upload"></i> Smelltu hér eða dragðu skrá inn á svæðið.',
  dictFileTooBig: 'Skrá er of stór ({{filesize}} MB). Hámarksstærð er {{maxFilesize}} MB.',
  dictInvalidFileType: 'Skrá er ekki á leyfilegu skráarsniði.',
  dictRemoveFile: 'Fjarlægja skrá.',
  dictMaxFilesExceeded: 'Hámarksfjölda skráa náð.',
  headers: {
    token: auth.getToken(),
  },
});

export function debounce(fn, wait) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }
    const context = this; // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args); // call the function if time expires
    }, wait);
  };
}
