<template>
  <div class="pb-2">
    <div v-if="canEditCompetence" style="padding: 10px 0px 10px 0px">
      <b-row>
        <b-col>
          <inline-edit class="mb-0" field="name" :doEdit="edit" @editField="editField" @saveField="validateBeforeSubmit">
            <span slot="content"
              ><strong>{{ index + 1 }}. {{ item.name }}</strong></span
            >
            <span slot="input">
              <b-form-input
                id="name"
                name="name"
                type="text"
                v-model="name"
                required
                placeholder="Sláðu inn heiti"
                :state="submitted && errors.has('name') ? false : null"
                v-validate="'required'"
                data-vv-as="heiti"
                v-on:keyup.enter="validateBeforeSubmit"
                ref="input"
              ></b-form-input>
            </span>
          </inline-edit>
        </b-col>
        <b-col cols="1" style="margin-top: 5px">
          <b-button
            size="sm"
            class="pull-right"
            variant="danger"
            v-b-tooltip.hover
            title="Eyða yfirflokki"
            type="button"
            @click.prevent="deleteCategory"
            ><i class="fa fa-fw fa-trash"></i
          ></b-button>
        </b-col>
      </b-row>
    </div>
    <div v-else style="padding-bottom: 15px">
      <strong>{{ index + 1 }}. {{ item.name }}</strong>
    </div>
    <div class="pl-3">
      <competence-list
        :categoryId="item.id"
        :competenceList="item.competence"
        :categoryIndex="index"
        :canEditCompetence="canEditCompetence"
        @successful="updateCompetence"
      ></competence-list>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

import vClickOutside from 'v-click-outside';
import core from '@/api/core';
import InlineEdit from '@/components/InlineEdit.vue';

import CompetenceList from '@/components/careerbook/competence/CompetenceList.vue';

export default {
  name: 'category-item',
  props: ['item', 'index', 'canEditCompetence'],
  components: {
    InlineEdit,
    CompetenceList,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      loading: false,
      edit: false,
      name: null,
      editFields: {},
      submitted: false,
      errorList: null,
    };
  },
  methods: {
    async updateCompetence() {
      // this.$emit('successful', {});
      this.loading = true;
      try {
        const response = await core.competenceList(this.item.id);
        this.$set(this.item, 'competence', response.data);
        // this.item.competence = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1})
          kom upp við að upfæra hæfni. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          await core.competenceCategoryUpdate(this.item.id, {
            name: this.name,
          });

          this.item.name = this.name;
          this.displaySuccess(`Flokkurinn „${this.name}“ uppfærður.`);
          this.name = null;
          this.edit = false;
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að upfæra flokk. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    async deleteCategory() {
        const conf = confirm(`Ertu viss um að þú viljir eyða flokknum "${this.item.name}"?`); //eslint-disable-line
      if (conf) {
        try {
          await core.competenceCategoryDelete(this.item.id);
          this.$emit('successful', {});
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    editField({ doEdit }) {
      if (doEdit) {
        this.edit = true;
        this.name = this.item.name;

        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);
      } else {
        this.edit = false;
        this.name = null;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
};
</script>

<style></style>
