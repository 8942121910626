/* eslint-disable */

/* flatpickr v4.1.2, @license MIT */

var Icelandic = {
  weekdays: {
      shorthand: ["sun", "mán", "þri", "mið", "fim", "fös", "lau"],
      longhand: [
          "sunnudagur",
          "mánudagur",
          "þriðjudagur",
          "miðvikudagur",
          "fimmtudagur",
          "föstudagur",
          "laugardagur",
      ],
  },
  months: {
      shorthand: [
          "jan",
          "feb",
          "mar",
          "apr",
          "maí",
          "jún",
          "júl",
          "ágú",
          "sep",
          "okt",
          "nóv",
          "des",
      ],
      longhand: [
          "janúar",
          "febrúar",
          "mars",
          "apríl",
          "maí",
          "júní",
          "júlí",
          "ágúst",
          "september",
          "október",
          "nóvember",
          "desember",
      ],
  },
  ordinal: function () {
      return ".";
  },
  firstDayOfWeek: 1,
  rangeSeparator: " til ",
  weekAbbreviation: "vika",
};

export default Icelandic;
