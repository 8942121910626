<template>
  <fbk-page>
    <b-card>
      <b-row>
        <b-col>
          <h5 class="my-4">Yfirlit yfir ferilbækur</h5>
        </b-col>
        <b-col>
          <div class="my-4" v-if="loggedInUser.can_create_book === 1">
            <b-link class="btn btn-secondary pull-right" :to="{ name: 'CreateCareerbookForm' }">
              <i class="fa fa-fw fa-plus-circle"></i>
              Virkja ferilbók
            </b-link>
          </div>
        </b-col>
      </b-row>
      <b-row cols="3">
        <b-col>
          <b-form-input id="name" name="name" type="text" v-model="searchName" placeholder="Leita eftir nafni eða kennitölu nemanda">
          </b-form-input>
        </b-col>
        <b-col>
          <b-form-select id="profession" name="profession" v-model="searchProfession">
            <option :value="null">-- Veldu starfsgrein --</option>
            <option v-for="profession in professionList" :key="profession.id" :value="profession.id">{{ profession.name }}</option>
          </b-form-select>
        </b-col>
        <b-col>
          <b-form-group>
            <b-form-select id="status" v-model="searchStatus">
              <b-form-select-option v-for="opt in statusList" :key="opt.value" :value="opt.value" :title="opt.tooltip">{{
                opt.text
              }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <label>Síðasta skráning nemanda</label>
          <div class="date-picker-container">
            <b-form-group>
              <flat-pickr
                v-model="searchStudentRecord.from"
                :config="{
                  ...flatpickrConfig,
                  maxDate: searchStudentRecord.to,
                }"
                placeholder="Dags. frá"
              ></flat-pickr>
            </b-form-group>
            <b-form-group>
              <flat-pickr
                v-model="searchStudentRecord.to"
                :config="{
                  ...flatpickrConfig,
                  minDate: searchStudentRecord.from,
                }"
                placeholder="Dags. til"
              ></flat-pickr>
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <label>Síðasta skráning tilsjónaraðila</label>
          <div class="date-picker-container">
            <b-form-group>
              <flat-pickr
                v-model="searchSupervisorRecord.from"
                :config="{
                  ...flatpickrConfig,
                  maxDate: searchSupervisorRecord.to,
                }"
                placeholder="Dags. frá"
              ></flat-pickr>
            </b-form-group>
            <b-form-group>
              <flat-pickr
                v-model="searchSupervisorRecord.to"
                :config="{
                  ...flatpickrConfig,
                  minDate: searchSupervisorRecord.from,
                }"
                placeholder="Dags. til"
              ></flat-pickr>
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <b-form-group label="Hæfni náð">
            <b-input-group>
              <b-input-group-prepend is-text>
                <label class="check-label" v-b-tooltip.hover title="Aðeins hæfni sem er staðfest af tilsjónaraðila">
                  Staðfest <input type="checkbox" v-model="searchPercentage.supervisor" />
                </label>
              </b-input-group-prepend>
              <div class="double-slider-container">
                <Slider v-model="searchPercentage.value" class="double-slider"></Slider>
              </div>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <table class="table table-hover striped">
        <thead>
          <tr>
            <th>Nafn</th>
            <th>Starfsgrein</th>
            <th>Gildir frá</th>
            <th v-if="loggedInUser.is_master_admin || loggedInUser.is_mms === 1">Vinnustaður</th>
            <th v-if="loggedInUser.is_mms === 1">Tilsjónaraðili/ar</th>
            <th v-else>Matsaðili/ar</th>
            <th>Umsýsluaðili/ar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="2"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</td>
          </tr>
          <tr v-else-if="careerbookList.length == 0">
            <td colspan="2">
              <i>Engar leitarniðurstöður fundust.</i>
            </td>
          </tr>
          <tr
            class="table-row-linkable"
            v-for="(careerbook, index) in careerbookList"
            :key="index"
            @click.prevent="
              $router.push({
                name: 'CareerbookById',
                params: { profession: careerbook.profession_version.profession.id, id: careerbook.id },
              })
            "
          >
            <td class="with-link" style="font-size: 12px">
              <b-link
                :to="{ name: 'CareerbookById', params: { profession: careerbook.profession_version.profession.id, id: careerbook.id } }"
                @click.stop
                ><strong>{{ careerbook.student.name }}</strong>
                <br />
                <div class="grayText">{{ careerbook.student.id | subStr }}</div>
                <br />
                <div class="grayText">
                  <div v-if="careerbook.contracts[0] && careerbook.contracts[0].status === 'NEW'">Samningur stofnaður</div>
                  <div
                    v-else-if="
                      careerbook.contracts[0] &&
                      (careerbook.contracts[0].status === 'SIGNED' || careerbook.contracts[0].status === 'REGISTERED')
                    "
                  >
                    Samningur undirritaður: {{ careerbook.contracts[0].signed | date }}
                  </div>
                  <div v-b-tooltip.right :title="`${getPercentage(careerbook.student_completed)} hæfni náð`">
                    <div class="d-flex justify-content-between">
                      Síðasta skráning nemanda: {{ careerbook.student_date ? careerbook.student_date : 'Engin skráning' | date }}
                      <span v-if="careerbook.student_completed > 0">{{ careerbook.student_completed | percentage }}</span>
                    </div>
                    <b-progress
                      v-if="careerbook.student_completed > 0"
                      height="3px"
                      :variant="careerbook.student_completed === 1 ? 'success' : 'default'"
                      :value="careerbook.student_completed"
                      max="1"
                      class="mb-1"
                    ></b-progress>
                  </div>
                  <div v-b-tooltip.right :title="`${getPercentage(careerbook.supervisor_completed)} staðfest af tilsjónaraðila`">
                    <div class="d-flex justify-content-between">
                      Síðasta skráning tilsjónaraðila:
                      {{ careerbook.supervisor_date ? careerbook.supervisor_date : 'Engin skráning' | date }}
                      <span v-if="careerbook.supervisor_completed > 0">{{ careerbook.supervisor_completed | percentage }}</span>
                    </div>
                    <b-progress
                      v-if="careerbook.supervisor_completed > 0"
                      height="3px"
                      :variant="careerbook.supervisor_completed === 1 ? 'success' : 'default'"
                      :value="careerbook.supervisor_completed"
                      max="1"
                    ></b-progress>
                  </div>
                </div>
              </b-link>
            </td>
            <td class="with-link" style="font-size: 12px">
              <b-link
                :to="{ name: 'CareerbookById', params: { profession: careerbook.profession_version.profession.id, id: careerbook.id } }"
                @click.stop
              >
                {{ careerbook.profession_version.profession.name }}
                <br />
                <div class="grayText">Útgáfa {{ careerbook.profession_version.version }}</div></b-link
              >
            </td>
            <td style="font-size: 12px">
              {{ careerbook.date_from | date }}
            </td>
            <td v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1" style="font-size: 12px">
              <span v-for="(workplace, index) in workplaces(careerbook.workplaces)" :key="index">
                <span v-if="index != 0"><br /></span>
                {{ workplace.name }}
              </span>
            </td>
            <td>
              <span v-for="supervisor in careerbook.supervisors" :key="supervisor.id">
                <avatar :title="supervisor.name" :name="supervisor.name" :color="0" size="sm"></avatar>
              </span>
            </td>
            <td>
              <span v-for="instructor in careerbook.instructors" :key="instructor.id">
                <avatar :title="instructor.name" :name="instructor.name" :color="3" size="sm" disableColor="true"></avatar>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage"></pagination>
    </b-card>
  </fbk-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import Slider from '@vueform/slider/dist/slider.vue2';
import '@vueform/slider/themes/default.css';
import book from '@/api/book';
import core from '@/api/core';
import Pagination from '@/components/Pagination.vue';
import { paginationMixin, parsePagination, flatpickrConfig, debounce } from '@/utils';
import Avatar from '@/components/Avatar.vue';

export default {
  name: 'careerbook-list',
  mixins: [paginationMixin],
  components: {
    Pagination,
    Avatar,
    Slider,
  },
  data() {
    return {
      loading: false,
      page: null,
      pagination: null,
      careerbookList: [],
      searchName: null,
      searchProfession: null,
      searchStudentRecord: {
        from: '',
        to: '',
      },
      searchSupervisorRecord: {
        from: '',
        to: '',
      },
      searchPercentage: {
        supervisor: false,
        value: [0, 100],
      },
      professionList: [],
      statusList: [
        {
          value: 1,
          text: 'Allar',
        },
        {
          value: 2,
          text: 'Virkar',
        },
        {
          value: 3,
          text: 'Lokið',
        },
        {
          value: 6,
          text: 'Undirritaðar',
          tooltip: 'Ferilbækur með samning í stöðunni "Undirritaður" eða "Undirritaður - skráður samningur".',
        },
        {
          value: 4,
          text: 'Ekki undirritaðar',
          tooltip: 'Ferilbækur með samning í stöunni "Sendur til undirritunar"',
        },
        {
          value: 5,
          text: 'Lokuð',
          tooltip: 'Bók telst lokuð ef hún hefur minnst einn afvirkjaðan samning og engan virkan',
        },
      ],
      searchStatus: 1,
      flatpickrConfig: flatpickrConfig(),
    };
  },
  filters: {
    subStr(string) {
      const newString = `${string.substring(0, 6)}-${string.substring(6, 10)}`;
      return newString;
    },
    date(string) {
      const date = moment(string);
      return date.isValid() ? date.format('DD.MM.YYYY') : string;
    },
    percentage(decimal) {
      const percentage = Math.round(decimal * 100);
      if (percentage === 100 && decimal !== 1) {
        return '99%';
      }
      return `${percentage}%`;
    },
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    /*
     * Sækja yfirlit yfir ferilbækur
     */
    async loadList(page = null, limit = null) {
      this.loading = true;
      this.pagination = null;
      this.careerbookList = [];
      this.updateUrlQuery();

      try {
        const [percentageFrom, percentageTo] = this.searchPercentage.value;
        const response = await book.careerbookList(
          page,
          limit,
          this.searchName,
          this.searchProfession,
          this.searchStatus,
          this.searchStudentRecord.from,
          this.searchStudentRecord.to,
          this.searchSupervisorRecord.from,
          this.searchSupervisorRecord.to,
          this.searchPercentage.supervisor,
          percentageFrom,
          percentageTo,
        );
        this.careerbookList = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadProfession() {
      try {
        const response = await core.professionList({});
        this.professionList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${
            e.response ? e.response.status : -1
          }) kom upp við að sækja yfirlit yfir starfsgreinar. Vinsamlegast reyndu aftur.`,
        );
      }
    },
    workplaces(list) {
      return list.filter((v) => !v.is_deleted);
    },
    getPercentage(decimal) {
      return this.$options.filters.percentage(decimal);
    },
    updateUrlQuery() {
      const [percentageFrom, percentageTo] = this.searchPercentage.value;
      // Búum til nýtt query object til að setja í URL-ið.
      const newQuery = {
        name: this.searchName || undefined,
        profession: this.searchProfession || undefined,
        status: this.searchStatus > 1 ? this.searchStatus : undefined,
        studentRecord:
          !!this.searchStudentRecord.from && !!this.searchStudentRecord.to
            ? `${this.searchStudentRecord.from} ${this.searchStudentRecord.to}`
            : undefined,
        supervisorRecord:
          !!this.searchSupervisorRecord.from && !!this.searchSupervisorRecord.to
            ? `${this.searchSupervisorRecord.from} ${this.searchSupervisorRecord.to}`
            : undefined,
        studentCompleted:
          !this.searchPercentage.supervisor && (percentageFrom > 0 || percentageTo < 100) ? `${percentageFrom}-${percentageTo}` : undefined,
        supervisorCompleted:
          this.searchPercentage.supervisor && (percentageFrom > 0 || percentageTo < 100) ? `${percentageFrom}-${percentageTo}` : undefined,
      };
      Object.keys(newQuery).forEach((key) => newQuery[key] === undefined && delete newQuery[key]); // Fjarlægum undefined gildi.

      // Gáum hvort nýja query objectið sé það sama og gamla.
      let hasChanged = true;
      const currKeys = Object.keys(this.$route.query);
      const newKeys = Object.entries(newQuery);

      // Ef mislangt, þá hefur query breyst. Þurfum bara að bera saman ef gildi eru jafn mörg.
      if (currKeys.length === newKeys.length) {
        hasChanged = currKeys.some((key) => this.$route.query[key] !== newQuery[key]);
      }

      // Uppfærum $router aðeins ef staðan hefur breyst.
      if (hasChanged) {
        this.$router.replace({ query: newQuery });
      }
    },
    readUrlQuery() {
      const { name, profession, status, studentCompleted, studentRecord, supervisorCompleted, supervisorRecord } = this.$route.query;

      if (name) {
        this.searchName = name;
      }
      if (profession) {
        this.searchProfession = profession;
      }
      if (status) {
        this.searchStatus = status;
      }
      if (studentCompleted) {
        this.searchPercentage.value = studentCompleted.split('-');
      }
      if (supervisorCompleted) {
        this.searchPercentage.supervisor = true;
        this.searchPercentage.value = supervisorCompleted.split('-');
      }
      if (studentRecord) {
        const [from, to] = studentRecord.split(' ');
        this.searchStudentRecord.from = from;
        this.searchStudentRecord.to = to;
      }
      if (supervisorRecord) {
        const [from, to] = supervisorRecord.split(' ');
        this.searchSupervisorRecord.from = from;
        this.searchSupervisorRecord.to = to;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  watch: {
    searchName: debounce(function () {
      this.loadList(null, null);
    }, 500),
    searchProfession: debounce(function () {
      this.loadList(null, null);
    }, 500),
    searchStatus: debounce(function () {
      this.loadList(null, null);
    }, 500),
    searchStudentRecord: {
      handler({ from, to }) {
        // Ef bæði eða hvorugt er skilgreint
        if (!!from === !!to) {
          setTimeout(this.loadList, 500);
        }
      },
      deep: true,
    },
    searchSupervisorRecord: {
      handler({ from, to }) {
        // Ef bæði eða hvorugt er skilgreint
        if (!!from === !!to) {
          setTimeout(this.loadList, 500);
        }
      },
      deep: true,
    },
    searchPercentage: {
      handler() {
        setTimeout(this.loadList, 500);
      },
      deep: true,
    },
  },
  created() {
    this.readUrlQuery();
    this.loadList();
    this.loadProfession();
  },
};
</script>
<style lang="scss" scoped>
tr:nth-child(even) {
  background: #f1f1f1;
  .progress {
    background-color: #fff;
  }
}
tr:nth-child(odd) {
  background: #fff;
  &:hover .progress {
    background-color: #fff;
  }
}
.grayText {
  font-size: 11px;
  color: #666;
}
.double-slider {
  --slider-connect-bg: #337ab7;
  --slider-tooltip-bg: #000;
  --slider-handle-ring-color: #337ab730;
}
.double-slider-container {
  flex: 1;
  height: 34px;
  padding: 0.7rem;
  border: 1px solid #ced4da;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.check-label {
  margin: 0;
  input {
    width: initial;
  }
}
.date-picker-container {
  display: flex;
  .form-control {
    /deep/ &[readonly] {
      background-color: #fff;
    }
  }
}
</style>
