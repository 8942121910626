<template>
  <div>
    <div v-for="(factor, index) in factorList" :key="factor.id" class="pb-3">
      <div v-if="factor.subcategory && (!getPrevCategory(index) || factor.subcategory.id != getPrevCategory(index).id)">
        <h5>
          <i>{{ factor.subcategory.name }}</i>
        </h5>
      </div>
      <div>{{ index + 1 }}. {{ factor.name }}</div>
      <factor-description-edit :description="factor.factor_description" :factorId="factor.id" class="box"></factor-description-edit>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import FactorDescriptionEdit from '@/components/careerbook/modal/FactorDescriptionEdit.vue';

export default {
  name: 'factor-description-edit-modal',
  components: {
    FactorDescriptionEdit,
  },
  props: ['category'],
  data() {
    return {
      factorList: [],
      loading: false,
      saving: true,
      failed: false,
    };
  },
  methods: {
    async getFactors() {
      this.loading = true;
      try {
        const response = await core.factorList(this.category);
        this.factorList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    getPrevCategory(index) {
      if (index > 0) {
        return this.factorList[index - 1].subcategory;
      }
      return null;
    },
    async validateBeforeSubmit() {
      try {
        this.saving = true;
        this.$emit('loading', true);
        const promises = [];
        this.factorList.forEach((f) => {
          promises.push(
            core.factorPatch(f.id, {
              factor_description: f.factor_description,
            }),
          );
        });
        await Promise.all(promises);
        this.displaySuccess('Lýsing verkþátta vistað');
        this.$emit('successful', {});
      } catch (e) {
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.$emit('loading', false);
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.getFactors();
  },
};
</script>
