/* tslint:disable */
/* eslint-disable */
/**
 * Ferilbók
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     *
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface SignetCallbackInputItem
 */
export interface SignetCallbackInputItem {
    /**
     *
     * @type {string}
     * @memberof SignetCallbackInputItem
     */
    DocID: string;
    /**
     *
     * @type {number}
     * @memberof SignetCallbackInputItem
     */
    Status: number;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     *
     * @type {Array<string>}
     * @memberof ValidationError
     */
    loc: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ValidationError
     */
    msg: string;
    /**
     *
     * @type {string}
     * @memberof ValidationError
     */
    type: string;
}

/**
 * ContractsApi - axios parameter creator
 * @export
 */
export const ContractsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Preview
         * @param {string} contractId
         * @param {string} [type] html / pdf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preview: async (contractId: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('preview', 'contractId', contractId)
            const localVarPath = `/api/worker/contracts/preview/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            // authentication APIKeyQuery required
            await setApiKeyToObject(localVarQueryParameter, "token", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Submit
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submit: async (contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('submit', 'contractId', contractId)
            const localVarPath = `/api/worker/contracts/submit/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            // authentication APIKeyQuery required
            await setApiKeyToObject(localVarQueryParameter, "token", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary View
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        view: async (contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('view', 'contractId', contractId)
            const localVarPath = `/api/worker/contracts/view/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            // authentication APIKeyQuery required
            await setApiKeyToObject(localVarQueryParameter, "token", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Remind
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remind: async (contractId: string, options: any = {}): Promise<RequestArgs> => {
          // verify required parameter 'contractId' is not null or undefined
          assertParamExists('remind', 'contractId', contractId)
          const localVarPath = `/api/worker/contracts/remind/{contractId}`
              .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
          // use dummy base URL string because the URL constructor only accepts absolute URLs.
          const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
          let baseOptions;
          if (configuration) {
              baseOptions = configuration.baseOptions;
          }

          const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
          const localVarHeaderParameter = {} as any;
          const localVarQueryParameter = {} as any;

          // authentication APIKeyHeader required
          await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

          // authentication APIKeyQuery required
          await setApiKeyToObject(localVarQueryParameter, "token", configuration)



          setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
          let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
          localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

          return {
              url: toPathString(localVarUrlObj),
              options: localVarRequestOptions,
          };
        },
    }
};

/**
 * ContractsApi - functional programming interface
 * @export
 */
export const ContractsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Preview
         * @param {string} contractId
         * @param {string} [type] html / pdf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preview(contractId: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.preview(contractId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Submit
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submit(contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submit(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary View
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async view(contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.view(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Remind
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remind(contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
          const localVarAxiosArgs = await localVarAxiosParamCreator.remind(contractId, options);
          return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
    }
};

/**
 * ContractsApi - factory interface
 * @export
 */
export const ContractsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractsApiFp(configuration)
    return {
        /**
         *
         * @summary Preview
         * @param {string} contractId
         * @param {string} [type] html / pdf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preview(contractId: string, type?: string, options?: any): AxiosPromise<string> {
            return localVarFp.preview(contractId, type, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Submit
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submit(contractId: string, options?: any): AxiosPromise<object> {
            return localVarFp.submit(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary View
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        view(contractId: string, options?: any): AxiosPromise<string> {
            return localVarFp.view(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remind
         * @param {string} contractId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remind(contractId: string, options?: any): AxiosPromise<string> {
          return localVarFp.remind(contractId, options).then((request) => request(axios, basePath));
      },
    };
};

/**
 * Request parameters for preview operation in ContractsApi.
 * @export
 * @interface ContractsApiPreviewRequest
 */
export interface ContractsApiPreviewRequest {
    /**
     *
     * @type {string}
     * @memberof ContractsApiPreview
     */
    readonly contractId: string

    /**
     * html / pdf
     * @type {string}
     * @memberof ContractsApiPreview
     */
    readonly type?: string
}

/**
 * Request parameters for submit operation in ContractsApi.
 * @export
 * @interface ContractsApiSubmitRequest
 */
export interface ContractsApiSubmitRequest {
    /**
     *
     * @type {string}
     * @memberof ContractsApiSubmit
     */
    readonly contractId: string
}

/**
 * Request parameters for view operation in ContractsApi.
 * @export
 * @interface ContractsApiViewRequest
 */
export interface ContractsApiViewRequest {
    /**
     *
     * @type {string}
     * @memberof ContractsApiView
     */
    readonly contractId: string
}

/**
 * ContractsApi - object-oriented interface
 * @export
 * @class ContractsApi
 * @extends {BaseAPI}
 */
export class ContractsApi extends BaseAPI {
    /**
     *
     * @summary Preview
     * @param {ContractsApiPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public preview(requestParameters: ContractsApiPreviewRequest, options?: any) {
        return ContractsApiFp(this.configuration).preview(requestParameters.contractId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Submit
     * @param {ContractsApiSubmitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public submit(requestParameters: ContractsApiSubmitRequest, options?: any) {
        return ContractsApiFp(this.configuration).submit(requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary View
     * @param {ContractsApiViewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public view(requestParameters: ContractsApiViewRequest, options?: any) {
        return ContractsApiFp(this.configuration).view(requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
    }


    /**
     *
     * @summary Remind
     * @param {ContractsApiViewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public remind(requestParameters: ContractsApiViewRequest, options?: any) {
      return ContractsApiFp(this.configuration).remind(requestParameters.contractId, options).then((request) => request(this.axios, this.basePath));
  }
}


/**
 * ContractsPublicApi - axios parameter creator
 * @export
 */
export const ContractsPublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Signet Callback
         * @param {SignetCallbackInputItem} signetCallbackInputItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signetCallback: async (signetCallbackInputItem: SignetCallbackInputItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signetCallbackInputItem' is not null or undefined
            assertParamExists('signetCallback', 'signetCallbackInputItem', signetCallbackInputItem)
            const localVarPath = `/api/worker/p/contracts-public/signet-callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signetCallbackInputItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractsPublicApi - functional programming interface
 * @export
 */
export const ContractsPublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractsPublicApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Signet Callback
         * @param {SignetCallbackInputItem} signetCallbackInputItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signetCallback(signetCallbackInputItem: SignetCallbackInputItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signetCallback(signetCallbackInputItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractsPublicApi - factory interface
 * @export
 */
export const ContractsPublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractsPublicApiFp(configuration)
    return {
        /**
         *
         * @summary Signet Callback
         * @param {SignetCallbackInputItem} signetCallbackInputItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signetCallback(signetCallbackInputItem: SignetCallbackInputItem, options?: any): AxiosPromise<object> {
            return localVarFp.signetCallback(signetCallbackInputItem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for signetCallback operation in ContractsPublicApi.
 * @export
 * @interface ContractsPublicApiSignetCallbackRequest
 */
export interface ContractsPublicApiSignetCallbackRequest {
    /**
     *
     * @type {SignetCallbackInputItem}
     * @memberof ContractsPublicApiSignetCallback
     */
    readonly signetCallbackInputItem: SignetCallbackInputItem
}

/**
 * ContractsPublicApi - object-oriented interface
 * @export
 * @class ContractsPublicApi
 * @extends {BaseAPI}
 */
export class ContractsPublicApi extends BaseAPI {
    /**
     *
     * @summary Signet Callback
     * @param {ContractsPublicApiSignetCallbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsPublicApi
     */
    public signetCallback(requestParameters: ContractsPublicApiSignetCallbackRequest, options?: any) {
        return ContractsPublicApiFp(this.configuration).signetCallback(requestParameters.signetCallbackInputItem, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            // authentication APIKeyQuery required
            await setApiKeyToObject(localVarQueryParameter, "token", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readRootGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readRootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         *
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootGet(options?: any): AxiosPromise<any> {
            return localVarFp.readRootGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readRootGet(options?: any) {
        return DefaultApiFp(this.configuration).readRootGet(options).then((request) => request(this.axios, this.basePath));
    }
}


