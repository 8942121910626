<template>
  <fbk-page>
    <b-card class="careerbook-tabs" :class="{ 'careerbook-tabs-fixed': tabsFixed }" v-if="professionId">
      <b-row class="d-lg-none">
        <b-col>
          <div class="pb-2">
            <b-nav class="mobile-navbar">
              <b-nav-item @click.prevent="tabIndex = 0" :active="tabIndex === 0">Hæfnikröfur</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 1" :active="tabIndex === 1">Tengiliðir</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 2" :active="tabIndex === 2">Upplýsingar</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 3" :active="tabIndex === 3" class="first">Verkflokkar</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 4" :active="tabIndex === 4">Myndasíða</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 5" :active="tabIndex === 5">Tímalína</b-nav-item>
            </b-nav>
          </div>
        </b-col>
      </b-row>
      <b-row cols="2" class="d-none d-sm-none d-md-none d-lg-block">
        <b-col style="max-width: 100%">
          <span ref="tabs"></span>
          <div class="pb-2">
            <b-nav class="mobile-navbar">
              <b-nav-item @click.prevent="tabIndex = 0" :active="tabIndex === 0">Hæfnikröfur</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 1" :active="tabIndex === 1">Tengiliðir</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 2" :active="tabIndex === 2">Upplýsingar</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 3" :active="tabIndex === 3">Verkflokkar</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 4" :active="tabIndex === 4">Myndasíða</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 5" :active="tabIndex === 5">Tímalína</b-nav-item>
            </b-nav>
          </div>
        </b-col>
      </b-row>
      <b-col sm="12" md="12" lg="12" v-if="professionId" style="padding: 0px">
        <careerbook-header
          :book="book"
          :id="id"
          :professionName="professionName"
          :versionName="versionName"
          :editBook="probe.editBook"
          :canEdit="editProfession"
          :professionId="professionId"
          :student-completed="{ percentage: book.student_completed, lastRecord: book.student_date }"
          :supervisor-completed="{ percentage: book.supervisor_completed, lastRecord: book.supervisor_date }"
        >
          <!-- :supervisorEditBook="probe.supervisorEditBook" -->
        </careerbook-header>
        <competence
          v-show="tabIndex === 0"
          :professionId="professionId"
          :id="id"
          :versionId="versionId"
          :canEdit="editProfession"
          :canEditCompetence="editProfession"
          :description="professionDescription"
          @successful="updateProfessionDescription"
        >
        </competence>

        <agreement
          v-show="tabIndex === 1"
          :professionName="professionName"
          :book="book"
          :id="id"
          :student="probe.student"
          :studentId="this.studentId"
          :supervisor="probe.supervisor"
          :instructor="probe.instructor"
          @reloadBook="loadBook"
        >
        </agreement>

        <regulation-list
          v-show="tabIndex === 2"
          :professionId="professionId"
          :versionId="versionId"
          :id="id"
          :professionName="professionName"
          :canEdit="editProfession"
          :isInstructor="probe.instructor"
          :book="book"
        >
        </regulation-list>

        <category-list
          v-show="tabIndex === 3"
          :professionId="professionId"
          :versionId="versionId"
          :id="id"
          :student="probe.student"
          :supervisor="probe.supervisor"
          :instructor="probe.instructor"
          :canEdit="editProfession"
          :book="book"
          :professionName="professionName"
        >
        </category-list>

        <careerbook-photo-list
          v-show="tabIndex === 4"
          :professionId="professionId"
          :versionId="versionId"
          :id="id"
          :student="probe.student"
          :supervisor="probe.supervisor"
          :instructor="probe.instructor"
          :canEdit="editProfession"
        >
        </careerbook-photo-list>

        <history-list v-show="tabIndex === 5" :professionId="professionId" :versionId="versionId" :id="id" :canEdit="editProfession">
        </history-list>
      </b-col>
    </b-card>
  </fbk-page>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import core from '@/api/core';
import book from '@/api/book';

import CareerbookHeader from '@/components/careerbook/header/CareerbookHeader.vue';
import Competence from '@/components/careerbook/competence/Overview.vue';
import Agreement from '@/components/careerbook/agreement/Agreement.vue';
import RegulationList from '@/components/careerbook/regulation/RegulationList.vue';
import CategoryList from '@/components/careerbook/category/CategoryList.vue';
import CareerbookPhotoList from '@/components/careerbook/image/CareerbookPhotoList.vue';
import HistoryList from '@/components/careerbook/history/HistoryList.vue';

export default {
  name: 'careerbook',
  components: {
    CareerbookHeader,
    CategoryList,
    Competence,
    Agreement,
    RegulationList,
    CareerbookPhotoList,
    HistoryList,
  },
  data() {
    return {
      studentId: null,
      profession: null,
      tabsFixed: false,
      tabIndex: 0,
      professionId: null,
      versionId: null,
      id: null,
      isApplicationCareerbook: false,
      loading: false,
      professionName: '',
      versionName: '',
      professionDescription: '',
      editProfession: false,
      imageList: '',
      book: {},
      probe: {
        instructor: false,
        student: false,
        supervisor: false,
        editBook: false,
        supervisorEditBook: false,
      },
    };
  },
  methods: {
    /* Athuga réttindi ef ferilbók */
    async checkProbe() {
      try {
        const response = await book.careerbookProbe(this.id, this.isApplicationCareerbook);
        this.probe.student = response.data.is_student;
        this.probe.instructor = response.data.is_instructor;
        this.probe.supervisor = response.data.is_supervisor;
        this.probe.editBook = response.data.is_can_edit;
        this.probe.supervisorEditBook = response.data.is_supervisor_can_edit;
        this.loadBook();
      } catch (e) {
        this.$log.error(e);
        if (e.response && e.response.status === 400) {
          this.displayError('Þú hefur ekki aðgang að þessari ferilbók.');
          this.$router.push({ name: 'Index' });
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að skoða aðgang. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.loading = false;
      }
    },
    /* Fletta upp profession */
    async loadProfession() {
      this.loading = true;
      this.profession = {};
      try {
        const response = await core.professionDetail(this.professionId);
        this.profession = response.data;
        this.professionName = this.profession.name;
        this.professionDescription = this.profession.description;
        this.editProfession = this.profession.can_edit === 1;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja starfsgreinar. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    /* Fletta upp útgáfu */
    async loadVersion() {
      this.loading = true;
      try {
        const response = await core.professionVersionDetail(this.professionId, this.versionId);
        this.versionName = response.data.version;
        this.professionDescription = response.data.description;
        this.professionName = response.data.profession.name;
        this.editProfession = false;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja útgáfunúmer. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    /* Fletta upp upplýsingum um ferilbókina */
    async loadBook() {
      this.loading = true;
      this.book = {};
      try {
        const response = await book.careerbookDetail(
          this.id,
          this.probe.student === true ? 1 : null,
          this.probe.supervisor === true ? 1 : null,
          this.probe.instructor === true ? 1 : null,
          this.isApplicationCareerbook === true ? 1 : null,
        );
        this.book = response.data;
        this.professionId = this.book.profession_version.profession.id;
        this.versionId = this.book.profession_version.id;
        this.professionName = this.book.profession_version.profession.name;
        this.professionDescription = this.book.profession_version.description;
        if (this.book.student.id) {
          this.getStudentInformation();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja upplýsingar um ferilbók. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },

    // Sækir nemandanúmer ef nemandi er til í völdum skóla
    async getStudentInformation() {
      try {
        const response = await book.searchStudentInformation(this.book.student.id, this.book.school.ssn);
        if (response.data.results[0]) {
          this.studentId = response.data.results[0].nemandanr;
          this.studentRelatives = response.data.results[0].adstandendur;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },

    /*
     * Scroll eventar.
     */
    onScroll() {
      if (this.$refs.tabs) {
        const bc = this.$refs.tabs.getBoundingClientRect();
        const sc = this.tabsFixed;

        if (!sc && bc.top <= 20) {
          Vue.set(this, 'tabsFixed', true);
        } else if (sc && bc.top > 20) {
          Vue.set(this, 'tabsFixed', false);
        }
      }
    },
    async updateProfessionDescription() {
      try {
        const response = await core.professionDetail(this.professionId);
        this.professionDescription = response.data.description;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að uppfæra lýsingu. Vinsamlegast reyndu aftur.`,
        );
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.isProfession = false;
    this.isVersion = false;
    this.isBook = false;
    if (this.$route.name === 'Careerbook') {
      this.isProfession = true;
      this.professionId = this.$route.params.profession;
      this.loadProfession();
    } else if (this.$route.name === 'CareerbookVersion') {
      this.isVersion = true;
      this.versionId = this.$route.params.version;
      this.professionId = this.$route.params.profession;
      this.loadVersion();
    } else if (this.$route.name === 'CareerbookById') {
      this.isBook = true;
      this.id = this.$route.params.id;
      this.tabIndex = 3;
      if (this.$route.params.isApplicationCareerbook) {
        this.isApplicationCareerbook = this.$route.params.isApplicationCareerbook;
      }
      this.checkProbe();
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss">
@import '../style/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.mobile-navbar {
  li.nav-item {
    a.nav-link {
      padding: 0.5rem 0.5rem;
    }
  }
}
.careerbook-tabs {
  .nav-tabs {
    display: none;
  }

  .nav {
    border-bottom: 0.5px solid #f1f1f1;
    margin-bottom: 10px;
  }

  li.nav-item {
    &.first {
      a.nav-link {
        padding: 10px 5px 10px 5px;
      }
    }

    a.nav-link {
      color: inherit;
      margin: 0px 3px 3px 0px;
      padding: 10px 10px 10px 10px;
      border: 1px solid #f1f1f1;
      margin-bottom: -1px;

      &.active {
        color: inherit;
        font-weight: bold;
        border: 1pxsolid #f1f1f1;
        border-bottom: 1px solid #f1f1f1 !important;
        background-color: #f1f1f1;
      }
      &:hover {
        border: 1px solid #f1f1f1;
        background-color: #f1f1f1;
      }
    }
  }

  h4 {
    font-weight: 200;
  }

  &.careerbook-tabs-fixed {
    .careerbook-nav {
      position: fixed;
      top: 20px;
    }
  }
}

.imagesCenter {
  margin: auto;
}
</style>
