<template>
  <div>
    <b-modal
      centered
      id="photoModal"
      size="lg"
      lazy
      :no-close-on-backdrop="false"
      :hide-header="true"
      :hide-footer="true"
      ok-title="Loka"
      :ok-only="true"
      ref="boxModal"
    >
      <photo-modal
        ref="photoBox"
        :image="image"
        :bookId="bookId"
        :categoryId="categoryId"
        :factorId="factorId"
        :student="student"
        @loading="setLoading"
        @close="successful"
      ></photo-modal>
    </b-modal>
  </div>
</template>

<script>
import PhotoModal from '@/components/careerbook/modal/PhotoModal.vue';

export default {
  name: 'careerbook-photo-modal',
  components: {
    PhotoModal,
  },
  props: ['image', 'canEdit', 'factorId', 'categoryId', 'bookId', 'student'],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    show() {
      this.$refs.boxModal.show();
    },
    successful(data) {
      this.$emit('successful', data);
      this.$refs.photoModal.hide();
    },
    setLoading(value) {
      this.loading = value;
    },
  },
};
</script>
<style lang="scss">
#careerbookFactorPhotoModal {
  .modal-dialog {
    max-width: 100%;
  }
  .modal-content {
    background-color: transparent;
    border-color: transparent;
  }
}
</style>
