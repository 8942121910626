<template>
  <b-card no-body>
    <div slot="header" @click="collapseCategory()" style="cursor: pointer">
      <b-row>
        <b-col cols="11">
          <h5 class="mb-0">
            <div v-if="loggedInUser.is_admin === 1 && edit">
              <b-input-group>
                <b-form-input
                  id="name"
                  name="name"
                  type="text"
                  v-model="name"
                  required
                  placeholder="Sláðu inn heiti"
                  :state="errors.has('name') ? false : null"
                  v-validate="'required'"
                  v-on:keyup.enter="saveCategory()"
                  v-on:keyup.esc="editCategory()"
                  ref="input"
                >
                </b-form-input>
                <b-input-group-button slot="right">
                  <button type="button" class="btn btn-sm btn-primary" @click.stop.prevent="saveCategory()" :disabled="submitting">
                    <i class="fa fa-fw fa-save"></i>
                  </button>
                  <button type="button" class="btn btn-sm btn-secondary" @click.stop.prevent="editCategory()" :disabled="submitting">
                    <i class="fa fa-fw fa-remove"></i>
                  </button>
                </b-input-group-button>
              </b-input-group>
            </div>
            <div v-else>
              {{ item.name }}
              <!-- ADA: símmenntunviðbót: leyfa master að breyta -->
              <i
                class="fa fa-fw fa-pencil hover-edit"
                @click.stop.prevent="editCategory()"
                v-if="item.institution == null && (loggedInUser.is_master_admin === 1 || loggedInUser.is_admin === 1)"
              ></i>
            </div>
          </h5>
        </b-col>
        <b-col>
          <div class="pull-right h5 mb-0">
            <i class="fa fa-fw" :class="{ 'fa-chevron-down': collapse, 'fa-chevron-right': !collapse }"></i>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-collapse id="collapse" v-model="collapse">
      <div class="card-body">
        <div v-if="loading" class="text-center"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div>
        <div v-else>
          <div v-if="item.institution != null" class="alert alert-info">
            Þessar starfsgreinar tilheyra stofnuninni <strong>{{ item.institution.name }}</strong
            >. Þær birtast ekki öðrum stofnunum.
          </div>
          <table class="table">
            <tr v-for="(profession, index) in professionList" :key="index">
              <td v-if="profession.can_edit === 1 || loggedInUser.can_edit === 1" class="p-0">
                <profession-list-item
                  :item="profession"
                  :isAdmin="loggedInUser.is_admin"
                  :isMasterAdmin="loggedInUser.is_master_admin"
                  :canEdit="profession.can_edit"
                ></profession-list-item>
              </td>
              <td v-else class="p-0">{{ profession.name }}</td>
              <td class="p-0">
                <span v-for="(module, index) in profession.modules" :key="index">
                  <span v-if="index != 0">,</span>
                  {{ module.code }}
                </span>
                <b-link
                  href
                  @click.prevent="professionModuleUpdateModal(profession.id, item.institution)"
                  class="text-secondary"
                  v-if="profession.can_edit === 1 && item.institution && item.institution.is_school"
                >
                  <i class="fa fa-fw fa-pencil"></i>
                </b-link>
              </td>
            </tr>
          </table>

          <div v-if="loggedInUser.is_admin === 1">
            <b-form @submit.prevent="saveProfession" novalidate>
              <b-input-group>
                <b-form-input
                  id="profession"
                  name="profession"
                  type="text"
                  v-model="profession"
                  required
                  placeholder="Sláðu inn heiti starfsgreinar"
                  :state="errors.has('profession') ? false : null"
                  v-validate="'required'"
                  v-on:keyup.enter="saveProfession()"
                >
                </b-form-input>
                <b-input-group-append>
                  <b-button @click="saveProfession()" :disabled="submitting"> Bæta við </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </div>
        </div>
      </div>
    </b-collapse>
    <!-- Modal til að uppfæra stofnun -->
    <profession-module-crud-modal ref="professionModuleUpdateModal" @successful="professionModuleUpdated"></profession-module-crud-modal>
  </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import vClickOutside from 'v-click-outside';
import core from '@/api/core';

import ProfessionListItem from '@/components/profession/ProfessionListItem.vue';
import ProfessionModuleCrudModal from '@/components/profession/ProfessionModuleCrudModal.vue';

export default {
  name: 'profession-list-category',
  components: {
    ProfessionListItem,
    ProfessionModuleCrudModal,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: ['item', 'notCollapsed'],
  data() {
    return {
      collapse: false,
      edit: false,
      loading: false,
      submitted: false,
      submitting: false,
      professionList: null,
      editFields: {},
      name: null,
      profession: '',
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    async updateProfessions() {
      this.loading = true;
      try {
        const response = await core.professionList({ category: this.item.id });
        this.professionList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
     * Collapse-ar starfsgreinaflokk og nær í professions ef ekki er búið að því
     */
    async collapseCategory() {
      if (this.edit !== true) {
        this.collapse = !this.collapse;
        this.loading = false;
        if (this.professionList == null && this.collapse) {
          this.updateProfessions();
        }
      }
    },
    editCategory() {
      // this.collapse = true;
      this.edit = !this.edit;
      if (this.edit) {
        this.name = this.item.name;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);
      } else {
        this.name = null;
      }
    },
    async saveCategory() {
      try {
        this.submitted = true;
        await this.$validator.validate('name').then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        try {
          await core.professionCategoryUpdate(this.item.id, {
            name: this.name,
          });
          this.item.name = this.name;
          this.displaySuccess(`Starfsgreininaflokkur „${this.name}“ uppfærður.`);
          this.name = null;
          this.edit = false;
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    async saveProfession() {
      try {
        this.submitted = true;
        this.submitting = true;
        await this.$validator.validate().then((response) => {
          if (!response) {
            this.submitting = false;
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });
        try {
          await core.professionCreate({
            category: this.item.id,
            name: this.profession,
          });
          this.updateProfessions();
          this.displaySuccess(`Starfsgreinin „${this.profession}“ stofnuð.`);
          this.profession = '';
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.submitting = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    /*
     * Aðgerðir á modal gluggum.
     */
    professionModuleUpdateModal(id, institution) {
      this.$refs.professionModuleUpdateModal.show(id, institution);
    },
    professionModuleUpdated() {
      this.updateProfessions();
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    if (this.notCollapsed) {
      this.collapseCategory();
    }
  },
};
</script>
<style lang="scss" scoped>
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
