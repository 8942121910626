<template>
  <div>
    <h4 v-if="!id">Hérna koma umræður fyrir verkflokkinn</h4>

    <div v-for="(factor, index) in factorList" :key="index" class="mb-3">
      <div v-if="factor.features.forum">
        <h4 style="font-weight: 300; cursor: pointer" @click="viewForum(factor)">
          <!--i class="fa fa-fw fa-search text-secondary" @click="viewForum(factor)"
          style="cursor:pointer" v-b-tooltip.hover title="Fara í umræður"></i-->
          {{ factor.name }}
        </h4>
        <div v-if="factor.messages.length == 0">Engar umræður skráðar</div>
        <b-row v-for="f in factor.messages" :key="f.id" class="m-0">
          <b-col class="forum-item" cols="6" :offset="f.me ? 6 : ''" :class="{ 'forum-item-me': f.me, 'forum-item': !f.me }">
            <div class="date pull-right" style="font-size: 0.6rem">{{ f.date_created | moment('DD.MM.YYYY HH:mm:ss') }}</div>
            <div class="name">{{ f.name }}</div>
            <div class="text">{{ f.text }}</div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import book from '@/api/book';

export default {
  name: 'category-forum',
  props: ['professionId', 'category', 'id'],
  data() {
    return {
      factorList: [],
      loading: false,
    };
  },
  methods: {
    async getFactors() {
      this.loading = true;
      try {
        if (this.id) {
          const response = await book.careerbookFactorList(this.id, this.category);
          this.factorList = response.data;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    viewForum(factor) {
      this.$emit('successful', { factor: factor.id });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.getFactors();
  },
};
</script>
<style lang="scss">
.forum-item {
  background-color: #e6e6e6;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  .name {
    // float: right;
    font-size: 0.7rem;
    font-style: italic;
  }
}

.forum-item-me {
  background-color: #d1ecf1;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  .name {
    // float: right;
    font-size: 0.7rem;
    font-style: italic;
  }
}
</style>
