<template>
  <div>
    <b-modal
      id="remindContractModal"
      size="lg"
      scrollable
      title="Minna undirritendur á að skrifa undir samning"
      lazy
      ok-title="Senda áminningu"
      cancel-title="Hætta við"
      @ok="accept"
      ref="remindModal"
    >
      <div>
        <strong>{{ lastReminder ? `Síðasta áminning var send ${lastReminder}` : 'Engin áminning hefur verið send' }}</strong>
      </div>
      <b-table :items="contacts" :fields="fields">
        <template #cell(check)="check">
          <i v-if="check.value" class="fa fa-check-circle-o signed-icon"></i>
          <i v-else class="fa fa-circle-o unsigned-icon"></i>
        </template>
        <template #cell(email)="email">
          <div>
            <a :href="'mailto:' + email.value">{{ email.value }}</a>
          </div>
        </template>
      </b-table>
      <i>Ekki er hægt að breyta netföngum tengd námssamningnum.</i><br />
      <i>Ef upplýsingarnar eru rangar þarf að Afvirkja og stofna nýjan samning.</i>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'contract-remind-modal',
  data() {
    return {
      id: null,
      lastReminder: null,
      currentlyEditingEmailIndex: null,
      newEmailValue: '',
      fields: [
        {
          key: 'check',
          label: '',
        },
        {
          key: 'name',
          label: 'Nafn',
        },
        {
          key: 'email',
          label: 'Netfang',
        },
        {
          key: 'signedAt',
          label: 'Undirritaði',
        },
      ],
      contacts: [
        {
          check: false,
          name: null,
          email: null,
          signedAt: null,
          canEdit: true,
        },
      ],
    };
  },
  methods: {
    show({ id, lastReminder, contacts }) {
      this.id = id;
      this.lastReminder = lastReminder ? moment(lastReminder).format('DD.MM.YYYY') : null;
      this.contacts = contacts.map((c) => ({
        check: !!c.signedAt,
        name: c.name,
        email: c.email,
        signedAt: c.signedAt ? moment(c.signedAt).format('DD.MM.YYYY') : null,
        canEdit: c.signedAt === null,
      }));
      this.$refs.remindModal.show();
    },
    accept() {
      this.$emit('accept', this.id);
    },
    editEmail(index, email = '') {
      this.currentlyEditingEmailIndex = index;
      this.newEmailValue = email; // Set the input field to the current email
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0px;
}
.signed-icon {
  color: green;
}
.unsigned-icon {
  color: lightgray;
}
.fa {
  margin-right: 3px;
}
.editButton {
  margin-left: 10px;
}
</style>
