<template>
  <fbk-page>
    <b-card header="Námssamningur">
      <b-form-group label="Vinnustaður" label-for="workplace">
        <b-form-select
          id="workplace"
          name="workplace"
          v-model="form.workplace"
          data-vv-as="vinnustaður"
          :disabled="workplaceList.length == 0"
        >
          <option :value="null">-- Enginn vinnustaður --</option>
          <option v-for="workplace in workplaceList" :key="workplace.uuid" :value="workplace">{{ workplace.name }}</option>
        </b-form-select>
      </b-form-group>

      <b-form-group label="Leið" label-for="contract">
        <b-form-select id="contract" v-model="form.contract" :options="contracts" />
      </b-form-group>

      <b-form-group label="Skóli" label-for="institution">
        <b-form-select id="institution" name="institution" v-model="form.institution" data-vv-as="skóli">
          <option :value="null" disabled>-- Veldu skóla --</option>
          <option v-for="(institution, index) in institutionList" :key="index" :value="institution">{{ institution.name }}</option>
        </b-form-select>
      </b-form-group>

      <b-form-group label="Braut" label-for="course">
        <b-form-input
          id="course"
          name="course"
          type="text"
          v-model="form.course"
          required
          placeholder="Skráðu inn heiti brautar"
          data-vv-as="heiti brautar"
        >
        </b-form-input>
      </b-form-group>

      <b-form-group label="Hámarksfjöldi vikna í vinnustaðanámi samkvæmt námslýsingu" label-for="weeks">
        <b-form-input
          id="weeks"
          name="weeks"
          type="text"
          v-model="form.weeks"
          required
          placeholder="Skráðu hámarksfjölda vikna í vinnustaðanámi"
          data-vv-as="hámarksfjöldi vikna í vinnustaðanámi"
        >
        </b-form-input>
      </b-form-group>

      <b-form-group label="Slit á samningi" label-for="termination">
        <b-form-input
          id="termination"
          name="termination"
          type="text"
          v-model="form.termination"
          required
          placeholder="Skráðu inn ástæðu slita"
          data-vv-as="ástæða slita"
        >
        </b-form-input>
      </b-form-group>

      <div class="pb-2 line" style="width: 100%"><label>Samningstexti</label></div>
      <div v-html="contractText.text"></div>
      <div v-if="form.contract === 'MEISTARALEID'" style="padding-bottom: 20px">
        „Gerður er sérstakur ráðningarsamningur milli nema og fyrirtækis í samræmi við gildandi kjarasamning fyrir nemendur í viðkomandi
        starfsnámi.“
      </div>
      <div class="pb-2 line" style="width: 100%"></div>
      <div>
        <b-btn style="width: 25%" variant="secondary" class="mb-2" @click.prevent="contractContactsModal"> Skoða undirritendur </b-btn>
        <b-btn style="width: 25%" variant="secondary" class="mb-2 pull-right" :to="{ name: 'CareerbookById', params: { id: this.id } }">
          Til baka
        </b-btn>
        <b-btn style="width: 25%" variant="primary" class="mr-2 pull-right" @click.prevent="sendSignetDraft"> Vista </b-btn>
      </div>
    </b-card>

    <category-modals ref="contractContactsModal" :type="10" :id="id"></category-modals>
  </fbk-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import core from '@/api/core';
import book from '@/api/book';
import CategoryModals from '@/components/careerbook/modal/CategoryModals.vue';

export default {
  name: 'send-contract',
  components: {
    CategoryModals,
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
    currentValueContract() {
      return this.contracts.find((contract) => contract.value === this.form.contract);
    },
  },
  data() {
    return {
      id: '',
      loading: false,
      contractText: '',
      workplaceList: [],
      institutionList: [],
      contracts: [
        {
          text: '-- Veldu leið --',
          value: null,
        },
        {
          value: 'SKOLALEID',
          text: 'Skólasamningur um vinnustaðarnám',
        },
        {
          value: 'MEISTARALEID',
          text: 'Iðnmeistarar-fyrirtæki, stofnanasamningur um vinnustaðarnám ',
        },
      ],
      form: {
        workplace: null,
        institution: null,
        contract: null,
        course: '',
        weeks: '',
        termination: '',
      },
    };
  },
  methods: {
    async loadContract() {
      this.loading = true;
      this.contractText = '';
      try {
        const response = await core.contractList({});
        this.contractText = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadWorkplaces() {
      this.loading = true;
      this.workplaceList = [];
      try {
        const response = await book.workplacesList(this.id);
        this.workplaceList = response.data.results;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja vinnustaði. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    async loadInstitutions() {
      this.loading = true;
      this.institutionList = [];
      try {
        const response = await book.institutionsList({});
        this.institutionList = response.data.results;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async sendSignetDraft() {
      this.loading = true;
      try {
        // Hax: til að hárakademían geti stofnað samning
        if (this.form.institution === null && this.loggedInUser.institution > 0) {
          this.form.institution = {
            name: 'Hárakademían',
          };
        }

        // Ef allar upplýsingar undirritenda valid
        const item = {
          book_id: this.id,
          contract: this.currentValueContract.value,
          institution: this.form.institution.name,
          workplace: this.form.workplace?.uuid,
          course: this.form.course,
          weeks: this.form.weeks,
          termination: this.form.termination,
          contract_text: this.contractText.text,
          register_contract: 0,
          register_date: '',
        };
        try {
          await book.signetContractDraft(item);
          this.$router.push({ name: 'CareerbookById', params: { id: this.id } });
          this.displaySuccess('Samningur hefur verið vistaður sem drög');
          this.$emit('successful', {});
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1})
          kom upp við að vista samning. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1})
          kom upp við að vista samning. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
     * Aðgerðir á modal gluggum.
     */
    contractContactsModal() {
      this.$refs.contractContactsModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.id = this.$route.params.id;
    this.loadContract();
    this.loadInstitutions();
    this.loadWorkplaces();
  },
};
</script>
