// @ts-nocheck

import Vue from 'vue';

import axios from 'axios';

import { ContractsApi } from '@/api/openapi';
import auth from '../api/auth';
import router from '../router';
import urlConfig from '../config';

Vue.http = Vue.prototype.$http = axios; // eslint-disable-line no-multi-assign

/**
 * Interceptor fyrir öll HTTP köll í kerfinu. Ef 'skipAuthorization' er sett á kallið
 * þá er ekkert gert. Annars er JWT token sóttur úr localStorage. Ef token vantar þá
 * má ekki gera HTTP kallið. Ef token er útrunnið þá má ekki gera HTTP kallið. Ef
 * token er við það að renna út þá er frískað upp á það og síðan kallað á HTTP.
 */
/* eslint-disable */
Vue.http.interceptors.request.use(
  (config) =>
    new Promise((resolve, reject) => {
      // Á að sleppa því að auðkenna kallið?
      if (
        config.url &&
        (config.url.indexOf(urlConfig.CHECK_LOGIN_URL) > -1 ||
          config.url.indexOf(urlConfig.SHOW_SYSTEM_STATUS_LOGIN) > -1 ||
          config.url.indexOf(urlConfig.SHOW_SYSTEM_STATUS_LOGGED_IN) > -1)
      ) {
        resolve(config);
      } else {
        // Sækja innihald innskráningar.
        const token = auth.getToken();
        // const payload = auth.getPayload();

        // Er notandi innskráður og er innskráning ekki útrunnin?
        if (!token || auth.isTokenExpired()) {
          auth.removeToken();
          router.push({ name: 'Login' });
          reject();
        } else {
          // Þarf að fríska upp á innskráningu?
          const chkDate = new Date(auth.getTokenExpire());
          const sysDate = new Date();

          chkDate.setSeconds(chkDate.getSeconds() - 600);

          if (sysDate.valueOf() > chkDate.valueOf()) {
            // Fríska upp á innskráningu.
            /* auth.refresh(auth.getToken())
          .then((response) => {
            auth.setToken(response.data.token);
            config.headers.common['Authorization'] = `Bearer ${response.data.token}`;
            resolve(config)
          })
          .catch(() => {
            auth.removeToken();
            router.push({name: 'Login'})
            reject();
          }); */
          } else {
            // Allt er í lagi.
            // config.headers.common['Authorization'] = `Bearer ${auth.getToken()}`;
            config.headers.common['token'] = auth.getToken();
            resolve(config);
          }
        }
      }
    }),
);
/* eslint-enable */

/* eslint-disable */
Vue.$contractsApi = Vue.prototype.$contractsApi = new ContractsApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);
/* eslint-enable */

declare module 'vue/types/vue' {
  // eslint-disable-next-line no-shadow
  interface Vue {
    $log: any;
    $contractsApi: ContractsApi;
  }
}
