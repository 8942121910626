<template>
  <div>
    <h4>Flokkar</h4>
    <table class="table">
      <tr v-if="groupList.length > 0">
        <td style="width: 10%">
          <b-link @click.prevent="orderByAlphabet()" v-b-tooltip.hover title="Raða öllum verkþáttum í stafrófsröð" class="text-secondary"
            ><i class="fa fa-sort-alpha-asc"></i
          ></b-link>
          <b-link
            @click.prevent="orderByDate()"
            v-b-tooltip.hover
            title="Raða öllum verkþáttum eftir stofnunardagsetningu"
            class="text-secondary"
            ><i class="fa fa-sort-amount-asc"></i
          ></b-link>
        </td>
        <td></td>
        <td></td>
      </tr>
      <tr v-for="(group, index) in groupList" :key="group.id">
        <td>
          <b-link @click.prevent="orderMoveUp(group)" v-b-tooltip.hover title="Færa upp" class="text-secondary"
            ><i class="fa fa-arrow-up"></i
          ></b-link>
          <b-link @click.prevent="orderMoveDown(group)" v-b-tooltip.hover title="Færa niður" class="text-secondary"
            ><i class="fa fa-arrow-down"></i
          ></b-link>
        </td>
        <td>
          <b-form @submit.prevent="editGroup(group)" novalidate v-on:keyup.esc="editField(false)">
            <inline-edit class="mb-0" field="name" :doEdit="group.edit" @editField="editField(group)" @saveField="editGroup(group)">
              <span slot="content">{{ group.name }}</span>
              <span slot="input">
                <b-form-input
                  :id="`${index}name`"
                  :name="`${index}name`"
                  required
                  type="text"
                  v-model="group.newName"
                  placeholder="Sláðu inn heiti"
                  v-on:keyup.enter="editGroup(group)"
                  :ref="`${index}input`"
                ></b-form-input>
              </span>
            </inline-edit>
          </b-form>
        </td>
        <td>
          <b-button
            size="sm"
            variant="danger"
            class="pull-right p-0"
            v-b-tooltip.hover
            title="Eyða"
            @click.prevent="deleteGroup(group)"
            type="button"
            ><i class="fa fa-fw fa-trash"></i
          ></b-button>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <b-form @submit.prevent="saveGroup" novalidate>
            <b-input-group>
              <b-form-input
                id="group"
                name="group"
                type="text"
                v-model="group"
                required
                placeholder="Sláðu inn heiti"
                :state="submitted && errors.has('group') ? false : null"
                v-validate="'required'"
                v-on:keyup.enter="saveGroup()"
                ref="input"
              >
              </b-form-input>
              <b-input-group-button slot="right">
                <button type="button" class="btn btn-sm btn-primary" @click="saveGroup()" :disabled="submitting">
                  <i class="fa fa-fw fa-save"></i>
                </button>
              </b-input-group-button>
            </b-input-group>
          </b-form>
        </td>
      </tr>
    </table>

    <br />
    <h4>Tengja</h4>
    <table class="table">
      <tr>
        <th>Verkþáttur</th>
        <th>Flokkun</th>
      </tr>
      <tr v-for="factor in factorList" :key="factor.id">
        <td>{{ factor.name }}</td>
        <td>
          <b-form-select
            :id="`factor${factor.id}group`"
            :name="`factor${factor.id}group`"
            style="min-width: 150px"
            v-model="factor.group"
            data-vv-as="flokkun"
          >
            <option :value="null">-- Veldu flokk --</option>
            <option v-for="(group, index) in groupList" :key="index" :value="group">{{ group.name }}</option>
          </b-form-select>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import InlineEdit from '@/components/InlineEdit.vue';

export default {
  name: 'factor-group-edit',
  props: ['category'],
  components: {
    InlineEdit,
  },
  data() {
    return {
      factorList: [],
      groupList: [],
      loading: { factors: false, groups: false },
      group: '',
      submitted: false,
      submitting: false,
      saving: false,
    };
  },
  methods: {
    async getFactors() {
      this.loading.factors = true;
      try {
        const response = await core.factorList(this.category);
        this.factorList = response.data;
        this.factorList.forEach((f) => {
          if (f.subcategory) {
            this.$set(
              f,
              'group',
              this.groupList.find((g) => g.subcategory_id === f.subcategory.id),
            );
          } else {
            this.$set(f, 'group', null);
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.factors = false;
      }
    },
    async getGroups(factors) {
      this.loading.groups = true;
      try {
        const response = await core.factorSubCategoryList(this.category);
        this.groupList = response.data;
        this.groupList.forEach((g) => {
          this.$set(g, 'newName', '');
          this.$set(g, 'edit', false);
        });
        if (factors) {
          this.getFactors();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.groups = false;
      }
    },
    async orderByAlphabet() {
      await core.factorSubCategorySortAll({
        category: this.category,
        alpha: 1,
      });
      this.getGroups();
    },
    async orderByDate() {
      await core.factorSubCategorySortAll({
        category: this.category,
        alpha: 0,
      });
      this.getGroups();
    },
    async orderMoveUp(group) {
      await core.factorSubCategorySortOne(group.subcategory_id, {
        up: 1,
      });
      this.getGroups();
    },
    async orderMoveDown(group) {
      await core.factorSubCategorySortOne(group.subcategory_id, {
        down: 1,
      });
      this.getGroups();
    },
    editField(group, value) {
      this.$set(group, 'edit', value !== undefined ? value : !group.edit);
      this.$set(group, 'newName', group.name);
    },
    async editGroup(group) {
      try {
        await core.factorSubCategoryUpdate(group.subcategory_id, {
          name: group.newName,
        });
        this.displaySuccess(`Flokknum „${group.newName}“ hefur verið breytt.`);
        this.getGroups();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async deleteGroup(group) {
      try {
        await core.factorSubCategoryDelete(group.subcategory_id);
        this.displaySuccess(`Flokknum „${group.name}“ hefur verið eytt.`);
        this.getGroups();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response.status === 400) {
          this.displayError('Ekki er hægt að eyða flokki sem er tengdur við verkþætti');
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async saveGroup() {
      try {
        this.submitted = true;
        this.submitting = true;
        await this.$validator.validate('group').then((response) => {
          if (!response) {
            this.submitting = false;
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });
        try {
          await core.factorSubCategoryCreate({
            category: this.category,
            name: this.group,
          });
          this.getGroups();
          this.displaySuccess(`Flokkurinn „${this.group}“ stofnaður.`);
          this.group = '';
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.submitting = false;
          this.submitted = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    async validateBeforeSubmit() {
      try {
        this.saving = true;
        this.$emit('loading', true);
        const promises = [];
        this.factorList.forEach((f) => {
          promises.push(
            core.factorPatch(f.id, {
              subcategory: f.group != null ? f.group.subcategory_id : null,
            }),
          );
        });
        await Promise.all(promises);
        this.displaySuccess('Tenging við flokka vistað');
        this.$emit('successful', {});
      } catch (e) {
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.$emit('loading', false);
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.getGroups(true);
  },
};
</script>
