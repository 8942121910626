<template>
  <div>
    <b-row>
      <b-col>Sýni niðurstöður {{ showingFrom }} - {{ showingTo }} af {{ totalCount }}</b-col>
      <b-col
        ><b-pagination
          align="right"
          :total-rows="totalCount"
          v-model="currentPage"
          :per-page="currentLimit"
          @change="changePage"
        ></b-pagination
      ></b-col>
    </b-row>
    <div class="text-right small">
      Sýna
      <select v-model.number="currentLimit" @change="changeLimit">
        <option value="1">1</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      leitarniðurstöður á blaðsíðu
    </div>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: ['paginationResponse'],
  data() {
    return {
      showingFrom: 0,
      showingTo: 0,
      currentPage: 0,
      totalCount: 0,
      currentLimit: 0,
    };
  },
  created() {
    // Galdur í stað þess að afrita hlutinn alveg.
    ({
      showingFrom: this.showingFrom,
      showingTo: this.showingTo,
      currentPage: this.currentPage,
      totalCount: this.totalCount,
      currentLimit: this.currentLimit,
    } = this.paginationResponse);
  },
  methods: {
    changePage(page) {
      this.$emit('change-page', page);
    },
    changeLimit() {
      this.$emit('change-limit', this.currentLimit);
    },
  },
};
</script>
