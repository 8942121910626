<template>
  <fbk-page>
    <b-card>
      <div>
        Uppsetning á verkflokka og -þátta skjali þarf að vera eins og í excel (.csv) skjalinu sem nálgast má hér:
        <a href="/Verkflokkar og -þættir.csv">Verkflokkar og -þættir.csv</a>
      </div>
      <div>
        Uppsetning á hæfnikrafna skjali þarf að vera eins og í excel (.csv) skjalinu sem nálgast má hér:
        <a href="/Hæfniviðmið.csv">Hæfniviðmið.csv</a>. <br />
        Passa þarf að heiti hæfnikrafna sé nákvæmlega það sama í þessu skjali verkflokka og -þátta skjalinu
        <br />
        Til að vista skal fara í <b>File - Save as</b> og velja <b>Save as type: CSV UTF-8(Comma delimited)(*.csv)</b>
      </div>
    </b-card>
    <br />
    <b-card>
      <h4>1. Veldu skrá með verkþáttum og smelltu á lesa skrá</h4>
      <b-form-file v-model="file" class="mt-3" plain></b-form-file>
      <br />
      <b-btn variant="primary" @click="loadFactors" :disabled="!file">Lesa skrá</b-btn>
      <br />
    </b-card>
    <br />
    <b-card v-if="parsed.length > 0">
      <h4>2. Yfirfarðu gögnin</h4>
      <p v-if="!this.showSaveFactor">
        Gögnin sem lituð eru rauð eru ekki á réttu formi, vinsamlegast lagaðu þau í skjalinu og lestu það aftur inn
      </p>
      <div style="overflow-x: auto">
        <table class="tableNew">
          <thead style="padding-bottom: 50px">
            <tr>
              <th class="rotate">
                <div><span>id</span></div>
              </th>
              <th class="rotate">
                <div><span>Nr. flokkur</span></div>
              </th>
              <th class="rotate">
                <div><span>Verkflokkur</span></div>
              </th>
              <th class="rotate">
                <div><span>Nr. þáttur</span></div>
              </th>
              <th class="rotate">
                <div><span>Verkþáttur</span></div>
              </th>
              <th class="rotate">
                <div><span>Lýsing á verkþætti</span></div>
              </th>
              <th class="rotate">
                <div><span>Hefur fylgst með</span></div>
              </th>
              <th class="rotate">
                <div><span>Hefur tekið þátt</span></div>
              </th>
              <th class="rotate">
                <div><span>Gerir sjálfstætt</span></div>
              </th>
              <th class="rotate">
                <div><span>Hæfni náð</span></div>
              </th>
              <th class="rotate">
                <div><span>Tímaskráning</span></div>
              </th>
              <th class="rotate">
                <div><span>Staðfest af tilsjónaraðila</span></div>
              </th>
              <th class="rotate">
                <div><span>Lýsing</span></div>
              </th>
              <th class="rotate">
                <div><span>Umsögn tilsjónaraðila</span></div>
              </th>
              <th class="rotate">
                <div><span>Umsögn skóla</span></div>
              </th>
              <th class="rotate">
                <div><span>Hversu oft</span></div>
              </th>
              <th class="rotate">
                <div><span>Skjöl</span></div>
              </th>
              <th class="rotate">
                <div><span>Myndir</span></div>
              </th>
              <th class="rotate">
                <div><span>Umræðuþræðir</span></div>
              </th>
              <th class="rotate">
                <div><span>Einkunn tilsjónaraðila</span></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in parsed" :key="index">
              <td class="center borderCheck">{{ item.id }}</td>
              <td :class="{ 'text-danger': checkIsNotANumber(item.nr_flokkur) }" class="center borderCheck">
                {{ item.nr_flokkur }}
              </td>
              <td :class="{ 'text-danger': checkMaxLength(item.verkflokkur, 455) }" class="borderCheck">
                {{ item.verkflokkur }}
              </td>
              <td :class="{ 'text-danger': checkIsNotANumber(item.nr_thattur) }" class="center borderCheck">
                {{ item.nr_thattur }}
              </td>
              <td :class="{ 'text-danger': checkMaxLength(item.verkthattur, 455) }" class="borderCheck">
                {{ item.verkthattur }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.lysing_verkthattur) }" class="center borderCheck">
                {{ item.lysing_verkthattur }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.hefur_fylgst_med) }" class="center borderCheck">
                {{ item.hefur_fylgst_med }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.hefur_tekid_thatt) }" class="center borderCheck">
                {{ item.hefur_tekid_thatt }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.gerir_sjalfstaett) }" class="center borderCheck">
                {{ item.gerir_sjalfstaett }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.verkthaetti_lokid) }" class="center borderCheck">
                {{ item.verkthaetti_lokid }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.timaskraning) }" class="center borderCheck">
                {{ item.timaskraning }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.stadfest_af_tilsjonaradila) }" class="center borderCheck">
                {{ item.stadfest_af_tilsjonaradila }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.athugasemd) }" class="center borderCheck">
                {{ item.athugasemd }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.umsogn_tilsjonaradila) }" class="center borderCheck">
                {{ item.umsogn_tilsjonaradila }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.namsmat_umsysluadila) }" class="center borderCheck">
                {{ item.namsmat_umsysluadila }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.hversu_oft) }" class="center borderCheck">
                {{ item.hversu_oft }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.skjol) }" class="center borderCheck">
                {{ item.skjol }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.myndir) }" class="center borderCheck">
                {{ item.myndir }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.umraeduthraedir) }" class="center borderCheck">
                {{ item.umraeduthraedir }}
              </td>
              <td :class="{ 'text-danger': checkIsInvalidCheckbox(item.einkunn_umsjonaradila) }" class="center borderCheck">
                {{ item.einkunn_umsjonaradila }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <br />
    <b-card v-if="parsed.length > 0 && this.showSaveFactor && this.submitted !== true">
      <h4>3. Smelltu á "Staðfesta" til að vista gögnin</h4>
      <b-btn variant="primary" @click="saveFactors" :disabled="!file">Staðfesta</b-btn>
    </b-card>
    <b-card v-if="this.submitted && this.showLoadCompetence !== true">
      <h4>4. Viltu hlaða upp hæfnviðmiðum úr Excel, skoða útgáfuna eða fara á fyrri síðu?</h4>
      <b-btn variant="primary" @click="uploadMore">Lesa inn hæfnikröfur</b-btn>
      <b-link
        :to="{ name: 'Careerbook', params: { profession: this.useProfession } }"
        class="btn btn-primary ml-2"
        v-b-tooltip.hover
        title="Skoða útgáfu"
        >Skoða útgáfu</b-link
      >
      <b-link :to="{ name: 'VersionList' }" class="btn btn-primary ml-2" v-b-tooltip.hover title="Skoða útgáfu">Fara á forsíðu</b-link>
    </b-card>
    <b-card v-if="this.showLoadCompetence === true">
      <h4>5. Veldu skrá með hhæfnikröfum og smelltu á lesa skrá</h4>
      <div>
        Uppsetning á skjali þarf að vera eins og í excel (.csv) skjalinu sem nálgast má hér: <a href="/Hæfniviðmið.csv">Hæfniviðmið.csv</a>.
        Passa þarf að heiti hæfnikrafna sé nákvæmlega sá sami í þessu skjali og því sem lesið var inn hér að ofan
      </div>
      <b-form-file v-model="file2" class="mt-3" plain></b-form-file>
      <br />
      <b-btn variant="primary" @click="loadCompetence" :disabled="!file2">Lesa skrá</b-btn>
      <br />
    </b-card>
    <br />
    <b-card v-if="parsed2.length > 0">
      <h4>6. Yfirfarðu gögnin</h4>
      <p v-if="!this.showSaveCompetence">
        Gögnin sem lituð eru rauð eru ekki á réttu formi, vinsamlegast lagaðu þau í skjalinu og lestu það aftur inn
      </p>
      <div style="overflow-x: auto">
        <table class="tableNew">
          <thead style="padding-bottom: 50px">
            <tr>
              <th class="rotate">
                <div><span>Nr. flokkur</span></div>
              </th>
              <th class="rotate">
                <div><span>Flokkur</span></div>
              </th>
              <th class="rotate">
                <div><span>Nr. viðmið</span></div>
              </th>
              <th class="rotate">
                <div><span>Hæfnikröfur</span></div>
              </th>
              <th class="rotate">
                <div><span>Verkþættir</span></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item2, index) in parsed2" :key="index">
              <td :class="{ 'text-danger': checkIsNotANumber(item2.nr_flokkur) }" class="borderCheck">
                {{ item2.nr_flokkur }}
              </td>
              <td :class="{ 'text-danger': checkMaxLength(item2.verkflokkur, 455) }" class="borderCheck">
                {{ item2.verkflokkur }}
              </td>
              <td :class="{ 'text-danger': checkIsNotANumber(item2.nr_vidmid) }" class="borderCheck">
                {{ item2.nr_vidmid }}
              </td>
              <td :class="{ 'text-danger': checkMaxLength(item2.vidmid, 455) }" class="borderCheck">
                {{ item2.vidmid }}
              </td>
              <td class="borderCheck">
                <div
                  class="d-inline"
                  v-b-popover.hover.html="
                    item2.factors.reduce((acc, cur, idx) => acc + (idx !== 0 ? '<br /><br />' : '') + `<span>${cur.name}</span>`, '')
                  "
                >
                  {{ item2.factors.length }} {{ item2.factors.length % 10 === 1 ? 'Verkþáttur' : 'Verkþættir' }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <br />
    <b-card v-if="parsed2.length > 0 && this.showSaveCompetence && this.submitted2 !== true">
      <h4>7. Smelltu á "Staðfesta" til að vista gögnin</h4>
      <b-btn variant="primary" @click="saveCompetence" :disabled="!file2">Staðfesta</b-btn>
    </b-card>
    <b-card v-if="this.submitted2 && this.showLoadCompetence === true">
      <h4>8. Viltu skoða útgáfuna eða fara á fyrri síðu?</h4>
      <b-link
        :to="{ name: 'Careerbook', params: { profession: this.useProfession } }"
        class="btn btn-primary ml-2"
        v-b-tooltip.hover
        title="Skoða útgáfu"
        >Skoða útgáfu</b-link
      >
      <b-link :to="{ name: 'VersionList' }" class="btn btn-primary ml-2" v-b-tooltip.hover title="Skoða útgáfu">Fara á forsíðu</b-link>
      <div style="padding-top: 5px"><i>* Til að tengja hæfnikröfur við verkþætti smelltu á Skoða útgáfu</i></div>
    </b-card>
  </fbk-page>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';

export default {
  name: 'profession-category-upload',
  props: ['profession', 'version'],
  data() {
    return {
      file: null,
      file2: null,
      parsed: [],
      parsed2: [],
      factors: null,
      factorCategories: null,
      showSaveFactor: true,
      showSaveCompetence: true,
      showLoadCompetence: false,
      submitted: false,
      submitted2: false,
      useProfession: '',
      competenceNames: null,
    };
  },
  methods: {
    uploadMore() {
      this.showLoadCompetence = true;
    },
    checkMaxLength(text, length) {
      if (text.length > length) {
        this.showSaveFactor = false;
        this.showSaveCompetence = false;
        return true;
      }
      return false;
    },
    checkIsInvalidCheckbox(text) {
      const trimmedText = text.toLowerCase().trim();
      if (!trimmedText || trimmedText === 'x') {
        return false;
      }
      this.showSaveFactor = false;
      return true;
    },
    checkIsNotANumber(number) {
      const parsed = Number.parseInt(number, 10);
      if (Number.isNaN(parsed)) {
        this.showSaveFactor = false;
        this.showSaveCompetence = false;
        return true;
      }
      return false;
    },

    loadFactors() {
      this.showSaveFactor = true;
      this.parsed = [];
      const read = new FileReader();
      read.readAsText(this.file);
      read.onloadend = () => {
        const data = read.result;
        const lines = data.split('\n');
        lines.forEach((line) => {
          if (line.length > 0) {
            const columns = line.split(';');
            const item = {
              id: columns.length > 0 ? columns[0] : '',
              nr_flokkur: columns.length > 1 ? columns[1] : '',
              verkflokkur: columns.length > 2 ? columns[2] : '',
              nr_thattur: columns.length > 3 ? columns[3] : '',
              verkthattur: columns.length > 4 ? columns[4] : '',
              lysing_verkthattur: columns.length > 5 ? columns[5].toLowerCase().trim() : '',
              hefur_fylgst_med: columns.length > 6 ? columns[6].toLowerCase().trim() : '',
              hefur_tekid_thatt: columns.length > 7 ? columns[7].toLowerCase().trim() : '',
              gerir_sjalfstaett: columns.length > 8 ? columns[8].toLowerCase().trim() : '',
              verkthaetti_lokid: columns.length > 9 ? columns[9].toLowerCase().trim() : '',
              timaskraning: columns.length > 10 ? columns[10].toLowerCase().trim() : '',
              stadfest_af_tilsjonaradila: columns.length > 11 ? columns[11].toLowerCase().trim() : '',
              athugasemd: columns.length > 12 ? columns[12].toLowerCase().trim() : '',
              umsogn_tilsjonaradila: columns.length > 13 ? columns[13].toLowerCase().trim() : '',
              namsmat_umsysluadila: columns.length > 14 ? columns[14].toLowerCase().trim() : '',
              hversu_oft: columns.length > 15 ? columns[15].toLowerCase().trim() : '',
              skjol: columns.length > 16 ? columns[16].toLowerCase().trim() : '',
              myndir: columns.length > 17 ? columns[17].toLowerCase().trim() : '',
              umraeduthraedir: columns.length > 18 ? columns[18].toLowerCase().trim() : '',
              einkunn_umsjonaradila: columns.length > 19 ? columns[19].toLowerCase().trim() : '',
              competenceColumns: columns.slice(19, columns.length),
            };
            if (item.id === 'id' || item.id === '') {
              this.competenceNames = item.competenceColumns;
            } else {
              this.parsed.push(item);
            }
          }
        });
      };
    },

    async saveFactors() {
      const versionPromise = [];
      this.useProfession = this.$route.params.profession;

      // Útgáfa vistuð
      versionPromise.push(
        core.CareerbookVersionExcel({
          profession: this.useProfession,
        }),
      );
      const versionResults = await Promise.all(versionPromise);
      this.newVersionUuid = versionResults[0].data.version_uuid;
      this.displaySuccess('Útgáfa stofnuð');

      const categories = {};
      const factors = [];
      const categoriesPromises = [];
      const factorsPromises = [];

      // Verkflokkar 'group'aðir' í fylki, hver flokkur 1x
      this.parsed.forEach((element) => {
        if (!(element.nr_flokkur in categories)) {
          categories[element.nr_flokkur] = {
            name: element.verkflokkur,
            rod: element.nr_flokkur,
          };
        }

        // Verkþættir matchaðir við réttan flokk
        factors.push({
          categoryNumber: element.nr_flokkur,
          number: element.nr_thattur,
          name: element.verkthattur,
          lysing_verkthattur: element.lysing_verkthattur,
          hefur_fylgst_med: element.hefur_fylgst_med,
          hefur_tekid_thatt: element.hefur_tekid_thatt,
          gerir_sjalfstaett: element.gerir_sjalfstaett,
          verkthaetti_lokid: element.verkthaetti_lokid,
          timaskraning: element.timaskraning,
          stadfest_af_tilsjonaradila: element.stadfest_af_tilsjonaradila,
          athugasemd: element.athugasemd,
          umsogn_tilsjonaradila: element.umsogn_tilsjonaradila,
          namsmat_umsysluadila: element.namsmat_umsysluadila,
          hversu_oft: element.hversu_oft,
          skjol: element.skjol,
          myndir: element.myndir,
          umraeduthraedir: element.umraeduthraedir,
          einkunn_umsjonaradila: element.einkunn_umsjonaradila,
          competenceColumns: element.competenceColumns,
        });
      });

      // Verkflokkar vistaðir
      Object.keys(categories).forEach((k) => {
        categoriesPromises.push(
          core.factorCategoryExcel({
            rod: k,
            name: categories[k].name,
            version: versionResults[0].data.version_id,
          }),
        );
      });

      const categoriesResults = await Promise.all(categoriesPromises);

      // Id á verkflokkum sett inn í verkflokkafylkið
      Object.keys(categories).forEach((k, idx) => {
        categories[k].id = categoriesResults[idx].data.factor_category_id;
      });

      factors.forEach((f) => {
        factorsPromises.push(
          core.factorExcel({
            category_id: categories[f.categoryNumber].id,
            factor_rod: f.number,
            factor_name: f.name,
            description: f.lysing_verkthattur,
            watched: f.hefur_fylgst_med,
            participated: f.hefur_tekid_thatt,
            independently: f.gerir_sjalfstaett,
            complete: f.verkthaetti_lokid,
            time_log: f.timaskraning,
            supervisor_confirmed: f.stadfest_af_tilsjonaradila,
            factor_description: f.athugasemd,
            supervisor_evaluation: f.umsogn_tilsjonaradila,
            instructor_evaluation: f.namsmat_umsysluadila,
            how_often: f.hversu_oft,
            files: f.skjol,
            photos: f.myndir,
            forum: f.umraeduthraedir,
            grade: f.einkunn_umsjonaradila,
          }),
        );
      });
      const factorResults = await Promise.all(factorsPromises);

      Object.keys(factors).forEach((f, idx) => {
        factors[f].databaseId = factorResults[idx].data.factor_id;
      });

      this.factorCategories = categories;
      this.factors = factors;
      this.submitted = true;
    },

    loadCompetence() {
      this.showSaveCompetence = true;
      this.parsed2 = [];
      const read = new FileReader();
      read.readAsText(this.file2);
      read.onloadend = () => {
        const data = read.result;
        const lines = data.split('\n');
        lines.forEach((line) => {
          if (line.length > 0) {
            const columns = line.split(';');
            const competenceNameIndex = this.competenceNames.findIndex((k) => k.trim() === columns[3].trim());
            const item2 = {
              nr_flokkur: columns.length > 0 ? columns[0] : '',
              verkflokkur: columns.length > 1 ? columns[1] : '',
              nr_vidmid: columns.length > 2 ? columns[2] : '',
              vidmid: columns.length > 3 ? columns[3].trim() : '',
              factors: [],
            };

            this.factors.forEach((f) => {
              f.competenceColumns.forEach((c, idx2) => {
                if (idx2 === competenceNameIndex && c.trim() === 'x') {
                  item2.factors.push(f);
                }
              });
            });

            if (item2.nr_flokkur !== 'NR. Flokkur') {
              this.parsed2.push(item2);
            }
          }
        });
      };
    },

    async saveCompetence() {
      const categories = {};
      const competence = [];
      const categoriesPromises = [];
      const competencePromises = [];
      const competenceFactorPromises = [];

      // Hæfnikröfuflokkar 'group'aðir' í fylki, hver flokkur 1x
      this.parsed2.forEach((element) => {
        if (!(element.nr_flokkur in categories)) {
          categories[element.nr_flokkur] = {
            rod: element.nr_flokkur,
            name: element.verkflokkur,
          };
        }

        // Hæfnikrafa mötchuð við réttan flokk
        competence.push({
          flokkur: element.nr_flokkur,
          rod: element.nr_vidmid,
          name: element.vidmid,
          factors: element.factors,
        });
      });

      // Flokkar vistaðir
      Object.keys(categories).forEach((k) => {
        categoriesPromises.push(
          core.competenceCategoryCreate({
            name: categories[k].name,
            version: this.newVersionUuid,
            profession: this.useProfession,
          }),
        );
      });

      const categoriesResults = await Promise.all(categoriesPromises);

      // Id á flokkum sett inn í flokkafylkið
      Object.keys(categories).forEach((k, idx) => {
        categories[k].id = categoriesResults[idx].data.id;
      });

      competence.forEach((f) => {
        competencePromises.push(
          core.competenceCreate({
            category: categories[f.flokkur].id,
            name: f.name,
          }),
        );
      });

      const competenceResults = await Promise.all(competencePromises);

      Object.keys(competence).forEach((c, idx) => {
        competence[c].databaseId = competenceResults[idx].data.id;
      });

      competence.forEach((c) => {
        c.factors.forEach((f) => {
          competenceFactorPromises.push(
            core.competenceConnect({
              selected: 1,
              factor: f.databaseId,
              competence: c.databaseId,
            }),
          );
        });
      });

      // ATHUGA: ég kommentaði þetta út því ég gat ekki buildað -  v skilgreint en aldrei notað
      // await Promise.all(competenceFactorPromises).then(v => {});

      this.submitted2 = true;
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  // created() {
  // },
};
</script>
<style lang="scss" scoped>
.tableNew {
  width: 99%;
  margin: 20px auto;
  table-layout: auto;
  border-collapse: collapse;
}
th.rotate {
  height: 140px;
  white-space: nowrap;
}

th.rotate > div {
  transform: translate(0px, 51px) rotate(270deg);
  width: 30px;
}

th.rotate > div > span {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}
td {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.center {
  text-align: center;
}
.borderCheck {
  border: 1px solid #ccc;
}
</style>
